import {
  DefaultPalette,
  DefaultSpacing,
  Icon,
  Link,
  Stack,
  Text,
  TooltipHost,
} from "@fluentui/react";
import { useAuth } from "lib/utils/auth";
import { useEffect, useState } from "react";
import { FaGraduationCap } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useId } from "@fluentui/react-hooks";

export type LinkGroup = {
  title: string;
  key: string;
  path?: string;
  sublinks?: MenuLink[];
  isStep?: boolean;
  isPrimary?: boolean;
  isSeparator?: boolean;
  isSeparate?: boolean;
  isHidden?: boolean;
  isChecked?: boolean;
  hiddeIfAdminDataDock?: boolean;
  adminDataDockOnly?: boolean;
  disabled?: boolean;
  disabledMessage?: string;
  onClick?: () => void;
  iconProps?: any;
};

type MenuLink = {
  title: string;
  path: string;
  key: string;
};

export type ContextuelMenuProps = {
  isAdminDataDock?: boolean;
  activeLinkKey?: string;
  links?: LinkGroup[];
  currentLinkIndex?: number;
};

export const ContextuelMenu = ({
  activeLinkKey,
  isAdminDataDock = false,
  links = [],
  currentLinkIndex,
}: ContextuelMenuProps) => {
  const { signout } = useAuth();
  const tooltipId = useId("tooltip");
  const navigate = useNavigate();
  const [havePrimaryLink, setHavePrimaryLink] = useState<boolean>(false);
  const [separateLinks, setSeparateLinks] = useState<LinkGroup[]>();

  const getSeParateLink = () => {
    let separateLinkArray = [];
    for (const link of links) {
      if (link.isSeparate) {
        separateLinkArray.push(link);
      }
    }
    setSeparateLinks(separateLinkArray);
  };

  useEffect(() => {
    if (links[0]?.hasOwnProperty("isPrimary") && links[0].isPrimary) {
      setHavePrimaryLink(true);
    }
    getSeParateLink();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack
      styles={{
        root: {
          minWidth: 320,
          height: "100%",
          minHeight: "calc(100vh - 254px)",
          backgroundColor: DefaultPalette.themeLight,
          padding: DefaultSpacing.l1,
        },
      }}
    >
      {links.map((link, i) => {
        return (
          !link.isSeparate &&
          !link.isHidden && (
            <Stack
              key={i}
              styles={{
                root: {
                  marginBottom: DefaultSpacing.m,
                },
              }}
            >
              <Stack
                horizontal
                horizontalAlign="center"
                styles={{
                  root: {
                    paddingLeft:
                      havePrimaryLink && !link.isPrimary
                        ? DefaultSpacing.l2
                        : 0,
                  },
                }}
                tokens={{ childrenGap: DefaultSpacing.s1 }}
              >
                {havePrimaryLink && link.isPrimary && (
                  <FaGraduationCap
                    size={23}
                    style={{
                      color: DefaultPalette.themePrimary,
                    }}
                  />
                )}
                <Stack.Item grow={link.isPrimary ? 3 : 2}>
                  <TooltipHost
                    content={
                      link.disabledMessage ? (
                        <Text>{link.disabledMessage}</Text>
                      ) : (
                        ""
                      )
                    }
                    id={tooltipId}
                    calloutProps={{ gapSpace: 0 }}
                    // styles={{ root: { display: "inline-block" } }}
                  >
                    <Link
                      disabled={link.disabled}
                      onClick={() => {
                        if (link.key === "se_deconnecter")
                          signout((_: any) => navigate("/interface"));
                        else if (link.path) navigate(link.path);
                      }}
                      style={
                        link.disabled
                          ? { textDecoration: "none", opacity: 0.6 }
                          : { textDecoration: "none" }
                      }
                      styles={{
                        root: {
                          color: link.isPrimary
                            ? DefaultPalette.themePrimary
                            : "#230040",
                          fontWeight:
                            activeLinkKey === link.key
                              ? "bold"
                              : link.isPrimary
                              ? "bold"
                              : "",
                          fontSize: link.isPrimary ? 18 : "",
                          borderBottom: link.isPrimary
                            ? `2px solid ${DefaultPalette.themePrimary}`
                            : "",
                        },
                      }}
                    >
                      {link.title.includes("!!") ? (
                        <>
                          <Stack
                            styles={{
                              root: {
                                color: "#230040",
                                fontWeight: "bold",
                                fontSize: 18,
                                textDecorationLine: "underline",
                              },
                            }}
                          >
                            {link.title.split("!!")[0]}
                          </Stack>
                          {link.title.split("!!")[1]}
                        </>
                      ) : (
                        <Stack>{link.title}</Stack>
                      )}
                    </Link>
                  </TooltipHost>
                  {/* <Link
                    disabled={link.disabled}
                    onClick={() => {
                      if (link.key === "se_deconnecter")
                        signout((_: any) => navigate("/interface"));
                      else if (link.path) navigate(link.path);
                    }}
                    style={
                      link.disabled
                        ? { textDecoration: "none", opacity: 0.6 }
                        : { textDecoration: "none" }
                    }
                    styles={{
                      root: {
                        color: link.isPrimary
                          ? DefaultPalette.themePrimary
                          : "#230040",
                        fontWeight:
                          activeLinkKey === link.key
                            ? "bold"
                            : link.isPrimary
                            ? "bold"
                            : "",
                        fontSize: link.isPrimary ? 18 : "",
                        borderBottom: link.isPrimary
                          ? `2px solid ${DefaultPalette.themePrimary}`
                          : "",
                      },
                    }}
                  >
                    {link.title.includes("!!") ? (
                      <>
                        <Stack
                          styles={{
                            root: {
                              color: "#230040",
                              fontWeight: "bold",
                              fontSize: 18,
                              textDecorationLine: "underline",
                            },
                          }}
                        >
                            {link.title.split("!!")[0]}
                        </Stack>
                        {link.title.split("!!")[1]}
                      </>
                    ) : (
                      <Stack>{link.title}</Stack>
                    )}
                  </Link> */}
                  {/* {link.disabledMessage && <Text>{link.disabledMessage}</Text>} */}
                </Stack.Item>
                {link.isStep && (
                  <Stack.Item>
                    <Icon
                      styles={{
                        root: {
                          color:
                            currentLinkIndex && currentLinkIndex > i
                              ? DefaultPalette.themePrimary
                              : "gray",
                          marginRight: DefaultSpacing.s1,
                          marginTop: DefaultSpacing.s2,
                        },
                      }}
                      iconName={
                        currentLinkIndex && currentLinkIndex > i
                          ? "SkypeCircleCheck"
                          : "CircleRing"
                      }
                    />
                  </Stack.Item>
                )}
                {link.isChecked !== undefined && (
                  <Stack.Item>
                    <Icon
                      styles={{
                        root: {
                          color:
                            link.iconProps?.color ||
                            (link.isChecked
                              ? DefaultPalette.themePrimary
                              : "gray"),
                          marginRight: DefaultSpacing.s1,
                          marginTop: DefaultSpacing.s2,
                        },
                      }}
                      iconName={
                        link.iconProps?.icon ||
                        (link.isChecked ? "SkypeCircleCheck" : "CircleRing")
                      }
                    />
                  </Stack.Item>
                )}
              </Stack>

              {link.sublinks?.map((sLink, index) => {
                return (
                  <Link
                    key={index}
                    styles={{
                      root: {
                        marginLeft: DefaultSpacing.l1,
                        color: "#230040",
                        fontWeight: activeLinkKey === sLink.key ? "bold" : "",
                      },
                    }}
                    onClick={() => navigate(sLink.path)}
                  >
                    {sLink.title}
                  </Link>
                );
              })}
            </Stack>
          )
        );
      })}
      {separateLinks && separateLinks?.length > 0 && (
        <Stack
          style={{
            marginTop: DefaultSpacing.m,
            borderTop: "1px solid",
            borderTopColor: DefaultPalette.themePrimary,
          }}
        >
          {separateLinks.map(
            (sLink, index) =>
              !sLink.isHidden && (
                <Link
                  key={index}
                  style={{ textDecoration: "none" }}
                  styles={{
                    root: {
                      marginTop: DefaultSpacing.m,
                      color: "#230040",
                      fontWeight:
                        activeLinkKey === sLink.key
                          ? "bold"
                          : sLink.isPrimary
                          ? "bold"
                          : "",
                    },
                  }}
                  onClick={() => sLink.path && navigate(sLink.path)}
                >
                  {sLink.title}
                </Link>
              )
          )}
        </Stack>
      )}
    </Stack>
  );
};
