import React, { useEffect } from "react";
import {
  Stack,
  SearchBox,
  ISelectableOption,
  SelectableOptionMenuItemType,
  CommandButton,
  IContextualMenuItem,
  ContextualMenu,
  Spinner,
  ContextualMenuItemType,
  Text
} from "@fluentui/react";
import { SearcherOptions, UseUrlParams } from "@d4b/fluent-ui/utils";
import { useDebounce } from "use-debounce";

type SearcherProps = {
  filterParams: UseUrlParams;
  options?: SearcherOptions[];
  dropdownWidth?: string;
  isLoading?: boolean;
};

export const Searcher = ({
  filterParams,
  options,
  dropdownWidth = "80px",
  isLoading,
}: SearcherProps) => {
  const [selectedItem, setSelectedItem] = React.useState<ISelectableOption>();
  const [selectedPredifinedFilter, setSelectedPredifinedFilter] =
    React.useState<ISelectableOption>();

  const [searchText, setSearchText] = React.useState("");
  const [searchTextDebounced] = useDebounce(searchText, 800);
  const componentRef = React.useRef<any>();
  const { setExtraParams, extraParams, setCurrentPage } = filterParams;

  const findFirstSelecable = (): ISelectableOption | undefined => {
    if (!options) return undefined;
    return options.find(
      (opt) =>
        opt.itemType === undefined ||
        opt.itemType !== SelectableOptionMenuItemType.Header
    );
  };

  const linkRef = React.useRef(null);
  const [showContextualMenu, setShowContextualMenu] = React.useState(false);
  const onShowContextualMenu = React.useCallback(
    (ev: React.MouseEvent<HTMLElement>) => {
      ev.preventDefault();
      setShowContextualMenu(true);
    },
    []
  );
  const onHideContextualMenu = React.useCallback(() => {
    setShowContextualMenu(false);
  }, []);

  const onSearch = (newValue: any) => {
    if (typeof newValue !== "string" || !selectedItem || !selectedItem.key)
      return;
    setSelectedPredifinedFilter(undefined);

    const isSpecial =
      newValue.startsWith("!") ||
      newValue.startsWith("<") ||
      newValue.startsWith("=") ||
      newValue.startsWith(">") ||
      newValue.endsWith("NULL");
    if (!isSpecial && !newValue.endsWith("*")) newValue = `${newValue}*`;

    const extraParamsNew = { [selectedItem.key]: newValue };
    const orderBy = extraParams.orderBy;
    if (orderBy) extraParamsNew.orderBy = orderBy;

    if (extraParamsNew !== extraParams) setExtraParams(extraParamsNew);
  };

  useEffect(() => {
      setSelectedItem(findFirstSelecable());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onSearch(searchTextDebounced);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTextDebounced]);

  const menuItems: IContextualMenuItem[] = [
    {
      key: "clear_filter",
      text: "Clear Filter",
      iconProps: { iconName: "ClearFilter" },
      onClick: () => {
        setExtraParams({});
        setSelectedPredifinedFilter(undefined);
        setSelectedItem(undefined);
      },
    },
  ];

  const searcherFiltersItems: IContextualMenuItem = {
    key: "searcher_filters",
    itemType: ContextualMenuItemType.Section,
    sectionProps: {
      title: {
        key: "searcher_filters_title",
        text: "Search with",
        style: { marginLeft: -25 },
      },
      items: [],
    },
  };

  useEffect(() => {
    if (searchTextDebounced || !searcherFiltersItems.sectionProps?.items.length)
      return;
    setSelectedItem(findFirstSelecable());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    options,
    searchTextDebounced,
    searcherFiltersItems.sectionProps?.items.length,
  ]);

  options?.forEach((o) => {
    searcherFiltersItems.sectionProps?.items.push({
      key: o.key,
      text: o.text,
      onClick: () => {
        setCurrentPage(1);
        setSelectedItem(o);
        setSelectedPredifinedFilter(undefined);
      },
      onRenderContent: (menu) => (
        <Text
          style={{
            paddingLeft: 8,
            fontWeight:
              selectedItem?.text === menu.item?.text ? 600 : 400,
          }}
        >
          {menu.item?.text}
        </Text>
      ),
    });
  });
  menuItems.push(searcherFiltersItems);

  return (
    <Stack horizontal style={{ paddingLeft: 8 }}>
      {isLoading ? (
        <Spinner
          style={{ marginRight: 10 }}
          ariaLive="assertive"
          labelPosition="right"
        />
      ) : (
        <>
          <CommandButton
            styles={{
              root: { marginTop: -4, fontWeight: 600 },
              label: {
                maxWidth: dropdownWidth,
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              },
            }}
            text={
              selectedPredifinedFilter
                ? selectedPredifinedFilter.text
                : selectedItem?.text
                ? selectedItem.text
                : "Filter"
            }
            disabled={false}
            iconProps={{
              iconName:
                selectedPredifinedFilter || selectedItem?.text
                  ? "FilterSolid"
                  : "Filter",
            }}
            elementRef={linkRef}
            onClick={onShowContextualMenu}
          />
          <ContextualMenu
            items={menuItems}
            hidden={!showContextualMenu}
            target={linkRef}
            onItemClick={onHideContextualMenu}
            onDismiss={onHideContextualMenu}
          />
        </>
      )}

      <SearchBox
        componentRef={componentRef}
        styles={{ root: { marginLeft: 0, marginRight: 16, width: "100%" } }}
        // underlined={true}
        placeholder="Search"
        // onEscape={() => {
        //   console.log("Custom onEscape Called");
        // }}
        onClear={() => {
          setExtraParams(false);
        }}
        onChange={(_, newValue) => setSearchText(newValue || "")}
        onSearch={(newValue) => onSearch(newValue)}
        clearButtonProps={{ width: "50%" }}
      />
    </Stack>
  );
};
