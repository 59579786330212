import { CommandButton, IStackItemStyles, Stack } from "@fluentui/react";
import { Searcher, SearcherOptions, UseUrlParams, ViewModeMenu } from "@d4b/fluent-ui";
import { useQueryClient } from "react-query";

const stackItemStylesDefault: IStackItemStyles = {
  root: {
    height: 50,
    overflow: "hidden",
    alignItems: "center"
  },
};
//Main props for the secondaryCommandBar component
export type SecondaryCommandeBarProps = {
  stackItemStyles?: IStackItemStyles;
  searcherProps?: SearcherProps;
  viewModeMenuProps?: ViewModeMenuProps;
  invalidateProps?: InvalidateProps;
  chooseColumns?: ChooseColumns;
};

//You need to pass the searcher props to display the searcher in the secondaryCommandBar component
type SearcherProps = {
  filterParams: UseUrlParams;
  searcherOptions: any;
  options?: SearcherOptions[];
  isLoading?: boolean;
};

//You need to pass the View mode props to display the toggle button to change the view mode in the secondaryCommandBar component
type ViewModeMenuProps = {
  defaultView?: string;
  modeView?: string;
  changeView: any;
};

//You need to pass the Invalidate props to display the refresh in the secondaryCommandBar component
type InvalidateProps =  any[];


type ChooseColumns = {
  onClick: any;
}



export const SecondaryCommandeBar = ({
  stackItemStyles,
  searcherProps,
  viewModeMenuProps,
  invalidateProps,
  chooseColumns
}: SecondaryCommandeBarProps) => {
  const appQueryClient = useQueryClient();
  
  return (
    <Stack
      horizontal
      horizontalAlign="end"
      grow={3}
      styles={stackItemStyles ? stackItemStyles : stackItemStylesDefault}
    >
      {searcherProps && <Searcher {...searcherProps} />}
      {invalidateProps && (
        <CommandButton
          style={{ marginLeft: -10 }}
          text="Refresh"
          iconProps={{ iconName: "Refresh" }}
          onClick={() =>
            invalidateProps.forEach((l) => {
              appQueryClient.invalidateQueries(l);
            })
          }
        />
      )}
      {viewModeMenuProps && <ViewModeMenu {...viewModeMenuProps} />}
      {chooseColumns && <CommandButton
        styles={{}}
        text="Choose columns"
        iconProps={{ iconName: "Settings" }}
        {...chooseColumns}
      />}
    </Stack>
  );
};
