import React from "react";

        import { AdminDeclarationRoutes } from "./AdminDeclarationRoutes";
        import { RequireAuth } from "@d4b/fluent-ui";
        import { Routes, Route } from "react-router-dom";
        

const FeatureAdminDeclaration = {
    
AdminDeclarationContextePage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminDeclaration/views/AdminDeclarationContextePage")),


AdminDeclarationCertificationsPage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminDeclaration/views/AdminDeclarationCertificationsPage")),


};

export const AdminDeclarationAppRoutes = () => (
    <Routes>
        
                        <Route key={"routeAdminDeclarationContextePage"} element={<RequireAuth />}>
                          <Route path={AdminDeclarationRoutes.toAdminDeclarationContextePage()} element={<FeatureAdminDeclaration.AdminDeclarationContextePage />} />
                        </Route>
                      
                        <Route key={"routeAdminDeclarationCertificationsPage"} element={<RequireAuth />}>
                          <Route path={AdminDeclarationRoutes.toAdminDeclarationCertificationsPage()} element={<FeatureAdminDeclaration.AdminDeclarationCertificationsPage />} />
                        </Route>
                      
    </Routes>
);