import { Stack, Text } from "@fluentui/react";
import { ReactNode } from "react";

//TODO: move
type PageTitleProps = {
  pageTitle: string;
  children?: ReactNode;
  right?: ReactNode;
};
export const PageTitle = ({ pageTitle, children, right }: PageTitleProps) => (
  <Stack style={{ margin: 10 }}>
    <Stack horizontal verticalAlign="center">
      <Text variant="xLargePlus">{pageTitle}</Text>
      {right && right}
    </Stack>
    <Stack style={{ borderBottom: "1px solid #ddd", paddingBottom: 25 }}>
      {children && children}
    </Stack>
  </Stack>
);
