import Axios, { AxiosInstance } from "axios";
import React from "react";

export const AxiosContext =
  React.createContext<AxiosInstance | undefined>(undefined);

export function AxiosProvider({ children }: React.PropsWithChildren<unknown>) {
  const axios = React.useMemo(() => {
    const axios = Axios.create({
      headers: {
        Accept: "application/json",
      },
      withCredentials: true,
      validateStatus: (status) => {
        return status >= 200 && status < 500;
      },
    });
    return axios;
  }, []);

  return (
    <AxiosContext.Provider value={axios}>{children}</AxiosContext.Provider>
  );
}

export function useAxios() {
  return React.useContext(AxiosContext);
}
export default AxiosProvider;

export const axiosInstance = Axios.create({
  withCredentials: true
});
