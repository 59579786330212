import { useMemo } from "react";
import { DateTime, Interval } from "luxon";
import humanizeDuration from "humanize-duration";
export const humanizeDateUseMemo = (s?: string, sPrefix?: string, sSuffix?: string) =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useMemo((): string => {
    if (!s || s === "" || s === "Z") return "";
    
    const finish = DateTime.utc();
    const start = DateTime.fromISO(s, { zone: "utc" });
    const interval = Interval.fromDateTimes(start, finish);
    let seconds = Math.round(interval.toDuration("seconds").seconds);
    if (seconds > 90) {
      seconds = Math.round(seconds / 60) * 60;
    }
    return (sPrefix ? sPrefix : "")+humanizeDuration(seconds * 1000, { largest: 2 })+(sSuffix ? sSuffix : "");
  }, [s,sPrefix,sSuffix]);

export const humanizeDateToString = (s?: string, sPrefix?: string, sSuffix?: string) => {
  if (!s || s === "" || s === "Z") return "";

  const finish = DateTime.utc();
  const start = DateTime.fromISO(s, { zone: "utc" });
  const interval = Interval.fromDateTimes(start, finish);
  let seconds = Math.round(interval.toDuration("seconds").seconds);
  if (seconds > 90) {
    seconds = Math.round(seconds / 60) * 60;
  }
  return  (sPrefix ? sPrefix : "")+humanizeDuration(seconds * 1000, { largest: 2 })+(sSuffix ? sSuffix : "");
};


export const humanizeTimeDifference = (sStartDate: string, sEndDate?: string) => {
  if (!sStartDate || sStartDate === "" || sStartDate === "Z") return "";

  const finish = sEndDate && sEndDate !== "" ? DateTime.fromISO(sEndDate+"Z", { zone: "utc" }) : DateTime.utc();
  const start = DateTime.fromISO(sStartDate+"Z", { zone: "utc" });
  const interval = Interval.fromDateTimes(start, finish);
  let seconds = Math.round(interval.toDuration("seconds").seconds);
  // if (seconds > 90) {
  //   seconds = Math.round(seconds / 60) * 60;
  // }
  return  humanizeDuration(seconds * 1000, { largest: 2 });
};



