import {
  FilterEntries,
  FilterEntry,
  getLocalStorageName,
  useLocalStorage,
} from "@d4b/fluent-ui";
import { UrlParams } from "@d4b/api";
import { SelectableOptionMenuItemType } from "@fluentui/react";
import React, { useEffect } from "react";

export declare enum SearcherOptionType {
  Normal = 0,
  Divider = 1,
  Header = 2,
}

export type SearcherOptions = {
  key: string;
  text: string;
  itemType?: SelectableOptionMenuItemType;
};

export type IUseUrlParamsOptions = {
  pageName: string;
  dataListName: string;
  pageSize?: number;
  filters?: FilterEntries;
  defaultFilterKey: string;
  visibilityFilter?: any;
  filterOptions?: any[];
};

const getPredefinedFilter = (
  filters?: FilterEntries,
  key?: string
): FilterEntry | undefined => {
  let result: FilterEntry | undefined = undefined;
  if (!filters || !key) return undefined;

  filters.forEach((filter: FilterEntry) => {
    if (filter.key === key) {
      result = filter;
      return;
    }
  });
  return result;
};

export type UseUrlParams = {
  currentFilterKey: string;
  urlParams?: UrlParams;
  setUrlParams: React.Dispatch<React.SetStateAction<UrlParams | undefined>>;
  options: IUseUrlParamsOptions;
  setCurrentFilterKey: (key: string) => void;
  setCurrentPage: (page: number) => void;
  extraParams: any;
  // visibilityFilter: any;
  setExtraParams: (value: any) => void;
  pageName: string;
  dataListName: string;
  readyParams: boolean;
  setDataListName: (key: string) => void;
  filterOptions?: SearcherOptions[];
  localStorageSortObj?: any;
  setLocalStorageSort: (value: any) => void;
  setCurrentPageSize: (value: any) => void;
};

export const useUrlParams = (options: IUseUrlParamsOptions): UseUrlParams => {
  const {
    pageName,
    pageSize = 20,
    filters = [],
    defaultFilterKey = "0",
    visibilityFilter,
    filterOptions,
  } = options;
  const [dataListName, setDataListName] = React.useState<string>(
    options.dataListName
  );
  const [currentFilterKey, setCurrentFilterKey] = useLocalStorage<string>(
    pageName,
    defaultFilterKey
  );

  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [currentPageSize, setCurrentPageSize] =
    React.useState<number>(pageSize);
  const [extraParams, setExtraParams] = React.useState<any>({});
  const [localStorageSortObj, setLocalStorageSortObj] = React.useState<any>({});

  const [urlParams, setUrlParams] = React.useState<UrlParams | undefined>(
    getPredefinedFilter(filters, currentFilterKey)?.filter ||
      getPredefinedFilter(filters, defaultFilterKey)?.filter ||
      undefined
  );
  const [readyParams, setReadyParams] = React.useState<boolean>(false);

  const paramsFromFilter = (extra?: object) => {
    const current = getPredefinedFilter(filters, currentFilterKey);
    let result = {
      ...current?.filter,
      ...extra,
      ...visibilityFilter,
      page: currentPage,
      pageSize: currentPageSize,
    };
    return result;
  };

  //console.log(getPredefinedFilter(filters, currentFilterKey))

  // default active sort or from localStorage
  const localStorageKey = getLocalStorageName({
    page: { name: pageName },
    detailsListName: dataListName,
  });
  const [localStorageSort, setLocalStorageSort] = useLocalStorage(
    localStorageKey,
    ""
  );

  useEffect(() => {
    try {
      const sortItem = JSON.parse(localStorageSort);
      if (typeof sortItem === "object" && sortItem[0]?.field) {
        let orderBy = "";
        sortItem?.forEach((s: any, k: any) => {
          if (typeof s == "string") {
            orderBy += `${s} asc${k < sortItem?.length - 1 ? ", " : ""}`;
          } else {
            orderBy += `${s.field} ${s.direction || "asc"}${
              k < sortItem?.length - 1 ? ", " : ""
            }`;
          }
        });

        setExtraParams({ ...extraParams, orderBy: orderBy });
        setLocalStorageSortObj(sortItem);
        return;
      }
    } catch (err) {}

    const defaultOrderBy = getPredefinedFilter(
      filters,
      currentFilterKey
    )?.orderBy;

    if (!defaultOrderBy) return;
    setExtraParams({ orderBy: `${defaultOrderBy.field} asc` });
    setLocalStorageSort(
      JSON.stringify([{ field: `${defaultOrderBy.field}`, direction: "asc" }])
    );
    setLocalStorageSortObj([{ field: defaultOrderBy.field, direction: "asc" }]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorageSort, currentPageSize]);

  React.useEffect(() => {
    // console.log(currentFilterKey, currentPage);
    setUrlParams(paramsFromFilter(extraParams));
    // eslint-disable-next-line
  }, [currentFilterKey, currentPage, extraParams]);

  React.useEffect(() => {
    if (!extraParams.orderBy || !urlParams) return;
    setReadyParams(true);
  }, [extraParams, urlParams]);

  return {
    urlParams,
    setUrlParams,
    options,
    setCurrentPage,
    setCurrentFilterKey,
    currentFilterKey,
    extraParams,
    setExtraParams,
    pageName,
    dataListName,
    setDataListName,
    readyParams,
    filterOptions,
    localStorageSortObj,
    setLocalStorageSort,
    setCurrentPageSize,
  };
};
