import React from "react";

        import { VotreDeclarationRoutes } from "./VotreDeclarationRoutes";
        import { RequireAuth } from "@d4b/fluent-ui";
        import { Routes, Route } from "react-router-dom";
        

const FeatureVotreDeclaration = {
    
VotreDeclarationPage: React.lazy(() => import("@DataDock/OrganismeDeFormation/VotreDeclaration/views/VotreDeclarationPage")),


VisualisationDesCertificationsPage: React.lazy(() => import("@DataDock/OrganismeDeFormation/VotreDeclaration/views/VisualisationDesCertificationsPage")),


ValiderDeclarationPage: React.lazy(() => import("@DataDock/OrganismeDeFormation/VotreDeclaration/views/ValiderDeclarationPage")),


};

export const VotreDeclarationAppRoutes = () => (
    <Routes>
        
                        <Route key={"routeVotreDeclarationPage"} element={<RequireAuth />}>
                          <Route path={VotreDeclarationRoutes.toVotreDeclarationPage()} element={<FeatureVotreDeclaration.VotreDeclarationPage />} />
                        </Route>
                      
                        <Route key={"routeVisualisationDesCertificationsPage"} element={<RequireAuth />}>
                          <Route path={VotreDeclarationRoutes.toVisualisationDesCertificationsPage()} element={<FeatureVotreDeclaration.VisualisationDesCertificationsPage />} />
                        </Route>
                      
                        <Route key={"routeValiderDeclarationPage"} element={<RequireAuth />}>
                          <Route path={VotreDeclarationRoutes.toValiderDeclarationPage()} element={<FeatureVotreDeclaration.ValiderDeclarationPage />} />
                        </Route>
                      
    </Routes>
);