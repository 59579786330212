import React from "react";

        import { CommentairesRoutes } from "./CommentairesRoutes";
        import { RequireAuth } from "@d4b/fluent-ui";
        import { Routes, Route } from "react-router-dom";
        

const FeatureCommentaires = {
    
CommentaireOfListePage: React.lazy(() => import("@DataDock/Financeur/Commentaires/views/CommentaireOfListePage")),


CommentaireOfAjouterPage: React.lazy(() => import("@DataDock/Financeur/Commentaires/views/CommentaireOfAjouterPage")),


CommentaireOfDetailsPage: React.lazy(() => import("@DataDock/Financeur/Commentaires/views/CommentaireOfDetailsPage")),


};

export const CommentairesAppRoutes = () => (
    <Routes>
        
                        <Route key={"routeCommentaireOfListePage"} element={<RequireAuth />}>
                          <Route path={CommentairesRoutes.toCommentaireOfListePage()} element={<FeatureCommentaires.CommentaireOfListePage />} />
                        </Route>
                      
                        <Route key={"routeCommentaireOfAjouterPage"} element={<RequireAuth />}>
                          <Route path={CommentairesRoutes.toCommentaireOfAjouterPage()} element={<FeatureCommentaires.CommentaireOfAjouterPage />} />
                        </Route>
                      
                        <Route key={"routeCommentaireOfDetailsPage"} element={<RequireAuth />}>
                          <Route path={CommentairesRoutes.toCommentaireOfDetailsPage()} element={<FeatureCommentaires.CommentaireOfDetailsPage />} />
                        </Route>
                      
    </Routes>
);