import {
  CommandButton,
  ContextualMenu,
  IContextualMenuItem,
} from "@fluentui/react";
import React from "react";

type ViewModeMenuProps = {
  defaultView?: string;
  modeView?: string;
  changeView: any;
};
export const ViewModeMenu = ({
  modeView,
  defaultView,
  changeView,
}: ViewModeMenuProps) => {
  const linkRef = React.useRef(null);
  const [showContextualMenu, setShowContextualMenu] = React.useState(false);
  const onShowContextualMenu = React.useCallback(
    (ev: React.MouseEvent<HTMLElement>) => {
      ev.preventDefault();
      setShowContextualMenu(true);
    },
    []
  );

  const onHideContextualMenu = React.useCallback(
    () => setShowContextualMenu(false),
    []
  );
  const menuItems: IContextualMenuItem[] = [
    {
      key: "Listview",
      text: "List view",
      onClick: () => changeView("list"),
      iconProps: { iconName: "List" },
      canCheck: true,
      checked: modeView && modeView === "list" ? true : false,
    },
    {
      key: "gridview",
      text: "Grid view",
      onClick: () => changeView("grid"),
      iconProps: { iconName: "BacklogBoard" },
      canCheck: true,
      checked: modeView && modeView === "grid" ? true : false,
    },
  ];

  return (
    <>
      <CommandButton
        iconProps={{ iconName: modeView === "grid" ? "BacklogBoard" : "List" }}
        elementRef={linkRef}
        onClick={onShowContextualMenu}
        text="View"
        styles={{ flexContainer: { flexDirection: "row-reverse" } }}
      />
      <ContextualMenu
        items={menuItems}
        hidden={!showContextualMenu}
        target={linkRef}
        onItemClick={onHideContextualMenu}
        onDismiss={onHideContextualMenu}
      />
    </>
  );
};
