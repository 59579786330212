/**
* AdminGestionOf Routes
*/

export const AdminGestionOfRoutes = {
	

toAdminRechercheOfPage: ()  : string =>  '/interface/of-administration/of-recherche',

toAdminAjouterOFPage: ()  : string =>  '/interface/of-administration/of-ajout-siren-nda',

toAdminExporterOfPage: ()  : string =>  '/interface/of-administration/of-export',

toAdminImporterOfPage: ()  : string =>  '/interface/of-administration/of-import',

};