import React from "react";

        import { VotreEntrepriseRoutes } from "./VotreEntrepriseRoutes";
        import { RequireAuth } from "@d4b/fluent-ui";
        import { Routes, Route } from "react-router-dom";
        

const FeatureVotreEntreprise = {
    
VisualisationInformationsGeneralesPage: React.lazy(() => import("@DataDock/OrganismeDeFormation/VotreEntreprise/views/VisualisationInformationsGeneralesPage")),


ModificationInformationsGeneralesPage: React.lazy(() => import("@DataDock/OrganismeDeFormation/VotreEntreprise/views/ModificationInformationsGeneralesPage")),


InformationEtablissementPrincipalPage: React.lazy(() => import("@DataDock/OrganismeDeFormation/VotreEntreprise/views/InformationEtablissementPrincipalPage")),


ListeDesEtablissementsPage: React.lazy(() => import("@DataDock/OrganismeDeFormation/VotreEntreprise/views/ListeDesEtablissementsPage")),


ModificationEtablissementPrincipalPage: React.lazy(() => import("@DataDock/OrganismeDeFormation/VotreEntreprise/views/ModificationEtablissementPrincipalPage")),


AutresEntreprisesAjoutPage: React.lazy(() => import("@DataDock/OrganismeDeFormation/VotreEntreprise/views/AutresEntreprisesAjoutPage")),


AutresEntreprisesPage: React.lazy(() => import("@DataDock/OrganismeDeFormation/VotreEntreprise/views/AutresEntreprisesPage")),


};

export const VotreEntrepriseAppRoutes = () => (
    <Routes>
        
                        <Route key={"routeVisualisationInformationsGeneralesPage"} element={<RequireAuth />}>
                          <Route path={VotreEntrepriseRoutes.toVisualisationInformationsGeneralesPage()} element={<FeatureVotreEntreprise.VisualisationInformationsGeneralesPage />} />
                        </Route>
                      
                        <Route key={"routeModificationInformationsGeneralesPage"} element={<RequireAuth />}>
                          <Route path={VotreEntrepriseRoutes.toModificationInformationsGeneralesPage()} element={<FeatureVotreEntreprise.ModificationInformationsGeneralesPage />} />
                        </Route>
                      
                        <Route key={"routeInformationEtablissementPrincipalPage"} element={<RequireAuth />}>
                          <Route path={VotreEntrepriseRoutes.toInformationEtablissementPrincipalPage()} element={<FeatureVotreEntreprise.InformationEtablissementPrincipalPage />} />
                        </Route>
                      
                        <Route key={"routeListeDesEtablissementsPage"} element={<RequireAuth />}>
                          <Route path={VotreEntrepriseRoutes.toListeDesEtablissementsPage()} element={<FeatureVotreEntreprise.ListeDesEtablissementsPage />} />
                        </Route>
                      
                        <Route key={"routeModificationEtablissementPrincipalPage"} element={<RequireAuth />}>
                          <Route path={VotreEntrepriseRoutes.toModificationEtablissementPrincipalPage()} element={<FeatureVotreEntreprise.ModificationEtablissementPrincipalPage />} />
                        </Route>
                      
                        <Route key={"routeAutresEntreprisesAjoutPage"} element={<RequireAuth />}>
                          <Route path={VotreEntrepriseRoutes.toAutresEntreprisesAjoutPage()} element={<FeatureVotreEntreprise.AutresEntreprisesAjoutPage />} />
                        </Route>
                      
                        <Route key={"routeAutresEntreprisesPage"} element={<RequireAuth />}>
                          <Route path={VotreEntrepriseRoutes.toAutresEntreprisesPage()} element={<FeatureVotreEntreprise.AutresEntreprisesPage />} />
                        </Route>
                      
    </Routes>
);