import { IPageMetaData } from "lib";

type Props = {
  pageMetaData?: IPageMetaData;
  pageUid?: string;
  componentPath?: string;
  componentName?: string;
};

export const PageMetaDataViewer = ({
  pageMetaData,
  pageUid,
  componentPath,
  componentName,
}: Props) => {
  const frontSrcPath =
    window && (window as any)._env_
      ? (window as any)._env_.RS_FRONT_SRC_PATH
      : "";
  const vsCodeHRef = `vscode://file/${frontSrcPath}${
    pageMetaData?.componentPath || componentPath
  }`;

  return (
    <span style={{ position: "absolute", top: 54, right: 20, zIndex: 1 }}>
      <a href={vsCodeHRef}>{pageMetaData?.pageUid || pageUid}</a>
    </span>
  );
};
export default PageMetaDataViewer;
