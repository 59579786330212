import { useOutsideClick } from "@d4b/fluent-ui";
import { VotreCompteRoutes } from "@DataDock/InterfaceGlobale/VotreCompte/VotreCompteRoutes";
import {
  CommandButton,
  DefaultEffects,
  getTheme,
  IButtonStyles,
  IStackStyles,
  ITextStyles,
  Link,
  Stack,
  Text,
} from "@fluentui/react";
import { useAuth } from "lib/utils/auth";
import React, { createRef } from "react";
import { useNavigate } from "react-router-dom";

export const theme = getTheme();
export const themePalette = theme.palette;
const UserDropDownPositionStyles: IStackStyles = {
  root: {
    position: "absolute",
    top: 48,
    right: 0,
    width: 320,
    boxShadow: DefaultEffects.elevation16,
    backgroundColor: "#FFF",
    zIndex: 100,
  },
};
const UserRefButtonStyles: IButtonStyles = {
  root: {
    color: "inherit",
    backgroundColor: "inherit",
    borderRadius: "50%",
    width: 32,
    height: 32,
    borderColor: themePalette.white,
  },
  flexContainer: { justifyContent: "center" },
  rootHovered: { color: themePalette.white },
};
const UserDropDownBlockStyles: IStackStyles = {
  root: {
    zIndex: 999,
    color: themePalette.neutralSecondary,
  },
};
const BigCircleButtonStyles: IButtonStyles = {
  root: {
    color: "inherit",
    width: 80,
    height: 80,
    margin: 20,
    backgroundColor: "inherit",
    borderRadius: "50%",
    borderColor: themePalette.neutralDark,
    fontSize: 24,
  },
  flexContainer: { justifyContent: "center" },
  rootHovered: { color: themePalette.neutralDark },
};

const subTextsStyles: ITextStyles = {
  root: { lineHeight: 24, width: 190 },
};
const signoutStyles: ITextStyles = {
  root: { padding: 10 },
};

type UserPreferencePanelProps = {};

export const UserPreferencePanel = (_: UserPreferencePanelProps) => {
  const [showDropDownMenu, setShowDropDownMenu] = React.useState(false);
  const { compteUtilisateur, signout } = useAuth();
  const navigate = useNavigate();
  const ref = createRef<HTMLDivElement>();
  useOutsideClick(ref, () => {
    setShowDropDownMenu(false);
  });

  const initials = compteUtilisateur.prenom?.substr(0, 1).toUpperCase();
  function changeShowDropDownMenu() {
    setShowDropDownMenu((prev) => !prev);
  }

  return (
    <div
      ref={ref}
      style={{
        width: 48,
        height: 48,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CommandButton
        styles={UserRefButtonStyles}
        text={initials}
        onClick={() => changeShowDropDownMenu()}
      />

      {showDropDownMenu && (
        <Stack styles={UserDropDownPositionStyles}>
          <Stack styles={UserDropDownBlockStyles}>
            <Stack horizontal>
              <Stack.Item>
                <CommandButton styles={BigCircleButtonStyles} text={initials} />
              </Stack.Item>
              <Stack.Item grow align="center">
                <Text
                  styles={subTextsStyles}
                  key={"name"}
                  variant="xLarge"
                  nowrap
                  block
                >
                  {compteUtilisateur?.prenom}
                </Text>
                <Text styles={subTextsStyles} key={"email"} nowrap block>
                  {compteUtilisateur?.email}
                </Text>
                <Link
                  onClick={() => {
                    navigate(
                      VotreCompteRoutes.toInformationsDeComptePage()
                    );
                    changeShowDropDownMenu();
                  }}
                >
                  Votre compte
                </Link>
              </Stack.Item>
            </Stack>
            <Stack horizontal>
              {/* <Stack.Item grow>
                <Text styles={appTextStyles} variant="medium" nowrap block>
                  DataDock
                </Text>
              </Stack.Item> */}
              <Stack.Item>
                <Link
                  styles={signoutStyles}
                  onClick={() => {
                    changeShowDropDownMenu();
                    signout((_: any) => navigate("/interface"));
                  }}
                >
                  Se déconnecter
                </Link>
              </Stack.Item>
            </Stack>
          </Stack>
        </Stack>
      )}
    </div>
  );
};
