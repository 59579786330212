import { CommandButton, DefaultPalette, Stack } from "@fluentui/react";
import { useNavigate } from "react-router-dom";

export type HeaderNavLink = {
  title: string;
  path: string;
  key: string;
  status?: string;
};

export type MenuPrincipaleProps = {
  activeLinkKey?: string;
  links?: HeaderNavLink[];
};

export const MenuPrincipale = ({ activeLinkKey, links = [] }: MenuPrincipaleProps) => {
  const navigate = useNavigate();

  return (
    <Stack horizontal styles={{ root: { width: "100%", /* position: "fixed", zIndex:9999 */} }}>
      {links.map((link,index) => {
        return (
          <Stack.Item key={index} grow={1} style={{borderRight: "1px solid #fff"}}>
            <CommandButton
              styles={{
                root: {
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  color: activeLinkKey === link.key ? DefaultPalette.themeTertiary : DefaultPalette.white,
                  backgroundColor: "#230040",
                  height: 48,
                  fontWeight: activeLinkKey === link.key ? "bold" : "",
                  borderRadius: 0
                },
                rootHovered: { color: DefaultPalette.white },
              }}
              onClick={() => navigate(link.path)}
            >
              {link.title}
            </CommandButton>
          </Stack.Item>
        );
      })}
    </Stack>
  );
};
