import { InterfaceGlobaleAppRoutes } from "./InterfaceGlobale/InterfaceGlobale/InterfaceGlobaleAppRoutes";
import { VotreCompteAppRoutes } from "./InterfaceGlobale/VotreCompte/VotreCompteAppRoutes";
import { CreerUnCompteAppRoutes } from "./OrganismeDeFormation/CreerUnCompte/CreerUnCompteAppRoutes";
import { AdministrationAppRoutes } from "./OrganismeDeFormation/Administration/AdministrationAppRoutes";
import { VotreEntrepriseAppRoutes } from "./OrganismeDeFormation/VotreEntreprise/VotreEntrepriseAppRoutes";
import { VotreDeclarationAppRoutes } from "./OrganismeDeFormation/VotreDeclaration/VotreDeclarationAppRoutes";
import { OFAppRoutes } from "./Financeur/OF/OFAppRoutes";
import { ExamenDeclarationAppRoutes } from "./Financeur/ExamenDeclaration/ExamenDeclarationAppRoutes";
import { CommentairesAppRoutes } from "./Financeur/Commentaires/CommentairesAppRoutes";
import { TableauDeBordAppRoutes } from "./Financeur/TableauDeBord/TableauDeBordAppRoutes";
import { AdminGestionOfAppRoutes } from "./AdministrateurDatadock/AdminGestionOf/AdminGestionOfAppRoutes";
import { AdminStructuresFinanceurAppRoutes } from "./AdministrateurDatadock/AdminStructuresFinanceur/AdminStructuresFinanceurAppRoutes";
import { AdminReferentielsAppRoutes } from "./AdministrateurDatadock/AdminReferentiels/AdminReferentielsAppRoutes";
import { AdminTraitementsAppRoutes } from "./AdministrateurDatadock/AdminTraitements/AdminTraitementsAppRoutes";
import { AdminDeclarationAppRoutes } from "./AdministrateurDatadock/AdminDeclaration/AdminDeclarationAppRoutes";
import { AdminFicheOfAppRoutes } from "./AdministrateurDatadock/AdminFicheOf/AdminFicheOfAppRoutes";
 
export const DataDockApplicationRoutes = () => (
<>
<InterfaceGlobaleAppRoutes />
<VotreCompteAppRoutes />
<CreerUnCompteAppRoutes />
<AdministrationAppRoutes />
<VotreEntrepriseAppRoutes />
<VotreDeclarationAppRoutes />
<OFAppRoutes />
<ExamenDeclarationAppRoutes />
<CommentairesAppRoutes />
<TableauDeBordAppRoutes />
<AdminGestionOfAppRoutes />
<AdminStructuresFinanceurAppRoutes />
<AdminReferentielsAppRoutes />
<AdminTraitementsAppRoutes />
<AdminDeclarationAppRoutes />
<AdminFicheOfAppRoutes />
</>); 