/**
* Administration Routes
*/

export const AdministrationRoutes = {
	

toAdministrationDesComptesPage: ()  : string =>  '/interface/votre-compte/compte-delegue',

toAdministrationCreationComptePage: ()  : string =>  '/interface/votre-compte/compte-delegue/creation',

toAdministrationModifierComptePage: ()  : string =>  '/interface/votre-compte/compte-delegue/modification',

toAdministrationSupprimerOFPage: ()  : string =>  '/interface/votre-compte/suppression-of',

};