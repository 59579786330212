import {
  createSafeContext,
  fetchAxios,
  resolveApiUrl,
  useAxios,
  useSafeContext,
} from "@d4b/api";
import WebServiceComponent from "@rs-core/rs-app/data-elements/WebServiceComponent";
import { CompteUtilisateur } from "lib";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useProvideAuth } from "./auth";

export type IWebService = WebServiceComponent.WebServiceComponent & {
  RS_APP_LABEL: string;
  RS_APP_API_URL: string;
  RS_APP_URL: string;
  RS_ADMIN_URL: string;
  MODE_DEBUG: boolean;
};

// export const API_URL = resolveApiUrl();
let AppContext = createSafeContext<any>();
export const useAppContext = () => useSafeContext<IAppContextValue>(AppContext);

type IAppContextValue = {
  compteUtilisateur: CompteUtilisateur;
  error: Error | null;
  myRoles: any[];
  ownerRoles: any[];
  currentOwnerRole: undefined;
  setCurrentOwnerRole: React.Dispatch<React.SetStateAction<undefined>>;
  flashMessage: string;
  setFlashMessage: React.Dispatch<React.SetStateAction<string>>;
  ws: IWebService | undefined;
  isDevelopmentEnvironment: boolean;
  hasRole: (role: string) => boolean;
  findFirstRole: (roleName: string) => undefined;
  isLoading: boolean;
  isAuthenticated: boolean;
  loadingText: string;

  //refetchMyAccounts:any;
};

const checkRole = (
  compteUtilisateur: CompteUtilisateur,
  roles: any,
  roleName: string
): boolean => {
  if (!roles || roles.length === 0) {
    // Allow Admin to have all roles
    if (roleName === "Administrator" && compteUtilisateur.utilisateurId === "Admin") return true;
    return false;
  }

  let result = false;
  // eslint-disable-next-line array-callback-return
  roles.map((x: any) => {
    if (x.RoleName === roleName) {
      result = true;
      // eslint-disable-next-line array-callback-return
      return; // Early exit:
    }
  });
  // console.log(roles);
  return result;
};

export const AppContextProvider = (props: any) => {
  const [flashMessage, setFlashMessage] = useState("");
  const [currentOwnerRole, setCurrentOwnerRole] =
    useState<undefined>(undefined);

  const [loadingText, setLoadingText] = useState("Starting application");

  const axios = useAxios();

  const {
    data: ws,
    isLoading: isLoadingWs,
    error = null,
  } = useQuery<IWebService, Error>("WebServiceComponent", () =>
    fetchAxios<IWebService>(axios, resolveApiUrl(), "WebServiceComponent")
  );
  const { compteUtilisateur, isLoading: isLoadingAuth, isAuthenticated } = useProvideAuth();
  const myRoles: any[] = [];

  useEffect(() => {
    if (isLoadingAuth || isLoadingWs) {
      setLoadingText("Loading...");
      return;
    }
    setLoadingText("Done");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingAuth, isLoadingWs]);

  const value: IAppContextValue = {
    error,
    flashMessage,
    setFlashMessage,
    ws,
    compteUtilisateur: compteUtilisateur as CompteUtilisateur,
    myRoles: myRoles || [],
    ownerRoles: [],
    currentOwnerRole,
    isAuthenticated,
    isLoading: isLoadingAuth || isLoadingWs,
    isDevelopmentEnvironment: process?.env?.NODE_ENV === "development",
    loadingText,
    setCurrentOwnerRole,
    hasRole: (roleName: string): boolean =>
      checkRole(compteUtilisateur, myRoles || [], roleName),
    findFirstRole: (roleName: string) => undefined,
  };
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
  window.addEventListener("resize", () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });
  return (
    <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
  );
};

export default AppContextProvider;
