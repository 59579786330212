import { FormDropdown, FormTextField, useForm } from "@d4b/fluent-ui";
import { ApiError } from "@DataDock/InterfaceGlobale/InterfaceGlobale/views/ReinitialisationMotDePassePage";
import {
  DefaultPalette,
  DefaultSpacing,
  Icon,
  Stack,
  Text,
} from "@fluentui/react";
import { Form } from "components";
import { AlertMessage } from "components/AlertMessage";
import { REGEX } from "lib/utils/Regex";
import { useState } from "react";

type Props = {
  linkName: string;
  dataElement: any;
  textButton: string;
  profiles: any[];
};

export const AjouterCompteForm = ({
  dataElement,
  linkName,
  textButton,
  profiles,
}: Props) => {
  const initFromState = () => ({} as any);
  const formState = useForm<any>(initFromState());

  const { cleanValues, checkRequired, values } = formState;
  const onCollectErrors = (errors: any) => {
    checkRequired("profil", errors, "champ obligatoire");
    checkRequired("civilite", errors, "champ obligatoire");
    checkRequired("email", errors, "champ obligatoire");
    checkRequired("confirmEmail", errors, "champ obligatoire");
    checkRequired("nomDeFamille", errors, "champ obligatoire");
    checkRequired("prenom", errors, "champ obligatoire");
    if (values.email?.search(REGEX.EMAIL_REGEXP) === -1) {
      errors["email"] = "Veuillez entrer un email valide";
    } else if (values.email !== values.confirmEmail)
      errors["confirmEmail"] = "L'adresse email ne correspond pas";
  };
  const [success, setSuccess] = useState<string>();
  const [error, setError] = useState<ApiError | false>();
  const onTransformValues = () => {
    const formData = new FormData();
    const body = cleanValues();
    for (let key in body) {
      const objValue = (body as any)[key];
      formData.append(key, objValue);
    }
    return formData;
  };

  const onServerReply = (reply: any) => {
    const { id, message, successMessage } = reply;
    setSuccess(successMessage || message);
    if (!id) return;
  };

  return (
    <>
      {success && !error && <AlertMessage message={success} />}
      {error && error.errorMessage && (
        <AlertMessage message={error.errorMessage} />
      )}
      <Stack horizontal>
        <Stack.Item grow={3}>
          <Form
            dataElement={dataElement}
            actionButtonText={textButton}
            actionButtonStyles={{ width: 300 }}
            linkName={linkName}
            formState={formState}
            initFromState={initFromState}
            onCollectErrors={onCollectErrors}
            onTransformValues={onTransformValues}
            onServerReply={onServerReply}
            setError={setError}
          >
            <FormTextField
              styles={{ root: { width: 340 } }}
              name="email"
              label="Email"
              required
              formState={formState}
            />
            <FormTextField
              styles={{ root: { width: 340 } }}
              name="confirmEmail"
              label="Confirmer votre adresse email"
              required
              formState={formState}
            />
            <FormDropdown
              label="Votre civilité"
              name="civilite"
              options={[
                { key: "TITLE_MALE", text: "M." },
                { key: "TITLE_FEMALE", text: "Mm." },
              ]}
              required
              formState={formState}
              styles={{ root: { width: 120 } }}
            />
            <Stack horizontal tokens={{ childrenGap: DefaultSpacing.l1 }}>
              <FormTextField
                name="nomDeFamille"
                label="Votre nom"
                required
                styles={{ root: { width: 220 } }}
                formState={formState}
              />
              <FormTextField
                name="prenom"
                label="Votre prénom"
                required
                styles={{ root: { width: 220 } }}
                formState={formState}
              />
            </Stack>
            <FormDropdown
              label="Role"
              options={profiles}
              required
              styles={{
                root: { width: 180, marginBottom: DefaultSpacing.l1 },
              }}
              name="profil"
              formState={formState}
            />
          </Form>
        </Stack.Item>

        <Stack.Item
          styles={{
            root: {
              backgroundColor: DefaultPalette.neutralLight,
              padding: DefaultSpacing.m,
              width: 200,
            },
          }}
        >
          <Stack tokens={{ childrenGap: DefaultSpacing.l1 }}>
            <Stack horizontal tokens={{ childrenGap: DefaultSpacing.s1 }}>
              <Icon
                style={{ fontSize: 48, color: DefaultPalette.green }}
                iconName="Info"
              />
              <Text>
                Votre collaborateur va recevoir un email pour finaliser la
                création de son compte en créant son mot de passe.
              </Text>
            </Stack>

            <Text>
              Il dispose d'un délai de 72 heures pour suivre le lien et achever
              la création de son compte. Après ce délai, le lien sera périmé et
              le compte délégué sera détruit.
            </Text>
          </Stack>
        </Stack.Item>
      </Stack>
    </>
  );
};
export default AjouterCompteForm;
