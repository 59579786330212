import { LabeledText, Section } from "@d4b/fluent-ui";
import {
  CommandButton,
  DefaultSpacing,
  IButtonStyles,
  Icon,
  Image,
  Label,
  Link,
  Panel,
  PanelType,
  Pivot,
  PivotItem,
  Stack,
  Text
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { PageMetaData } from "lib";
import { useNavigate } from "react-router-dom";
import { useFoundMetaData } from ".";

export type DevToolsProps = {
  pageId?: string;
  pageMetaData?: PageMetaData;
};
const LinkJira = ({ ticketJira }: any) => {
  const url = "https://d4b.atlassian.net/browse/" + ticketJira;
  return (
    <Link href={url} target="_blank" rel="noopener noreferrer">
      {ticketJira}
    </Link>
  );
};
type Props = {
  page: Page;
};
export const SiteMapLink = ({ page }: Props) => {
  const navigate = useNavigate();
  //const hasStatus = page.status && page.status !== "";
  return (
    <Stack horizontal tokens={{ childrenGap: DefaultSpacing.s1 }}>
      <Stack horizontal tokens={{ childrenGap: DefaultSpacing.s1 }}>
        <Icon
          styles={{
            root: {
              color: page.status === "3 - Approved" ? "green" : "gray",
            },
          }}
          iconName={
            page.status === "3 - Approved" ? "SkypeCircleCheck" : "CircleRing"
          }
        />
        <Link onClick={() => navigate(page.route)}>
          {page.componentName}
        </Link>
        <Text>
          {/* ({page.pageTitle} - {page.pageUid}) */}({page.pageUid})
        </Text>

        <LinkJira ticketJira={page.refJira} />
      </Stack>

      {/* <Text>{hasStatus ? `(${page.status})` : ""}</Text> */}
    </Stack>
  );
};
type Page = {
  name: string;
  pageUid: string;
  pageTitle: string;
  componentPath: string;
  componentName: string;
  route: string;
  refJira: string;
  status: string;
  reqsourcedescription: string;
};

export const DevTools = ({ pageId }: DevToolsProps) => {
  const { application, foundMetaData} = useFoundMetaData(pageId);

  const DevToolsButtonStyles: IButtonStyles = {
    root: {
      position: "fixed",
      top: 100,
      right: 10,
      zIndex: 100,
    },
  };
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);
  const frontSrcPath =
    window && (window as any)._env_
      ? (window as any)._env_.RS_FRONT_SRC_PATH
      : "";
  const vsCodeHRef = `vscode://file/${frontSrcPath}/${
    foundMetaData?.relativePath || ""
  }`;

  return (
    <>
      <Panel
        headerText="Dev Tools"
        isOpen={isOpen}
        onDismiss={dismissPanel}
        closeButtonAriaLabel="Close"
        type={PanelType.custom}
        customWidth="620px"
      >
        <Pivot styles={{ root: { marginTop: DefaultSpacing.l2 } }}>
          <PivotItem headerText="Page" key="Page">
            <Stack styles={{ root: { marginTop: DefaultSpacing.l2 } }}>
              <LabeledText label="id" text={foundMetaData?.id} />
              <LabeledText
                label="reqSourceType"
                text={foundMetaData?.reqSourceType}
              />
              <LabeledText
                label="reqSourceRef"
                text={foundMetaData?.reqSourceRef}
              />
              <LabeledText label="pageUid" text={foundMetaData?.pageUid} />
              <LabeledText
                label="componentName"
                text={foundMetaData?.componentName}
              />
              <LabeledText label="route" text={foundMetaData?.route} />

              <Label>Ticket Jira</Label>
              <LinkJira ticketJira={foundMetaData?.refJira} />

              <LabeledText label="status" text={foundMetaData?.status} />

              <LabeledText label="App Front Path" text={frontSrcPath} />
              <LabeledText
                label="Page Path"
                text={foundMetaData?.relativePath}
              />
              <a href={vsCodeHRef}>Open in vscode</a>
            </Stack>
          </PivotItem>
          <PivotItem headerText="Site map" key="Sitemap">
            <Stack styles={{ root: { marginBottom: DefaultSpacing.l2 } }}>
              {application?.keyFeatures?.map((keyFeature: any) => (
                <Section key={keyFeature.technicalName} sectionTitle={keyFeature.name}>
                  {keyFeature?.features?.length ? (
                    keyFeature?.features?.map((feature: any) => (
                      <Stack
                        key={feature.technicalName}
                        styles={{ root: { marginLeft: DefaultSpacing.l1 } }}
                      >
                        <Section sectionTitle={feature.name}>
                          {feature.pages?.length ? (
                            feature.pages?.map((page: Page) => (
                              <Stack
                                key={page.componentName}
                                styles={{
                                  root: { marginLeft: DefaultSpacing.l1 },
                                }}
                              >
                                <SiteMapLink page={page} />
                              </Stack>
                            ))
                          ) : (
                            <Text>No pages found!</Text>
                          )}
                        </Section>
                      </Stack>
                    ))
                  ) : (
                    <Text>No features found!</Text>
                  )}
                </Section>
              ))}
            </Stack>
          </PivotItem>
        </Pivot>
      </Panel>
      <CommandButton onClick={openPanel} styles={DevToolsButtonStyles}>
        <Image width={40} src={require("../img/favicon.ico")} />
      </CommandButton>
    </>
  );
};
