/**
* InterfaceGlobale Routes
*/

export const InterfaceGlobaleRoutes = {
	

toConditionsGeneralesPage: ()  : string =>  '/interface/conditions-generales',

toHomePage: ()  : string =>  '/',

toLoginPage: ()  : string =>  '/interface',

toReinitialisationMotDePassePage: ()  : string =>  '/interface/motdepasse-oublie',

toResetMotDePassePage: (token?: string)  : string => token ? `/interface/reset-mot-de-passe/${token}` :  '/interface/reset-mot-de-passe/:token',

toDroitInsuffisantPage: ()  : string =>  '/interface/droits-insuffisants',

toMaintenancePage: ()  : string =>  '/interface/maintenance-en-cours',

};