import React from "react";

        import { AdminReferentielsRoutes } from "./AdminReferentielsRoutes";
        import { RequireAuth } from "@d4b/fluent-ui";
        import { Routes, Route } from "react-router-dom";
        

const FeatureAdminReferentiels = {
    
AdminBaseSIRETPage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminReferentiels/views/AdminBaseSIRETPage")),


AdminApeListPage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminReferentiels/views/AdminApeListPage")),


AdminBasePactolePage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminReferentiels/views/AdminBasePactolePage")),


AdminCriteresEtIndicateursPage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminReferentiels/views/AdminCriteresEtIndicateursPage")),


AdminGestionDelaiStagnationOfPage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminReferentiels/views/AdminGestionDelaiStagnationOfPage")),


AdminCertificationsPage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminReferentiels/views/AdminCertificationsPage")),


AdminDetailCertificationPage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminReferentiels/views/AdminDetailCertificationPage")),


AdminModifierCertificationsPage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminReferentiels/views/AdminModifierCertificationsPage")),


AdminCreationCertificationPage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminReferentiels/views/AdminCreationCertificationPage")),


AdminGestionMotifPage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminReferentiels/views/AdminGestionMotifPage")),


AdminVisualisationMotifPage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminReferentiels/views/AdminVisualisationMotifPage")),


AdminModifierMotifPage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminReferentiels/views/AdminModifierMotifPage")),


AdminAjoutMotifPage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminReferentiels/views/AdminAjoutMotifPage")),


AdminRecapCriteresPage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminReferentiels/views/AdminRecapCriteresPage")),


AdminVisualisationCriterePage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminReferentiels/views/AdminVisualisationCriterePage")),


AdminAjouterCriterePage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminReferentiels/views/AdminAjouterCriterePage")),


AdminVisualisationIndicateurPage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminReferentiels/views/AdminVisualisationIndicateurPage")),


AdminAjouterIndicateurPage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminReferentiels/views/AdminAjouterIndicateurPage")),


AdminCertificateursPage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminReferentiels/views/AdminCertificateursPage")),


};

export const AdminReferentielsAppRoutes = () => (
    <Routes>
        
                        <Route key={"routeAdminBaseSIRETPage"} element={<RequireAuth />}>
                          <Route path={AdminReferentielsRoutes.toAdminBaseSIRETPage()} element={<FeatureAdminReferentiels.AdminBaseSIRETPage />} />
                        </Route>
                      
                        <Route key={"routeAdminApeListPage"} element={<RequireAuth />}>
                          <Route path={AdminReferentielsRoutes.toAdminApeListPage()} element={<FeatureAdminReferentiels.AdminApeListPage />} />
                        </Route>
                      
                        <Route key={"routeAdminBasePactolePage"} element={<RequireAuth />}>
                          <Route path={AdminReferentielsRoutes.toAdminBasePactolePage()} element={<FeatureAdminReferentiels.AdminBasePactolePage />} />
                        </Route>
                      
                        <Route key={"routeAdminCriteresEtIndicateursPage"} element={<RequireAuth />}>
                          <Route path={AdminReferentielsRoutes.toAdminCriteresEtIndicateursPage()} element={<FeatureAdminReferentiels.AdminCriteresEtIndicateursPage />} />
                        </Route>
                      
                        <Route key={"routeAdminGestionDelaiStagnationOfPage"} element={<RequireAuth />}>
                          <Route path={AdminReferentielsRoutes.toAdminGestionDelaiStagnationOfPage()} element={<FeatureAdminReferentiels.AdminGestionDelaiStagnationOfPage />} />
                        </Route>
                      
                        <Route key={"routeAdminCertificationsPage"} element={<RequireAuth />}>
                          <Route path={AdminReferentielsRoutes.toAdminCertificationsPage()} element={<FeatureAdminReferentiels.AdminCertificationsPage />} />
                        </Route>
                      
                        <Route key={"routeAdminDetailCertificationPage"} element={<RequireAuth />}>
                          <Route path={AdminReferentielsRoutes.toAdminDetailCertificationPage()} element={<FeatureAdminReferentiels.AdminDetailCertificationPage />} />
                        </Route>
                      
                        <Route key={"routeAdminModifierCertificationsPage"} element={<RequireAuth />}>
                          <Route path={AdminReferentielsRoutes.toAdminModifierCertificationsPage()} element={<FeatureAdminReferentiels.AdminModifierCertificationsPage />} />
                        </Route>
                      
                        <Route key={"routeAdminCreationCertificationPage"} element={<RequireAuth />}>
                          <Route path={AdminReferentielsRoutes.toAdminCreationCertificationPage()} element={<FeatureAdminReferentiels.AdminCreationCertificationPage />} />
                        </Route>
                      
                        <Route key={"routeAdminGestionMotifPage"} element={<RequireAuth />}>
                          <Route path={AdminReferentielsRoutes.toAdminGestionMotifPage()} element={<FeatureAdminReferentiels.AdminGestionMotifPage />} />
                        </Route>
                      
                        <Route key={"routeAdminVisualisationMotifPage"} element={<RequireAuth />}>
                          <Route path={AdminReferentielsRoutes.toAdminVisualisationMotifPage()} element={<FeatureAdminReferentiels.AdminVisualisationMotifPage />} />
                        </Route>
                      
                        <Route key={"routeAdminModifierMotifPage"} element={<RequireAuth />}>
                          <Route path={AdminReferentielsRoutes.toAdminModifierMotifPage()} element={<FeatureAdminReferentiels.AdminModifierMotifPage />} />
                        </Route>
                      
                        <Route key={"routeAdminAjoutMotifPage"} element={<RequireAuth />}>
                          <Route path={AdminReferentielsRoutes.toAdminAjoutMotifPage()} element={<FeatureAdminReferentiels.AdminAjoutMotifPage />} />
                        </Route>
                      
                        <Route key={"routeAdminRecapCriteresPage"} element={<RequireAuth />}>
                          <Route path={AdminReferentielsRoutes.toAdminRecapCriteresPage()} element={<FeatureAdminReferentiels.AdminRecapCriteresPage />} />
                        </Route>
                      
                        <Route key={"routeAdminVisualisationCriterePage"} element={<RequireAuth />}>
                          <Route path={AdminReferentielsRoutes.toAdminVisualisationCriterePage()} element={<FeatureAdminReferentiels.AdminVisualisationCriterePage />} />
                        </Route>
                      
                        <Route key={"routeAdminAjouterCriterePage"} element={<RequireAuth />}>
                          <Route path={AdminReferentielsRoutes.toAdminAjouterCriterePage()} element={<FeatureAdminReferentiels.AdminAjouterCriterePage />} />
                        </Route>
                      
                        <Route key={"routeAdminVisualisationIndicateurPage"} element={<RequireAuth />}>
                          <Route path={AdminReferentielsRoutes.toAdminVisualisationIndicateurPage()} element={<FeatureAdminReferentiels.AdminVisualisationIndicateurPage />} />
                        </Route>
                      
                        <Route key={"routeAdminAjouterIndicateurPage"} element={<RequireAuth />}>
                          <Route path={AdminReferentielsRoutes.toAdminAjouterIndicateurPage()} element={<FeatureAdminReferentiels.AdminAjouterIndicateurPage />} />
                        </Route>
                      
                        <Route key={"routeAdminCertificateursPage"} element={<RequireAuth />}>
                          <Route path={AdminReferentielsRoutes.toAdminCertificateursPage()} element={<FeatureAdminReferentiels.AdminCertificateursPage />} />
                        </Route>
                      
    </Routes>
);