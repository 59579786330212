import React from "react";

        import { ExamenDeclarationRoutes } from "./ExamenDeclarationRoutes";
        import { RequireAuth } from "@d4b/fluent-ui";
        import { Routes, Route } from "react-router-dom";
        

const FeatureExamenDeclaration = {
    
ExamenResumePage: React.lazy(() => import("@DataDock/Financeur/ExamenDeclaration/views/ExamenResumePage")),


FicheOfMieuxConnaitrePage: React.lazy(() => import("@DataDock/Financeur/ExamenDeclaration/views/FicheOfMieuxConnaitrePage")),


ExamenCertificationsPage: React.lazy(() => import("@DataDock/Financeur/ExamenDeclaration/views/ExamenCertificationsPage")),


ExamenFinalisationPage: React.lazy(() => import("@DataDock/Financeur/ExamenDeclaration/views/ExamenFinalisationPage")),


ExamenIndicateurPage: React.lazy(() => import("@DataDock/Financeur/ExamenDeclaration/views/ExamenIndicateurPage")),


ExamenAnalyseIndicateurPage: React.lazy(() => import("@DataDock/Financeur/ExamenDeclaration/views/ExamenAnalyseIndicateurPage")),


};

export const ExamenDeclarationAppRoutes = () => (
    <Routes>
        
                        <Route key={"routeExamenResumePage"} element={<RequireAuth />}>
                          <Route path={ExamenDeclarationRoutes.toExamenResumePage()} element={<FeatureExamenDeclaration.ExamenResumePage />} />
                        </Route>
                      
                        <Route key={"routeFicheOfMieuxConnaitrePage"} element={<RequireAuth />}>
                          <Route path={ExamenDeclarationRoutes.toFicheOfMieuxConnaitrePage()} element={<FeatureExamenDeclaration.FicheOfMieuxConnaitrePage />} />
                        </Route>
                      
                        <Route key={"routeExamenCertificationsPage"} element={<RequireAuth />}>
                          <Route path={ExamenDeclarationRoutes.toExamenCertificationsPage()} element={<FeatureExamenDeclaration.ExamenCertificationsPage />} />
                        </Route>
                      
                        <Route key={"routeExamenFinalisationPage"} element={<RequireAuth />}>
                          <Route path={ExamenDeclarationRoutes.toExamenFinalisationPage()} element={<FeatureExamenDeclaration.ExamenFinalisationPage />} />
                        </Route>
                      
                        <Route key={"routeExamenIndicateurPage"} element={<RequireAuth />}>
                          <Route path={ExamenDeclarationRoutes.toExamenIndicateurPage()} element={<FeatureExamenDeclaration.ExamenIndicateurPage />} />
                        </Route>
                      
                        <Route key={"routeExamenAnalyseIndicateurPage"} element={<RequireAuth />}>
                          <Route path={ExamenDeclarationRoutes.toExamenAnalyseIndicateurPage()} element={<FeatureExamenDeclaration.ExamenAnalyseIndicateurPage />} />
                        </Route>
                      
    </Routes>
);