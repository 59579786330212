import { DefaultPalette, DefaultSpacing, Stack, Text } from "@fluentui/react";
import { ReactNode } from "react";
import { Children } from "react";
import React from "react";

type FormSectionProps = {
  title?: string;
  children?: ReactNode | ReactNode[]
};

export const FormSection = ({
  title,
  children
}: FormSectionProps) => {
  return (
    <Stack
      styles={{
        root: {
          width: "100%",
        },
      }}
      tokens={{ childrenGap: 0 }}
    >
      <Text
        style={{
          fontWeight: "bold",
          color: DefaultPalette.themePrimary,
          marginBottom: DefaultSpacing.m,
        }}
        variant="xLarge"
      >
        {title && title}
      </Text>
      <Stack>
        {Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child);
          }
        })}
      </Stack>
    </Stack>
  );
};
