import React from "react";

        import { AdminFicheOfRoutes } from "./AdminFicheOfRoutes";
        import { RequireAuth } from "@d4b/fluent-ui";
        import { Routes, Route } from "react-router-dom";
        

const FeatureAdminFicheOf = {
    
FicheOfResumePage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminFicheOf/views/FicheOfResumePage")),


FicheOfInformationGeneralePage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminFicheOf/views/FicheOfInformationGeneralePage")),


FicheOfMieuxConnaitrePage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminFicheOf/views/FicheOfMieuxConnaitrePage")),


FicheOfEtablissementPrincipalePage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminFicheOf/views/FicheOfEtablissementPrincipalePage")),


FicheOfListeEtablissementPage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminFicheOf/views/FicheOfListeEtablissementPage")),


FicheOfHistoriqueQualiopiPage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminFicheOf/views/FicheOfHistoriqueQualiopiPage")),


FicheOfCertificateursPage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminFicheOf/views/FicheOfCertificateursPage")),


FicheOfHistoriquePage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminFicheOf/views/FicheOfHistoriquePage")),


FicheOfCommentairesPage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminFicheOf/views/FicheOfCommentairesPage")),


FicheOfCommentaireDetailsPage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminFicheOf/views/FicheOfCommentaireDetailsPage")),


CompteDelegePage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminFicheOf/views/CompteDelegePage")),


AjouterCompteDelegePage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminFicheOf/views/AjouterCompteDelegePage")),


FicheOfReferencementPage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminFicheOf/views/FicheOfReferencementPage")),


FicheOfRapportControlePage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminFicheOf/views/FicheOfRapportControlePage")),


};

export const AdminFicheOfAppRoutes = () => (
    <Routes>
        
                        <Route key={"routeFicheOfResumePage"} element={<RequireAuth />}>
                          <Route path={AdminFicheOfRoutes.toFicheOfResumePage()} element={<FeatureAdminFicheOf.FicheOfResumePage />} />
                        </Route>
                      
                        <Route key={"routeFicheOfInformationGeneralePage"} element={<RequireAuth />}>
                          <Route path={AdminFicheOfRoutes.toFicheOfInformationGeneralePage()} element={<FeatureAdminFicheOf.FicheOfInformationGeneralePage />} />
                        </Route>
                      
                        <Route key={"routeFicheOfMieuxConnaitrePage"} element={<RequireAuth />}>
                          <Route path={AdminFicheOfRoutes.toFicheOfMieuxConnaitrePage()} element={<FeatureAdminFicheOf.FicheOfMieuxConnaitrePage />} />
                        </Route>
                      
                        <Route key={"routeFicheOfEtablissementPrincipalePage"} element={<RequireAuth />}>
                          <Route path={AdminFicheOfRoutes.toFicheOfEtablissementPrincipalePage()} element={<FeatureAdminFicheOf.FicheOfEtablissementPrincipalePage />} />
                        </Route>
                      
                        <Route key={"routeFicheOfListeEtablissementPage"} element={<RequireAuth />}>
                          <Route path={AdminFicheOfRoutes.toFicheOfListeEtablissementPage()} element={<FeatureAdminFicheOf.FicheOfListeEtablissementPage />} />
                        </Route>
                      
                        <Route key={"routeFicheOfHistoriqueQualiopiPage"} element={<RequireAuth />}>
                          <Route path={AdminFicheOfRoutes.toFicheOfHistoriqueQualiopiPage()} element={<FeatureAdminFicheOf.FicheOfHistoriqueQualiopiPage />} />
                        </Route>
                      
                        <Route key={"routeFicheOfCertificateursPage"} element={<RequireAuth />}>
                          <Route path={AdminFicheOfRoutes.toFicheOfCertificateursPage()} element={<FeatureAdminFicheOf.FicheOfCertificateursPage />} />
                        </Route>
                      
                        <Route key={"routeFicheOfHistoriquePage"} element={<RequireAuth />}>
                          <Route path={AdminFicheOfRoutes.toFicheOfHistoriquePage()} element={<FeatureAdminFicheOf.FicheOfHistoriquePage />} />
                        </Route>
                      
                        <Route key={"routeFicheOfCommentairesPage"} element={<RequireAuth />}>
                          <Route path={AdminFicheOfRoutes.toFicheOfCommentairesPage()} element={<FeatureAdminFicheOf.FicheOfCommentairesPage />} />
                        </Route>
                      
                        <Route key={"routeFicheOfCommentaireDetailsPage"} element={<RequireAuth />}>
                          <Route path={AdminFicheOfRoutes.toFicheOfCommentaireDetailsPage()} element={<FeatureAdminFicheOf.FicheOfCommentaireDetailsPage />} />
                        </Route>
                      
                        <Route key={"routeCompteDelegePage"} element={<RequireAuth />}>
                          <Route path={AdminFicheOfRoutes.toCompteDelegePage()} element={<FeatureAdminFicheOf.CompteDelegePage />} />
                        </Route>
                      
                        <Route key={"routeAjouterCompteDelegePage"} element={<RequireAuth />}>
                          <Route path={AdminFicheOfRoutes.toAjouterCompteDelegePage()} element={<FeatureAdminFicheOf.AjouterCompteDelegePage />} />
                        </Route>
                      
                        <Route key={"routeFicheOfReferencementPage"} element={<RequireAuth />}>
                          <Route path={AdminFicheOfRoutes.toFicheOfReferencementPage()} element={<FeatureAdminFicheOf.FicheOfReferencementPage />} />
                        </Route>
                      
                        <Route key={"routeFicheOfRapportControlePage"} element={<RequireAuth />}>
                          <Route path={AdminFicheOfRoutes.toFicheOfRapportControlePage()} element={<FeatureAdminFicheOf.FicheOfRapportControlePage />} />
                        </Route>
                      
    </Routes>
);