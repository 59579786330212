import { DefaultSpacing, Stack, Text } from "@fluentui/react";
import React, { ReactNode } from "react";
import { Children } from "react";

type ContextSectionProps = {
  title: string;
  children?: ReactNode | ReactNode[];
};
export const ContextSection = ({ title, children }: ContextSectionProps) => {
  return (
    <Stack>
      <Text
        styles={{
          root: { fontWeight: "bold", marginBottom: DefaultSpacing.s1 },
        }}
        variant="medium"
      >
        {title}
      </Text>
      {Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child);
        }
      })}
    </Stack>
  );
};
