import { DevTools } from "@d4b/dev-tools/utils";
import { useFoundMetaData } from "@d4b/dev-tools/utils/useFoundMetaData";
import {
  ErrorPage,
  // PageHeader,
  useLocalStorage,
  UseUrlParams
} from "@d4b/fluent-ui";
import { DefaultSpacing, IStyle, Spinner, Stack, Text } from "@fluentui/react";
import { MenuPrincipale, MenuPrincipaleProps } from "components";
import { PageMetaData, useAppContext } from "lib";
import { useAuth } from "lib/utils/auth";
import React, {
  Children,
  createContext,
  ReactNode,
  useContext, 
  useState
} from "react";
import { SecondaryCommandeBarProps } from "../components";

type ILayoutStyles = {
  root?: IStyle;
  childrenContainer?: IStyle;
};

type ListWithDetailsLayoutProps = {
  children?: ReactNode;
  metadata?: LayoutMetadata;
  extraButton?: ReactNode;
  filterParams?: UseUrlParams;
  error?: boolean;
  styles?: ILayoutStyles;
  pageId?: string;
  menuPrincipaleProps?: MenuPrincipaleProps;
};

type LayoutMetadata = {
  pageMetaData: PageMetaData;
};

const Context = createContext<any>({});

type LayoutState = {
  hidden?: boolean;
  setHidden: React.Dispatch<React.SetStateAction<false | undefined>>;
  modeView?: "grid" | "list";
  setModeView: (value: "grid" | "list") => void;
};

export const useLayout = (): LayoutState => useContext(Context);

const ListWithDetailsLayout = ({
  children,
  metadata,
  extraButton,
  filterParams,
  error = false,
  styles,
  pageId,
  menuPrincipaleProps,
}: ListWithDetailsLayoutProps) => {
  const { ws } = useAppContext();

  // TODO : delete in prod maybe
  // const navigate = useNavigate();
  // const [appStatut, setAppStatut] = useLocalStorage("appStatut", "NONE");
  // useEffect(() => {
  //   if(appStatut === "NONE")
  //     setAppStatut("Y");
  //   if (!appStatut || appStatut !== "Y") return;
  //   navigate(InterfaceGlobaleRoutes.toMaintenancePage());
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [appStatut]);

  // TODO: Just an example .. maybe page data etc..
  const [hidden, setHidden] = useState<false>();
  const [modeView, setModeView] = useLocalStorage<"grid" | "list">(
    `Page:ViewMode:${metadata?.pageMetaData?.pageUid}`,
    "grid"
  );
  const auth = useAuth();
  const { foundMetaData } = useFoundMetaData(pageId);
  const { isDevelopmentEnvironment } = useAppContext();

  const checkAccess = () => {
    if (foundMetaData?.controleAccess === "Public") return true;
    let haveAccess = false;
    if (
      auth.isAuthenticated &&
      foundMetaData?.roles &&
      foundMetaData?.roles.length !== 0
    ) {
      if (foundMetaData?.roles[0] === "*") {
        return true;
      }
      foundMetaData?.roles?.forEach((role: any) => {
        if (auth.hasProfil(role)) {
          haveAccess = true;
        }
      });

      return haveAccess;
    }
    // if (!haveAccess)
    //   navigate(InterfaceGlobaleRoutes.toDroitInsuffisantPage());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  if (error)
    return (
      <ErrorPage
        title="Oups.."
        message="The resource you have requested is not found. If the problem doesn't go away, please contact support."
      />
    );

  const value: LayoutState = { hidden, setHidden, modeView, setModeView };
  const secondaryCommandeBarProps: SecondaryCommandeBarProps = {};
  if (metadata?.pageMetaData.hasModeView)
    secondaryCommandeBarProps.viewModeMenuProps = {
      defaultView: "grid",
      modeView: modeView,
      changeView: setModeView,
    };
  if (filterParams)
    secondaryCommandeBarProps.searcherProps = {
      filterParams: filterParams,
      options: filterParams.filterOptions,
      searcherOptions: [],
    };

  return (
    <Context.Provider value={value}>
      <Stack styles={{ root: styles?.root }}>
        {menuPrincipaleProps && <MenuPrincipale {...menuPrincipaleProps} />}
        {(isDevelopmentEnvironment || ws?.MODE_DEBUG) && (
          <DevTools pageId={pageId} />
        )}
        <div
          style={{
            ...(styles?.childrenContainer as any),
            padding: DefaultSpacing.l1,
          }}
        >
          {auth.isLoading ? (
            <Spinner />
          ) : checkAccess() ? (
            children
          ) : (
            <DroitInsuffisant />
          )}
        </div>
      </Stack>
    </Context.Provider>
  );
};

const DroitInsuffisant = () => (
  <Stack style={{ paddingTop: DefaultSpacing.l1 }}>
    <Text>
      Vous ne possédez pas les droits suffisants pour atteindre la section du
      site à laquelle vous tentez d'accéder.
    </Text>
    {/* <Text>
      Vous pouvez retourner à votre page d'accueil en cliquant sur le logo dans
      le bandeau en haut de page.
    </Text> */}
  </Stack>
);

type LayoutContentProps = {
  children?: ReactNode | ReactNode[];
};

export const LayoutContent = ({ children }: LayoutContentProps) => {
  const layout = useLayout();

  return (
    <>
      {!layout.hidden &&
        Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement<any>(child, { layout });
          }
        })}
    </>
  );
};

export const LayoutGridView = ({ children }: LayoutContentProps) => {
  const layout = useLayout();

  return (
    <>
      {layout.modeView === "grid" &&
        Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement<any>(child, { layout });
          }
        })}
    </>
  );
};

export const LayoutListView = ({ children }: LayoutContentProps) => {
  const layout = useLayout();

  return (
    <>
      {layout.modeView === "list" &&
        Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement<any>(child, { layout });
          }
        })}
    </>
  );
};

ListWithDetailsLayout.Content = LayoutContent;
ListWithDetailsLayout.Grid = LayoutGridView;
ListWithDetailsLayout.List = LayoutListView;
export { ListWithDetailsLayout };

export default ListWithDetailsLayout;
