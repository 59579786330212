import { DefaultPalette, DefaultSpacing, Stack } from "@fluentui/react";

import { ReactNode } from "react";

type InforCardProps = {
  children?: ReactNode | ReactNode[];
  relative?: boolean;
  className?: string;
};
/**
 * This component is used for displaying ui cards (light theme bachground)
 *
 * @version 1.0.0
 * @param {InforCardProps} props
 * @returns
 */
export const InfoCard = ({ children, className }: InforCardProps) => {
  return (
    <Stack
      style={{ position: "relative" }}
      styles={cardStyles}
      tokens={{ childrenGap: DefaultSpacing.s1 }}
      className={className}
    >
      {children}
    </Stack>
  );
};

const cardStyles = {
  root: {
    backgroundColor: DefaultPalette.themeLight,
    padding: DefaultSpacing.l1,
    paddingRight: DefaultSpacing.l2,
    borderLeft: "4px dotted",
    borderLeftColor: DefaultPalette.white,
    marginBottom: DefaultSpacing.l1,
    marginRight: DefaultSpacing.l1,
    maxWidth: "500px",
    selectors: {
      "@media(max-width: 480px)": {},
      "@media(max-width: 768px)": {},
      "@media(max-width: 1024px)": {},
    },
  },
};
