import React from "react";

        import { AdminTraitementsRoutes } from "./AdminTraitementsRoutes";
        import { RequireAuth } from "@d4b/fluent-ui";
        import { Routes, Route } from "react-router-dom";
        

const FeatureAdminTraitements = {
    
AdminTraitementsPage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminTraitements/views/AdminTraitementsPage")),


AdminGestionBaseSIRETPage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminTraitements/views/AdminGestionBaseSIRETPage")),


AdminGestionIntegrationDGEFPPage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminTraitements/views/AdminGestionIntegrationDGEFPPage")),


AdminGestionIntegrationOrganismesFinancesPage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminTraitements/views/AdminGestionIntegrationOrganismesFinancesPage")),


AdminGestionNombreMaxResultatExportPage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminTraitements/views/AdminGestionNombreMaxResultatExportPage")),


AdminGestionOFStagnantPage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminTraitements/views/AdminGestionOFStagnantPage")),


AdminTraitementDateReferencabilitePage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminTraitements/views/AdminTraitementDateReferencabilitePage")),


AdminSuppressionUtilisateurExpireePage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminTraitements/views/AdminSuppressionUtilisateurExpireePage")),


AdminExportOfsPage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminTraitements/views/AdminExportOfsPage")),


AdminExportReferencementsPage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminTraitements/views/AdminExportReferencementsPage")),


AdminExportSiretsPage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminTraitements/views/AdminExportSiretsPage")),


AdminCertificationsExpirationsPage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminTraitements/views/AdminCertificationsExpirationsPage")),


AdminExpirationsMotsDePassePage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminTraitements/views/AdminExpirationsMotsDePassePage")),


AdminGestionCompteFinanceurActifPage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminTraitements/views/AdminGestionCompteFinanceurActifPage")),


AdminTraitementsEmailsPage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminTraitements/views/AdminTraitementsEmailsPage")),


AdminTraitementsAutoCreationOFsPage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminTraitements/views/AdminTraitementsAutoCreationOFsPage")),


AdminTraitementsSuppressionAutoInertiePage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminTraitements/views/AdminTraitementsSuppressionAutoInertiePage")),


AdminTraitementsSuppressionStatutSuspenduPage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminTraitements/views/AdminTraitementsSuppressionStatutSuspenduPage")),


};

export const AdminTraitementsAppRoutes = () => (
    <Routes>
        
                        <Route key={"routeAdminTraitementsPage"} element={<RequireAuth />}>
                          <Route path={AdminTraitementsRoutes.toAdminTraitementsPage()} element={<FeatureAdminTraitements.AdminTraitementsPage />} />
                        </Route>
                      
                        <Route key={"routeAdminGestionBaseSIRETPage"} element={<RequireAuth />}>
                          <Route path={AdminTraitementsRoutes.toAdminGestionBaseSIRETPage()} element={<FeatureAdminTraitements.AdminGestionBaseSIRETPage />} />
                        </Route>
                      
                        <Route key={"routeAdminGestionIntegrationDGEFPPage"} element={<RequireAuth />}>
                          <Route path={AdminTraitementsRoutes.toAdminGestionIntegrationDGEFPPage()} element={<FeatureAdminTraitements.AdminGestionIntegrationDGEFPPage />} />
                        </Route>
                      
                        <Route key={"routeAdminGestionIntegrationOrganismesFinancesPage"} element={<RequireAuth />}>
                          <Route path={AdminTraitementsRoutes.toAdminGestionIntegrationOrganismesFinancesPage()} element={<FeatureAdminTraitements.AdminGestionIntegrationOrganismesFinancesPage />} />
                        </Route>
                      
                        <Route key={"routeAdminGestionNombreMaxResultatExportPage"} element={<RequireAuth />}>
                          <Route path={AdminTraitementsRoutes.toAdminGestionNombreMaxResultatExportPage()} element={<FeatureAdminTraitements.AdminGestionNombreMaxResultatExportPage />} />
                        </Route>
                      
                        <Route key={"routeAdminGestionOFStagnantPage"} element={<RequireAuth />}>
                          <Route path={AdminTraitementsRoutes.toAdminGestionOFStagnantPage()} element={<FeatureAdminTraitements.AdminGestionOFStagnantPage />} />
                        </Route>
                      
                        <Route key={"routeAdminTraitementDateReferencabilitePage"} element={<RequireAuth />}>
                          <Route path={AdminTraitementsRoutes.toAdminTraitementDateReferencabilitePage()} element={<FeatureAdminTraitements.AdminTraitementDateReferencabilitePage />} />
                        </Route>
                      
                        <Route key={"routeAdminSuppressionUtilisateurExpireePage"} element={<RequireAuth />}>
                          <Route path={AdminTraitementsRoutes.toAdminSuppressionUtilisateurExpireePage()} element={<FeatureAdminTraitements.AdminSuppressionUtilisateurExpireePage />} />
                        </Route>
                      
                        <Route key={"routeAdminExportOfsPage"} element={<RequireAuth />}>
                          <Route path={AdminTraitementsRoutes.toAdminExportOfsPage()} element={<FeatureAdminTraitements.AdminExportOfsPage />} />
                        </Route>
                      
                        <Route key={"routeAdminExportReferencementsPage"} element={<RequireAuth />}>
                          <Route path={AdminTraitementsRoutes.toAdminExportReferencementsPage()} element={<FeatureAdminTraitements.AdminExportReferencementsPage />} />
                        </Route>
                      
                        <Route key={"routeAdminExportSiretsPage"} element={<RequireAuth />}>
                          <Route path={AdminTraitementsRoutes.toAdminExportSiretsPage()} element={<FeatureAdminTraitements.AdminExportSiretsPage />} />
                        </Route>
                      
                        <Route key={"routeAdminCertificationsExpirationsPage"} element={<RequireAuth />}>
                          <Route path={AdminTraitementsRoutes.toAdminCertificationsExpirationsPage()} element={<FeatureAdminTraitements.AdminCertificationsExpirationsPage />} />
                        </Route>
                      
                        <Route key={"routeAdminExpirationsMotsDePassePage"} element={<RequireAuth />}>
                          <Route path={AdminTraitementsRoutes.toAdminExpirationsMotsDePassePage()} element={<FeatureAdminTraitements.AdminExpirationsMotsDePassePage />} />
                        </Route>
                      
                        <Route key={"routeAdminGestionCompteFinanceurActifPage"} element={<RequireAuth />}>
                          <Route path={AdminTraitementsRoutes.toAdminGestionCompteFinanceurActifPage()} element={<FeatureAdminTraitements.AdminGestionCompteFinanceurActifPage />} />
                        </Route>
                      
                        <Route key={"routeAdminTraitementsEmailsPage"} element={<RequireAuth />}>
                          <Route path={AdminTraitementsRoutes.toAdminTraitementsEmailsPage()} element={<FeatureAdminTraitements.AdminTraitementsEmailsPage />} />
                        </Route>
                      
                        <Route key={"routeAdminTraitementsAutoCreationOFsPage"} element={<RequireAuth />}>
                          <Route path={AdminTraitementsRoutes.toAdminTraitementsAutoCreationOFsPage()} element={<FeatureAdminTraitements.AdminTraitementsAutoCreationOFsPage />} />
                        </Route>
                      
                        <Route key={"routeAdminTraitementsSuppressionAutoInertiePage"} element={<RequireAuth />}>
                          <Route path={AdminTraitementsRoutes.toAdminTraitementsSuppressionAutoInertiePage()} element={<FeatureAdminTraitements.AdminTraitementsSuppressionAutoInertiePage />} />
                        </Route>
                      
                        <Route key={"routeAdminTraitementsSuppressionStatutSuspenduPage"} element={<RequireAuth />}>
                          <Route path={AdminTraitementsRoutes.toAdminTraitementsSuppressionStatutSuspenduPage()} element={<FeatureAdminTraitements.AdminTraitementsSuppressionStatutSuspenduPage />} />
                        </Route>
                      
    </Routes>
);