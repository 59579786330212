/**
* VotreDeclaration Routes
*/

export const VotreDeclarationRoutes = {
	

toVotreDeclarationPage: ()  : string =>  '/interface/votre-declaration',

toVisualisationDesCertificationsPage: (type_declaration?: string)  : string => type_declaration ? `/interface/votre-declaration/${type_declaration}/certifications` :  '/interface/votre-declaration/:type_declaration/certifications',

toValiderDeclarationPage: (type_declaration?: string)  : string => type_declaration ? `/interface/votre-declaration/${type_declaration}/finalisation` :  '/interface/votre-declaration/:type_declaration/finalisation',

};