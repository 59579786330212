import { DefaultPalette, DefaultSpacing, Text } from "@fluentui/react";

type PrimaryTitleProps = {
  text: string;
};
/**
 * This component is used for displaying primary titles
 *
 * @version 1.0.0
 * @param {PrimaryTitleProps} props
 * @returns
 */
export const PrimaryTitle = ({ text }: PrimaryTitleProps) => {
  return (
    <Text variant="xLargePlus" style={styles.title}>
      {text}
    </Text>
  );
};

const styles = {
  title: {
    fontWeight: "bold",
    color: DefaultPalette.themePrimary,
    marginBottom: DefaultSpacing.l1
  },
};
