/**
* AdminReferentiels Routes
*/

export const AdminReferentielsRoutes = {
	

toAdminBaseSIRETPage: ()  : string =>  '/interface/referentiels/base-siret',

toAdminApeListPage: ()  : string =>  '/interface/referentiels/ape',

toAdminBasePactolePage: ()  : string =>  '/interface/referentiels/base-pactole',

toAdminCriteresEtIndicateursPage: ()  : string =>  '/interface/referentiels/criteres',

toAdminGestionDelaiStagnationOfPage: ()  : string =>  '/interface/referentiels/delai-of-stagnant-visualisation',

toAdminCertificationsPage: ()  : string =>  '/interface/referentiels/certifications',

toAdminDetailCertificationPage: (id_certification?: string)  : string => id_certification ? `/interface/referentiels/certifications/${id_certification}` :  '/interface/referentiels/certifications/:id_certification',

toAdminModifierCertificationsPage: (id_certification?: string)  : string => id_certification ? `/interface/referentiels/certifications/${id_certification}/modifier` :  '/interface/referentiels/certifications/:id_certification/modifier',

toAdminCreationCertificationPage: (id_certification?: string)  : string => id_certification ? `/interface/referentiels/certifications/${id_certification}/creation` :  '/interface/referentiels/certifications/:id_certification/creation',

toAdminGestionMotifPage: ()  : string =>  '/interface/referentiels/motifs-commentaire',

toAdminVisualisationMotifPage: (id_motif_commentaire?: string)  : string => id_motif_commentaire ? `/interface/referentiels/motifs-commentaire/${id_motif_commentaire}/visualisation` :  '/interface/referentiels/motifs-commentaire/:id_motif_commentaire/visualisation',

toAdminModifierMotifPage: (id_motif_commentaire?: string)  : string => id_motif_commentaire ? `/interface/referentiels/motifs-commentaire/${id_motif_commentaire}/modifier` :  '/interface/referentiels/motifs-commentaire/:id_motif_commentaire/modifier',

toAdminAjoutMotifPage: ()  : string =>  '/interface/referentiels/motifs-commentaire/ajouter',

toAdminRecapCriteresPage: ()  : string =>  '/interface/referentiels/criteres/recap',

toAdminVisualisationCriterePage: (id_critere?: string)  : string => id_critere ? `/interface/referentiels/criteres/criteres/${id_critere}/visualisation` :  '/interface/referentiels/criteres/criteres/:id_critere/visualisation',

toAdminAjouterCriterePage: ()  : string =>  '/interface/referentiels/criteres/criteres/ajouter',

toAdminVisualisationIndicateurPage: (id_indicateur?: string)  : string => id_indicateur ? `/interface/referentiels/criteres/indicateurs/${id_indicateur}/visualisation` :  '/interface/referentiels/criteres/indicateurs/:id_indicateur/visualisation',

toAdminAjouterIndicateurPage: ()  : string =>  '/interface/referentiels/criteres/indicateurs/ajouter',

toAdminCertificateursPage: ()  : string =>  '/interface/referentiels/certificateurs',

};