import ReactDOM from "react-dom";

import { mergeStyles, initializeIcons } from "@fluentui/react";
import { App } from "App";
import { QueryClient, QueryClientProvider } from "react-query";

initializeIcons();
window.process = {} as any;

// Inject some global styles
mergeStyles({
  ":global(body,html,#root)": {
    margin: 0,
    padding: 0,
    height: "100vh",
  },
});

const appQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <QueryClientProvider client={appQueryClient}>
    <App />
  </QueryClientProvider>,

  document.getElementById("root")
);
