import {
  DetailsHeader,
  DetailsListLayoutMode,
  DetailsRow,
  IColumn,
  IColumnReorderOptions,
  IDetailsGroupRenderProps,
  IDetailsHeaderProps,
  IDetailsHeaderStyles,
  IDetailsListProps,
  IDetailsListStyleProps,
  IDetailsListStyles,
  IDetailsRowProps,
  IDetailsRowStyles,
  IGroup,
  IListProps,
  IObjectWithKey,
  IRenderFunction,
  ISelection,
  IStyleFunctionOrObject,
  NeutralColors,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
} from "@fluentui/react";

import { useRef } from "react";

export const shimmeredDetailsListProps: IListProps = {
  renderedWindowsAhead: 0,
  renderedWindowsBehind: 0,
};

type Props = {
  items: any[];
  columns?: IColumn[];
  groups?: IGroup[];
  setKey?: string;
  layoutMode?: DetailsListLayoutMode;
  selectionMode?: SelectionMode;
  selection?: ISelection<IObjectWithKey>;
  ariaLabelForShimmer?: string;
  ariaLabelForGrid?: string;
  enableShimmer?: boolean;
  onActiveItemChanged?: any;
  groupProps?: IDetailsGroupRenderProps;
  onRenderRow?: IRenderFunction<IDetailsRowProps>;
  onRendersHeader?: IRenderFunction<IDetailsHeaderProps>;
  columnReorderOptions?: IColumnReorderOptions;
  detailsListStyles?: IStyleFunctionOrObject<
    IDetailsListStyleProps,
    IDetailsListStyles
  >;
};

export const ResponsiveDetailsList = ({
  items = [],
  columns,
  groups,
  setKey = "set",
  layoutMode,
  selectionMode,
  selection,
  ariaLabelForShimmer,
  ariaLabelForGrid,
  enableShimmer = false,
  onActiveItemChanged,
  groupProps,
  onRenderRow,
  onRendersHeader,
  columnReorderOptions,
  detailsListStyles,
}: Props) => {
  const ref = useRef<any>();
  const renderDetailsHeader: IDetailsListProps["onRenderDetailsHeader"] = (
    props
  ) => {
    const headerStyles: Partial<IDetailsHeaderStyles> = {
      root: {
        backgroundColor: "#e9ecef",
        height: 40,
        marginTop: -16,
        color: "white",
        selectors: {
          ":hover": {
            color: "white",
          },
        },
      },
    };
    if (!props) return null;
    return <DetailsHeader {...props} styles={headerStyles} />;
  };
  const renderRow: IDetailsListProps["onRenderRow"] = (props) => {
    const rowStyles: Partial<IDetailsRowStyles> = {
      root: {
        borderBottom: "1px solid",
        fontSize: 13,
        borderBottomColor: NeutralColors.gray40,
        color: NeutralColors.gray130,
      },
    };
    if (!props) return null;
    return <DetailsRow {...props} styles={rowStyles} />;
  };
  return (
    <Stack
      styles={{
        root: {
          selectors: {
            ".ms-Viewport": {
              margin: 0,
            },
          },
        },
      }}
    >
      <ShimmeredDetailsList
        componentRef={ref}
        items={items}
        columns={columns}
        groups={groups}
        setKey={setKey}
        layoutMode={layoutMode || DetailsListLayoutMode.justified}
        selectionMode={selectionMode || SelectionMode.none}
        selection={selection}
        detailsListStyles={
          detailsListStyles || {
            root: {
              height: `calc((var(--vh, 1vh) * 92) - ${ref.current?._root?.current?.offsetTop}px)`,
              width: `calc((var(--vw, 1vw) * 98) - ${ref.current?._root?.current?.offsetLeft}px)`,
            },
          }
        }
        ariaLabelForShimmer={ariaLabelForShimmer}
        ariaLabelForGrid={ariaLabelForGrid}
        onActiveItemChanged={onActiveItemChanged}
        onRenderRow={onRenderRow || renderRow}
        groupProps={groupProps}
        columnReorderOptions={columnReorderOptions}
        enableShimmer={enableShimmer}
        listProps={shimmeredDetailsListProps}
        onRenderDetailsHeader={onRendersHeader || renderDetailsHeader}
      />
    </Stack>
  );
};
