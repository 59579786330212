import React from "react";

        import { AdministrationRoutes } from "./AdministrationRoutes";
        import { RequireAuth } from "@d4b/fluent-ui";
        import { Routes, Route } from "react-router-dom";
        

const FeatureAdministration = {
    
AdministrationDesComptesPage: React.lazy(() => import("@DataDock/OrganismeDeFormation/Administration/views/AdministrationDesComptesPage")),


AdministrationCreationComptePage: React.lazy(() => import("@DataDock/OrganismeDeFormation/Administration/views/AdministrationCreationComptePage")),


AdministrationModifierComptePage: React.lazy(() => import("@DataDock/OrganismeDeFormation/Administration/views/AdministrationModifierComptePage")),


AdministrationSupprimerOFPage: React.lazy(() => import("@DataDock/OrganismeDeFormation/Administration/views/AdministrationSupprimerOFPage")),


};

export const AdministrationAppRoutes = () => (
    <Routes>
        
                        <Route key={"routeAdministrationDesComptesPage"} element={<RequireAuth />}>
                          <Route path={AdministrationRoutes.toAdministrationDesComptesPage()} element={<FeatureAdministration.AdministrationDesComptesPage />} />
                        </Route>
                      
                        <Route key={"routeAdministrationCreationComptePage"} element={<RequireAuth />}>
                          <Route path={AdministrationRoutes.toAdministrationCreationComptePage()} element={<FeatureAdministration.AdministrationCreationComptePage />} />
                        </Route>
                      
                        <Route key={"routeAdministrationModifierComptePage"} element={<RequireAuth />}>
                          <Route path={AdministrationRoutes.toAdministrationModifierComptePage()} element={<FeatureAdministration.AdministrationModifierComptePage />} />
                        </Route>
                      
                        <Route key={"routeAdministrationSupprimerOFPage"} element={<RequireAuth />}>
                          <Route path={AdministrationRoutes.toAdministrationSupprimerOFPage()} element={<FeatureAdministration.AdministrationSupprimerOFPage />} />
                        </Route>
                      
    </Routes>
);