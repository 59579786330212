import { formatJsonDateString } from "@d4b/fluent-ui";

type DateDisplayProps = {
  dateStr: string;
  showSeconds?: boolean;
};

export const DateDisplay = ({
  dateStr,
  showSeconds = false,
}: DateDisplayProps) => {
  if (dateStr === "") return null;
  return <span>{formatJsonDateString(dateStr, showSeconds)}</span>;
};
