import {
  Callout,
  CommandButton,
  DefaultButton,
  FontWeights,
  IColumn,
  mergeStyleSets,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  Text,
} from "@fluentui/react";
import {
  FormTextField,
  Selector,
  useForm,
  useLocalStorage,
  UseUrlParams,
} from "@d4b/fluent-ui";
import { useBoolean, useId } from "@fluentui/react-hooks";
import { useState } from "react";

type FilterContextSpec = {
  page: string;
  dataListName: string;
  type: "filters" | "filterLogic";
};
const getFilterContextFromLocalStorage = (ctx: FilterContextSpec): string =>
  `${ctx.page}:${ctx.type}:${ctx.dataListName}`;
type FilterEntry = {
  Field: string;
  Operator: FilterOperator;
  Value: string;
};
type FilterOperator = {
  OperatorName: string;
  Symbol: string;
};
type CustomFilterLogicProps = {
  columns: IColumn[];
  filterParams: UseUrlParams;
};
type FilterCmd = {
  Field: string;
  Operator: string;
  Value: string;
  index?: number;
};
function getFiltersFromLogic(xxx: string, f: FilterEntry[]): any {
  let where = "";
  for (let i = 0; i < xxx.length; i++) {
    let x = xxx[i];
    if (xxx[i] !== " " && !isNaN(Number(xxx[i]))) {
      let filter = f[Number(xxx[i]) - 1];
      if (filter) {
        let sym = filter.Operator.Symbol;
        let val = filter.Value;
        if (sym === "like") val = `%${val}%`;
        x = `${filter.Field} ${sym} '${val}'`;
      }
    }
    where += x;
  }
  return where;
}
export const AdvancedFilterPanel = ({
  columns,
  filterParams,
}: CustomFilterLogicProps) => {
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] =
    useBoolean(false);
  const [filters, setFilters] = useLocalStorage<FilterEntry[]>(
    getFilterContextFromLocalStorage({
      page: filterParams.pageName,
      dataListName: filterParams.dataListName,
      type: "filters",
    }),
    []
  );
  const [filterLogic, setFilterLogic] = useLocalStorage<string>(
    getFilterContextFromLocalStorage({
      page: filterParams.pageName,
      dataListName: filterParams.dataListName,
      type: "filterLogic",
    }),
    ""
  );
  const [
    displayFilterLogic,
    { setTrue: displayFIlterLogicInput, setFalse: dismissFIlterLogicInput },
  ] = useBoolean(filterLogic !== "");

  const [buttonId, setButtonId] = useState(useId("callout-button"));
  const labelId = useId("callout-label");
  const descriptionId = useId("callout-description");
  const initFromState = () => ({} as any);
  const formState = useForm<FilterCmd>(initFromState());
  const { values, setValues, setValue, checkRequired, setErrors } = formState;
  const onCollectErrors = (errors: any) => {
    checkRequired("Field", errors, "Please provide the field");
    checkRequired("Operator", errors, "Please provide the operator");
    checkRequired("Value", errors, "Please provide the value");
  };

  const operators: FilterOperator[] = [
    { OperatorName: "isLessThan", Symbol: "<" },
    { OperatorName: "isGreaterThan", Symbol: ">" },
    { OperatorName: "isLessThanOrEqual", Symbol: "<=" },
    { OperatorName: "isGreaterThanOrEqual", Symbol: ">=" },
    { OperatorName: "isLike", Symbol: "like" },
    { OperatorName: "isEqual", Symbol: "=" },
    { OperatorName: "isNotEqual", Symbol: "<>" },
  ];

  const onSubmitForm = (): void => {
    let errors: any = {};
    onCollectErrors && onCollectErrors(errors);
    setErrors && setErrors(errors);
    if (JSON.stringify(errors) !== "{}") return;
    toggleIsCalloutVisible();
    const findFilter = operators.find(
      (o) => o.OperatorName === values.Operator
    );

    let filter: FilterEntry;
    if (findFilter) {
      filter = {
        Field: values.Field,
        Operator: findFilter,
        Value: values.Value,
      };
      if (values.index) {
        let newFilters = filters.map((f, i) =>
          i === values.index ? (f = filter) : f
        );
        setFilters(newFilters);
      } else setFilters([...filters, filter]);
    }
    setValue("index", undefined);
  };

  const styles = mergeStyleSets({
    button: {
      width: 130,
    },
    callout: {
      width: 320,
      maxWidth: "90%",
      padding: "20px 24px",
    },
    title: {
      marginBottom: 12,
      fontWeight: FontWeights.semilight,
    },
    link: {
      display: "block",
      marginTop: 20,
    },
  });

  return (
    <>
      <CommandButton
        text="Advanced"
        onClick={() => {
          openPanel();
        }}
        iconProps={{ iconName: "FilterSettings" }}
      />
      <Panel
        headerText="Filters"
        isOpen={isOpen}
        type={PanelType.custom}
        customWidth="560px"
        onDismiss={dismissPanel}
        closeButtonAriaLabel="Close"
        isBlocking={false}
        style={{ marginTop: 48 }}
        styles={{
          commands: { zIndex: 1 },
          content: { paddingTop: 20 },
        }}
      >
        {filters.map((f, i) => (
          <Stack
            key={i}
            styles={{
              root: {
                border: "1px solid #DDD",
                marginBottom: 10,
                alignItems: "center",
              },
            }}
            horizontal
          >
            <Stack.Item>
              <Text style={{ marginRight: 10, marginLeft: 10 }}>{i + 1}.</Text>
            </Stack.Item>
            <Stack.Item grow={4}>
              <Text key="filter">{f.Field}</Text>
              <Text
                key="operator"
                style={{ fontWeight: 600, marginRight: 10, marginLeft: 10 }}
              >
                {f.Operator.OperatorName}
              </Text>
              <Text key="value">{f.Value}</Text>
            </Stack.Item>
            <Stack.Item>
              <CommandButton
                onClick={() => {
                  var filter = filters[i];
                  setValues({
                    Field: filter.Field,
                    Operator: filter.Operator.OperatorName,
                    Value: filter.Value,
                    index: i,
                  });
                  toggleIsCalloutVisible();
                  setButtonId(`btn${i}`);
                }}
                id={`btn${i}`}
                iconProps={{ iconName: "Edit" }}
              />
              <CommandButton
                onClick={() => {
                  var filter = filters.filter((item, ind) => ind !== i);
                  setFilters(filter);
                }}
                iconProps={{ iconName: "Cancel" }}
              />
            </Stack.Item>
          </Stack>
        ))}
        <Stack horizontal>
          <CommandButton
            text="Add filter"
            id={buttonId}
            onClick={toggleIsCalloutVisible}
            iconProps={{ iconName: "Add" }}
          />
          <CommandButton
            text="Clear"
            onClick={() => setFilters([])}
            iconProps={{ iconName: "Trash" }}
          />
        </Stack>

        <Stack
          styles={{ root: { marginTop: 25, borderTop: "1px solid #DDD" } }}
        >
          {displayFilterLogic ? (
            <CommandButton
              text="Clear"
              onClick={() => {
                setFilterLogic("");
                dismissFIlterLogicInput();
              }}
              iconProps={{ iconName: "Trash" }}
            />
          ) : (
            <CommandButton
              text="Add a logic filter"
              onClick={displayFIlterLogicInput}
              iconProps={{ iconName: "Add" }}
            />
          )}
        </Stack>

        <FormTextField
          label="Filter logic"
          name="Value"
          value={filterLogic}
          multiline
          styles={{
            root: { marginTop: 10 },
            fieldGroup: { border: "1px solid #DDD" },
          }}
          visible={displayFilterLogic}
          onChange={(
            event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string
          ) => setFilterLogic(newValue || "")}
        />
        {isCalloutVisible && (
          <Callout
            className={styles.callout}
            ariaLabelledBy={labelId}
            ariaDescribedBy={descriptionId}
            gapSpace={0}
            target={`#${buttonId}`}
            onDismiss={toggleIsCalloutVisible}
            setInitialFocus
          >
            <Text block variant="xLarge" className={styles.title} id={labelId}>
              Add a filter
            </Text>
            <Text block variant="small" id={descriptionId}>
              To create a filter choose the column then the operator and the
              value.
            </Text>
            <form style={{ width: "100%" }} onSubmit={onSubmitForm}>
              <Selector
                name="Field"
                formState={formState}
                choices={columns}
                value=""
                label="Select a field"
                choiceKey="key"
                choiceText="name"
              />
              <Selector
                name="Operator"
                formState={formState}
                choices={operators}
                value=""
                label="Select an operator"
                choiceKey="OperatorName"
                choiceText="OperatorName"
              />
              <FormTextField
                label="Enter a value"
                name="Value"
                formState={formState}
              />
              <PrimaryButton
                style={{ marginRight: 5, marginTop: 10 }}
                text="Save"
                onClick={onSubmitForm}
              />

              <DefaultButton
                style={{ marginRight: 5, marginTop: 10 }}
                text="Close"
                onClick={toggleIsCalloutVisible}
              />
            </form>
          </Callout>
        )}

        <Stack style={{ marginTop: 20 }} horizontal>
          <PrimaryButton
            style={{ marginRight: 5 }}
            text="Search"
            onClick={() => {
              filterParams.setExtraParams({
                ...filterParams.extraParams,
                where: getFiltersFromLogic(filterLogic, filters),
              });
              dismissPanel();
            }}
          />
          <DefaultButton
            text="Cancel"
            onClick={() => {
              dismissPanel();
            }}
          />
        </Stack>
      </Panel>
    </>
  );
};
