/**
* CreerUnCompte Routes
*/

export const CreerUnCompteRoutes = {
	

toCreationComptePage: ()  : string =>  '/interface/of-compte-creation',

toRecapitulatifCreationComptePage: ()  : string =>  '/interface/confirmation-compte-creation',

toFinaliserCreationComptePage: (token?: string)  : string => token ? `/interface/finalisation-compte/${token}` :  '/interface/finalisation-compte/:token',

toFinaliserCreationCompteDeleguePage: (token?: string)  : string => token ? `/interface/finalisation-compte-delegue/${token}` :  '/interface/finalisation-compte-delegue/:token',

};