/**
* VotreEntreprise Routes
*/

export const VotreEntrepriseRoutes = {
	

toVisualisationInformationsGeneralesPage: ()  : string =>  '/interface/votre-entreprise/informations-generales',

toModificationInformationsGeneralesPage: ()  : string =>  '/interface/votre-entreprise/informations-generales/modification',

toInformationEtablissementPrincipalPage: ()  : string =>  '/interface/votre-entreprise/etablissement-principal',

toListeDesEtablissementsPage: ()  : string =>  '/interface/votre-entreprise/liste-etablissements',

toModificationEtablissementPrincipalPage: ()  : string =>  '/interface/votre-entreprise/etablissement-principal/modifier',

toAutresEntreprisesAjoutPage: ()  : string =>  '/interface/votre-entreprise/autres-entreprises/ajout',

toAutresEntreprisesPage: ()  : string =>  '/interface/votre-entreprise/autres-entreprises',

};