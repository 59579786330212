import Axios, { AxiosResponse } from "axios";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { axiosInstance } from ".";
import getLinks from "./getLinks";
import { isError, RSErrorType } from "./RSTypes";
import { LinkEntry } from "../../rs-core";

export const getCSRFCookieRequest = async ({ ws }: any) => {
  await axiosInstance.get(ws?.links["sanctum.csrf-cookie"]);
};

export function useCommand(
  de: any,
  linkName: string,
  onError?: any,
  removeQueries?: any,
  withCredentials?: boolean,
  key?: string,
  value?: string
) {
  const appQueryClient = useQueryClient();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();

  //const axios = useAxios();
  const axios = Axios.create({
    headers: {
      Accept: "application/json",
    },
    withCredentials: withCredentials,
  });

  const mutation = useMutation<any, Error>(async (data) => {
    setIsLoading(true);
    if (!axios) throw Error("No axios");
    if (onError) onError(null);
    const links = getLinks(de);
    const link: LinkEntry = links[linkName];
    if (!link || !link.url) {
      setIsLoading(false);
      const error: RSErrorType = {
        errorMessage: `Link ${linkName} not found on context`,
        errorMessageDetails:
          "Avalable links: " +
          JSON.stringify(
            Object.getOwnPropertyNames(links).map((l) => l + ", ")
          ),
      };
      if (onError) return onError(error);
      throw new Error(JSON.stringify(error));
    }

    // TODO: switch
    let action;

    if (link.method.toLowerCase() === "put")
      action = axios.post(link.url, data);
    else if (link.method.toLowerCase() === "delete") {
      if (key && value) {
        action = axios.delete(link.url + "?" + key + "=" + value);
      } else {
        action = axios.delete(link.url);
      }
    } else action = axios.post(link.url, data);

    action
      .then((response: AxiosResponse) => {
        setIsLoading(false);
        if (response.status === 200) {
          setIsSuccess(true);
          if (isError(response.data)) {
            if (onError) return onError(response.data);
            throw new Error(JSON.stringify(response.data));
          }
          setData(response.data);
          if(removeQueries?.length > 0){
            removeQueries?.map((q: any) => appQueryClient.invalidateQueries([q]));
          }
            
          return response.data;
        }
        return response.data;
      })
      .catch((e: any) => {
        setIsLoading(false);
        if (!e) throw new Error("Empty error received from server");
        if (!e.response && e.message && onError)
          return onError({
            errorMessage: e.message,
            errorDetails: JSON.stringify(e),
          });
        const { data } = e.response;
        if (data && onError) return onError(data);
        throw e;
      });
  });

  return {
    ...mutation,
    onSubmit: (data: any) => mutation.mutate(data),
    isSuccess,
    isLoading,
    data: data,
  };
}

export default useCommand;
