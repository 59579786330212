/**
* AdminFicheOf Routes
*/

export const AdminFicheOfRoutes = {
	

toFicheOfResumePage: (idOf?: string)  : string => idOf ? `/interface/administration/fiche-of/${idOf}/index` :  '/interface/administration/fiche-of/:idOf/index',

toFicheOfInformationGeneralePage: (idOf?: string)  : string => idOf ? `/interface/administration/fiche-of/${idOf}/informations-generales` :  '/interface/administration/fiche-of/:idOf/informations-generales',

toFicheOfMieuxConnaitrePage: (idOf?: string)  : string => idOf ? `/interface/administration/fiche-of/${idOf}/contexte` :  '/interface/administration/fiche-of/:idOf/contexte',

toFicheOfEtablissementPrincipalePage: (idOf?: string)  : string => idOf ? `/interface/administration/fiche-of/${idOf}/etablissement` :  '/interface/administration/fiche-of/:idOf/etablissement',

toFicheOfListeEtablissementPage: (idOf?: string)  : string => idOf ? `/interface/administration/fiche-of/${idOf}/liste-etablissements` :  '/interface/administration/fiche-of/:idOf/liste-etablissements',

toFicheOfHistoriqueQualiopiPage: (idOf?: string)  : string => idOf ? `/interface/administration/fiche-of/${idOf}/historique-qualiopi` :  '/interface/administration/fiche-of/:idOf/historique-qualiopi',

toFicheOfCertificateursPage: (idOf?: string)  : string => idOf ? `/interface/administration/fiche-of/${idOf}/certificateurs` :  '/interface/administration/fiche-of/:idOf/certificateurs',

toFicheOfHistoriquePage: (idOf?: string)  : string => idOf ? `/interface/administration/fiche-of/${idOf}/historique` :  '/interface/administration/fiche-of/:idOf/historique',

toFicheOfCommentairesPage: (idOf?: string)  : string => idOf ? `/interface/administration/fiche-of/${idOf}/commentaires` :  '/interface/administration/fiche-of/:idOf/commentaires',

toFicheOfCommentaireDetailsPage: (idOf?: string, id_commentaire?: string)  : string => idOf ? `/interface/administration/fiche-of/${idOf}/commentaires/${id_commentaire}/detail` :  '/interface/administration/fiche-of/:idOf/commentaires/:id_commentaire/detail',

toCompteDelegePage: (idOf?: string)  : string => idOf ? `/interface/administration/fiche-of/${idOf}/utilisateurs` :  '/interface/administration/fiche-of/:idOf/utilisateurs',

toAjouterCompteDelegePage: (idOf?: string)  : string => idOf ? `/interface/administration/fiche-of/${idOf}/utilisateurs-form` :  '/interface/administration/fiche-of/:idOf/utilisateurs-form',

toFicheOfReferencementPage: (idOf?: string)  : string => idOf ? `/interface/administration/fiche-of/${idOf}/referencement` :  '/interface/administration/fiche-of/:idOf/referencement',

toFicheOfRapportControlePage: (idOf?: string)  : string => idOf ? `/interface/administration/fiche-of/${idOf}/rapport-controle` :  '/interface/administration/fiche-of/:idOf/rapport-controle',

};