import React, { useState, useEffect, createContext, useContext } from "react";

import {
  ScreenWidthMinSmall,
  ScreenWidthMinLarge,
  ScreenWidthMinMedium,
  ScreenWidthMinXLarge,
  ScreenWidthMinXXLarge,
  ScreenWidthMinXXXLarge,
} from "@fluentui/react";

// export type FabricBreakpoint = "sm" | "md" | "lg" | "xl" | "xxl" | "xxxl";

// export function currentFabricBreakpoint(): FabricBreakpoint {
//   const _windowWidth: number = window.innerWidth || ScreenWidthMinXLarge;

//   if (_windowWidth < ScreenWidthMinMedium) {
//     return "sm";
//   } else if (_windowWidth < ScreenWidthMinLarge) {
//     return "md";
//   } else if (_windowWidth < ScreenWidthMinXLarge) {
//     return "lg";
//   } else if (_windowWidth < ScreenWidthMinXXLarge) {
//     return "xl";
//   } else if (_windowWidth < ScreenWidthMinXXXLarge) {
//     return "xxl";
//   } else {
//     return "xxxl";
//   }
// }


const defaultValue: any = {};

const fabricBreakPoints: any = {
  sm: `(min-width: ${ScreenWidthMinSmall}px)`,
  md: `(min-width: ${ScreenWidthMinMedium}px)`,
  lg: `(min-width: ${ScreenWidthMinLarge}px)`,
  xl: `(min-width: ${ScreenWidthMinXLarge}px)`,
  xxl: `(min-width: ${ScreenWidthMinXXLarge}px)`,
  xxxl: `(min-width: ${ScreenWidthMinXXXLarge}px)`,
  or: "(orientation: portrait)", // we can check orientation also
}

const BreakpointContext = createContext(defaultValue);

export type BreakpointProviderProps = {
  children: any;
  queries?: any;
};

const BreakpointProvider = ({ children, queries = fabricBreakPoints }: BreakpointProviderProps) => {
  const [queryMatch, setQueryMatch] = useState({});

  useEffect(() => {
    const mediaQueryLists: any = {};
    const keys = Object.keys(queries);
    let isAttached = false;

    const handleQueryListener = () => {
      const updatedMatches = keys.reduce((acc: any, media: any) => {
        acc[media] = !!(
          mediaQueryLists[media] && mediaQueryLists[media].matches
        );
        return acc;
      }, {});
      setQueryMatch(updatedMatches);
    };

    if (window && window.matchMedia) {
      const matches: any = {};
      keys.forEach((media) => {
        if (typeof queries[media] === "string") {
          mediaQueryLists[media] = window.matchMedia(queries[media]);
          matches[media] = mediaQueryLists[media].matches;
        } else {
          matches[media] = false;
        }
      });
      setQueryMatch(matches);
      isAttached = true;
      keys.forEach((media) => {
        if (typeof queries[media] === "string") {
          mediaQueryLists[media].addListener(handleQueryListener);
        }
      });
    }

    return () => {
      if (isAttached) {
        keys.forEach((media) => {
          if (typeof queries[media] === "string") {
            mediaQueryLists[media].removeListener(handleQueryListener);
          }
        });
      }
    };
  }, [queries]);

  return (
    <BreakpointContext.Provider value={queryMatch}>
      {children}
    </BreakpointContext.Provider>
  );
};

function useBreakpoint() {
  const context = useContext(BreakpointContext);
  // console.log({fabricBreakPoints});
  if (context === defaultValue) {
    throw new Error("useBreakpoint must be used within BreakpointProvider");
  }
  return context;
}
export { useBreakpoint, BreakpointProvider };

