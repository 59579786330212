/**
* VotreCompte Routes
*/

export const VotreCompteRoutes = {
	

toInformationsDeComptePage: ()  : string =>  '/interface/votre-compte',

toChangerEmailPage: (token?: string)  : string => token ? `/interface/activation-email/${token}` :  '/interface/activation-email/:token',

toModifierInformationsComptePage: ()  : string =>  '/interface/votre-compte/informations/modification',

toModifierMotDePassePage: ()  : string =>  '/interface/votre-compte/change-motdepasse',

};