import { DefaultSpacing, Stack } from "@fluentui/react";
import { ContextuelMenu, ContextuelMenuProps, PrimaryTitle } from "components";
import React, { Children, ReactNode } from "react";

type Props = {
  title?: string;
  suffix?: ReactNode | ReactNode[];
  children?: ReactNode | ReactNode[];
  contextuelMenuProps?: ContextuelMenuProps;
};

export const PageWithContextuelMenu = ({
  title,
  suffix,
  children,
  contextuelMenuProps,
}: Props) => {
  return (
    <Stack horizontal>
      {contextuelMenuProps && <ContextuelMenu {...contextuelMenuProps} />}
      <Stack
        styles={{
          root: {
            width: "100%",
            paddingLeft: DefaultSpacing.l1,
            paddingRight: DefaultSpacing.l1,
          },
        }}
      >
        <Stack horizontal wrap verticalAlign="center" tokens={{ childrenGap: DefaultSpacing.s1 }}>
          {title && <PrimaryTitle text={title} />}
          {suffix && <span style={{marginBottom: DefaultSpacing.l1}}>{suffix}</span>}
        </Stack>

        <Stack tokens={{ childrenGap: DefaultSpacing.l1 }}>
          {Children.map(children, (child) => {
            if (React.isValidElement(child)) {
              return React.cloneElement(child);
            }
          })}
        </Stack>
      </Stack>
    </Stack>
  );
};
