import { RSListReply } from "@d4b/api";
import { IUseUrlParamsOptions } from "@d4b/fluent-ui";
import { RSDetailsReply } from "@d4b/rs-core";
import { CertificationEntry } from "@DataDock/OrganismeDeFormation/VotreDeclaration/api";
import { IBreadcrumbItem } from "@fluentui/react";

export * from "./types";

export type IBreadcrumbItemMinimal = {
  id: string;
  text: string;
  url?: string;
};

type IPageMetaDataBase = {
  id: string;
  pageUid: string;
  pageTitle: string;
  instructions?: string;
  componentPath: string;
  componentName: string;
  operationsToInvalidate?: any[];
  hasModeView?: boolean;
  isLoading?: boolean;
  api?: apiLinks[];
  filterParams: IUseUrlParamsOptions;
};
export type apiLinks = {
  visit: string;
  visitName: string;
  de: string;
  selectListItem?: selectListItem;
};
export type selectListItem = {
  field: string;
  localStorageField: string;
  notFound: string;
};

// export type IPageMetaData = IPageMetaDataBase & {
//   breadCrumbs: IBreadcrumbItemMinimal[];
// //  filterParams: IUseUrlParamsOptions;
// };
export type IPageMetaData = IPageMetaDataBase & {
  breadCrumbs?: IBreadcrumbItem[];
  breadCrumbsExpanded?: IBreadcrumbItem[];
  //  filterParams: IUseUrlParamsOptions;
  apiData?: any;
};

export type CompteUtilisateur = RSDetailsReply & {
  utilisateurId: string;
  civilite?: string;
  email: string;
  prenom?: string;
  nomDeFamille?: string;
  utilisateurType?: string;
  emailRecuperation?: string;
  cguAcceptedDate?: Date;
  dateExpirationMdpPrevue?: Date;
  dateExpirationMdpEffective?: Date;
  expirationProcahaineNotifiee?: boolean;
  mdpExpire?: boolean;
  dateCreation?: Date;
  dateModification?: Date;
  statut?: string;
  telephone?: string;
  typeFonction?: string;
};

export type AppartenanceProfilType =
  | "ADMIN_DDOCK"
  | "ADMIN_OF"
  | "DECLARANT_OF"
  | "ADMIN_FINANCEUR"
  | "UTILISATEUR_FINANCEUR"
  | "VISITEUR_FINANCEUR";

export type Appartenance = {
  utilisateurAppartenanceId: string;
  structureId: string;
  structureType: string;
  dateCreation: string;
  profil: AppartenanceProfilType;
  utilisateurId: string;
  raisonSociale: string;
  siren: string;
  nda: string;
  hasDeclarationCourante: boolean;
  desactive?: boolean;
  enVoieDesactivation: boolean;
};

export type AppartenancesListReply = RSListReply & {
  Appartenances: Appartenance[];
};
export type MotifDesactivation = {
  motifDesactivation: string;
  dateDesactivation: Date;
  notificationInitiale: false;
  notificationRappel: false;
};

export type MotifEnVoieDesactivation = {
  motifDesactivation: string;
  dateDesactivation: Date;
  notificationInitiale: false;
  notificationRappel: false;
};

export type OrganismeDeFormation = {
  idOf: string;
  idInterne: string;
  dateCreation: Date;
  dateModification?: Date;
  type?: TypeOrganismeDeFormationsEntry;
  hasDeclarationCourante: boolean;
  hasDeclarationValide: boolean;
  renseigne?: boolean;
  siren?: string;
  nomCommercial?: string;
  raisonSociale?: string;
  of2022: string | null;
  idGrid?: string;
  nomFichier?: string;
  groupeReseau?: string;
  ape?: ApeEntry;
  siteWeb?: string;
  referencable?: boolean;
  dateReferencable: Date;
  dateDerniereDeclaration: Date;
  inerte?: boolean;
  enVoieDesactivation?: boolean;
  desactive?: boolean;
  dateDesactive?: Date;
  motifsEnVoieDesactivation?: MotifEnVoieDesactivation[];
  motifDesactivation?: MotifDesactivation[];
  dateDesactivation?: Date;
  notificationInitiale?: string;
  notificationRappel?: string;
  referencableAvantDesactivation?: boolean;
  nbCertifications?: number;
  statut: IOfStatus;
  annexe?: boolean;
  rappelStagnationEnvoye?: string;
  dateRappelStagnation?: Date;
  numeroTvaIntracommunautaire?: string;
  raisonSocialeRepresentantLegal?: string;
  siretRepresentantLegal?: string;
  score?: number;
  status: OfStatusType;
  presentation?: string;
  domaineActivites: DomaineActivitePrincipalsEntry[];
  modaliteFormations: any[];
  modaliteOrganisation: any[];
  effectifPermanent: OfEffectifPermanentType;
  etablissementPrincipal?: EtablissementPrincipal;
  etablissements?: Etablissement[];
  // certifications?: CertificationEntry[];
  //TODO: certificationCount? or nombreDeCertifications ?
  nombreDeCertifications: number;
  declarations?: Declaration[];
  commentaire: Commentaire[];
  cheminFichier?: string;
  hasSiege: boolean;
  etablissementsRenseigne: boolean;
  activeDeclaration?: [];
};

export type CertificationQualiopiReply = RSListReply & {
  CertificationQualiopiDetails: CertificationQualiopi;
};
export type CertificationQualiopi = {
  da_certif_af: boolean;
  da_certif_bc: boolean;
  da_certif_vae: boolean;
  da_certif_af_app: boolean;
};

export type EtablissementPrincipalReply = RSDetailsReply & {
  EtablissementPrincipalDetails: EtablissementPrincipal;
};

export type EtablissementPrincipal = {
  idEtablissementPrincipal: string;
  renseigne?: boolean;
  nda?: string;
  siret?: string;
  siege?: boolean;
  adresse1?: string;
  adresse2?: string;
  adresse3?: string;
  codePostal?: number;
  ville?: string;
  pays?: string;
  telephone?: string;
  email?: string;
  commentaire?: string;
  dateLimiteReferencabilite?: Date;
  dateRappelLimiteReferencabilite?: Date;
  rappele?: boolean;
  idOf: string;
};

export type EtablissementReply = {
  EtablissementListe: Etablissement[];
};

export type EtablissementAffecteReply = {
  EtablissementAffecteListe: Etablissement[];
};

export type Etablissement = {
  idEtablissement: string;
  renseigne?: boolean;
  siren?: string;
  siret?: string;
  siege?: string;
  nomCommercial?: string;
  address?: string;
  codePostal?: number;
  ville?: string;
  pays?: string;
  telephone?: string;
  email?: string;
  qualificationOF?: boolean;
  idOf: string;
  // affecte: boolean;
};

export type EffectIndicateur = {
  id: string;
  descriptionFacultative: boolean;
  preuvesFacultative: boolean;
  validationFacultative: boolean;
  codeIndicateur: string;
};

export enum OfStatusType {
  INITIE = "INITIE",
  CREE = "CREE",
  REJETE = "REJETE",
  ACTIF = "ACTIF",
  DECLARE = "DECLARE",
  EN_COURS_EXAMEN = "EN_COURS_EXAMEN",
  INCOMPLET = "INCOMPLET",
  EXAMINE = "EXAMINE",
  SUSPENDU = "SUSPENDU",
}

type IOfStatus = OfStatusType;

export enum OfModaliteFormationsType {
  PRESENTIEL = "PRESENTIEL",
  FOAD = "FOAD",
  MIXTE = "MIXTE",
}

export enum OfModaliteOrganisationType {
  INTER = "INTER",
  INTRA = "INTRA",
  INDIVIDUEL = "INDIVIDUEL",
}

export enum OfEffectifPermanentType {
  NON_RENSEIGNE = "NON_RENSEIGNE",
  INDEPENDANT = "INDEPENDANT",
  MOINS_DE_10_SALARIES = "MOINS_DE_10_SALARIES",
  DE_10_A_20_SALARIES = "DE_10_A_20_SALARIES",
  PLUS_DE_20_SALARIES = "PLUS_DE_20_SALARIES",
}

export enum FlagExpirationTreatmentType {
  EXPIRATION_NOTIFIED = "EXPIRATION_NOTIFIED",
  EXPIRATION_TREATED = "EXPIRATION_TREATED",
}

export enum QualificationType {
  GENERALISTE = "GENERALISTE",
  SPECIALISE = "SPECIALISE",
}

export type IDeclarationStatut =
  | "NON_EXAMINE"
  | "VALIDE"
  | "EN_COURS"
  | "REJETE"
  | "FINALISE"
  | "EN_COURS_EXAMEN";

export enum DeclarationStatutType {
  NON_EXAMINE = "NON_EXAMINE",
  VALIDE = "VALIDE",
  EN_COURS = "EN_COURS",
  REJETE = "REJETE",
  INEXISTANTE = "INEXISTANTE",
  FINALISE = "FINALISE",
  EN_COURS_EXAMEN = "EN_COURS_EXAMEN",
}

export enum IndicateurStatusType {
  VALIDE = "VALIDE",
  REJETE = "REJETE",
}

export enum ExamenCertificationType {
  VALIDE = "VALIDE",
  REJETE = "REJETE",
  NON_EXAMINE = "NON_EXAMINE",
}

export enum MotifRejetTypeEnum {
  CERTIFICATION = "CERTIFICATION",
  INDICATEUR = "INDICATEUR",
}

export enum PreuveType {
  PREUVE_FICHIER = "PREUVE_FICHIER",
  PREUVE_WEB = "PREUVE_WEB",
}

// export type OrganismeFinanceurReply = {
//   OrganismeFinanceurListe: OrganismeFinanceur[];
//   OrganismeFinanceurDetails: OrganismeFinanceur;
// };

export type OrganismeFinanceur = {
  idFinanceur: string;
  dateCreation: Date;
  dateModification?: Date;
  codeOrganismeFinanceur?: string;
  siren?: string;
  raisonSociale?: string;
  adresse1?: string;
  adresse2?: string;
  adresse3?: string;
  codePostal?: string;
  ville?: string;
  siteWeb?: string;
  telephone?: string;
  email?: string;
  active?: boolean;
};

export type DeclarationReply = RSDetailsReply & Declaration;
export type DeclarationVerrouReply = RSDetailsReply & DeclarationVerrou;

export type Declaration = {
  idOf: string;
  idDeclaration: string;
  idDeclarationSource?: string;
  dateCreation: Date;
  dateModification?: Date;
  autoRejected?: boolean;
  statut: IDeclarationStatut;
  examenDescriptionCertifications?: string;
  examenCertifications?: ExamenCertificationType;
  examenMotifCodeCertifications?: string;
  examenMotifDescriptionCertifications?: string;
  examenUtilisateurFinanceurId?: string;
  message?: string;
};

export type DeclarationVerrou = {
  idVerrou: string;
  idDeclaration: string;
  utilisateurFinanceurId?: string;
  dateCreation: Date;
  dateExpiration: Date;
  dateModification: Date;
  prenomFinanceur?: string;
  nomDeFamilleFinanceur?: string;
  emailFinanceur?: string;
  structureFinanceur?: string;
  verrouOnMe?: string;
  message?: string;
};

export type Indicateur = {
  idIndicateur: string;
  dateCreation: Date;
  dateModification?: Date;
  concerne?: boolean;
  description?: string;
  status: IndicateurStatusType;
  type: IndicateurType;
  examen: Examen;
  certifications?: CertificationEntry[];
};

export type IndicateurTypeReply = {
  IndicateurListe: IndicateurType[];
};

export type IndicateurType = {
  idIndicateurType: string;
  dateCreation: Date;
  dateModification?: Date;
  nom: string;
  code: string;
  numero: number;
  description: string;
  descriptionPreuves?: string;
  procedure?: string;
  active?: boolean;
  dateActivation?: Date;
  nonConcerne?: boolean;
  descriptionFacultative?: boolean;
  preuvesFacultatives?: boolean;
  validationFacultative?: boolean;
  critere?: Critere;
};

export type CritereReply = RSListReply & {
  CritereListe: Critere[];
};

export type Critere = {
  idCritere: string;
  dateCreation: Date;
  dateModification?: Date;
  nom: string;
  code: string;
  description: string;
  indicateurType: IndicateurType[];
};

export type Examen = {
  idExamen: string;
  dateCreation: Date;
  dateModification?: Date;
  descriptionCertifications: string;
  examenCertifications: ExamenCertificationType;
  motifCodeCertifications: string;
  motifDescriptionCertifications: string;
  idDeclaration: string;
  idFinanceur?: string;
  motifRejetType?: MotifRejetType;
};

export type MotifRejetType = {
  idMotifRejetType: string;
  dateCreation: Date;
  dateModification?: Date;
  code: string;
  description: string;
  type: MotifRejetTypeEnum;
  examenId: string;
};

export type Preuve = {
  idPreuve: string;
  dateCreation: Date;
  dateModification?: Date;
  indicateurId?: string;
  preuveType: PreuveType;
  url?: string;
  idGrid?: string;
  nom?: string;
};

export type CommentaireListeReply = RSListReply & {
  CommentaireListe: Commentaire[];
};

export type Commentaire = {
  idCommentaire: string;
  dateCreation: Date;
  dateModification?: Date;
  idStructureFinanceur?: string;
  detail?: string;
  type?: string;
  nomUtilisateur?: string;
  prenomUtilisateur?: string;
  emailUtilisateur?: string;
  active?: boolean;
  motif?: MotifCommentaireEntry;
  OrganismeFormation: OrganismeDeFormation;
  OrganismeFinanceur: OrganismeFinanceur;
};

export type MotifCommentaireReply = RSListReply & {
  MotifCommentaireListe: MotifCommentaireEntry[];
};
export type MotifCommentaireEntry = {
  idMotifCommentaire: string;
  dateCreation: Date;
  dateModification?: Date;
  active?: boolean;
  code?: string;
  motif?: string;
};

export type ApeEntry = {
  id: string;
  code: string;
  activite: string;
  section: string;
  categorie: string;
};

export type ApeReply = {
  ApeDetail: ApeEntry;
  ApeListe: ApeEntry[];
};

export type ApeListeReply = RSListReply & {
  ApeListe: ApeEntry[];
};

export type ReferentielCertificateurEntry = {
  idCertificateur: string;
  siren: string;
  raisonSociale: string;
};

export type ReferentielCertificateurListeReply = RSListReply & {
  CertificateurListe: ReferentielCertificateurEntry[];
};

export enum ModaliteFormationsType {
  NON_RENSEIGNE = "NON_RENSEIGNE",
  PRESENTIEL = "PRESENTIEL",
  FOAD = "FOAD",
  MIXTE = "MIXTE",
  PAR_TELEPHONE = "PAR_TELEPHONE",
}

export enum ModaliteOrganisationType {
  NON_RENSEIGNE = "NON_RENSEIGNE",
  INTER = "INTER",
  INTRA = "INTRA",
  INDIVIDUEL = "INDIVIDUEL",
}

export type DomaineActivitePrincipalsEntry = {
  id: string;
  code: string;
  libelle: string;
};

export type DomaineActivitePrincipalsReply = {
  DomaineActivitePrincipalListe: DomaineActivitePrincipalsEntry[];
  DomaineActivitePrincipalDetail: DomaineActivitePrincipalsEntry;
};

export type TypeOrganismeDeFormationsEntry = {
  id: string;
  label: string;
  categorie: string;
};

export type ReferencielDetailsEntry = {
  apes: ApeEntry[];
  typeOrganismeDeFormations: TypeOrganismeDeFormationsEntry[];
  domaineActivitePrincipals: DomaineActivitePrincipalsEntry[];
};

export type ReferencielDetailsReply = {
  ReferencielDetails: ReferencielDetailsEntry;
};

export const ExamenStatus = [
  {
    key: "REFUSE",
    valeur: "Réfusé",
    message: "Réfusé par le financeur",
  },
  {
    key: "VALIDE",
    valeur: "Validé",
    message: "Validé par le financeur",
  },
  {
    key: "NON_EXAMINE",
    valeur: "Non examiné",
    message: "A valider par le financeur",
  },
];

export type HistoriqueQualiopiListeReply = RSListReply & {
  HistoriqueQualiopiListe: HistoriqueQualiopi[];
};

export type HistoriqueQualiopi = {
  historiqueId: string;
  siren: string;
  nda: string;
  dateDerniereEntreeCertifAf?: Date;
  dateDerniereSortieCertifAf?: Date;
  dateDerniereEntreeCertifBC?: Date;
  dateDerniereSortieCertifBC?: Date;
  dateDerniereEntreeCertifVAE?: Date;
  dateDerniereSortieCertifVAE?: Date;
  dateDerniereEntreeCertifAFPA?: Date;
  dateDerniereSortieCertifAFPA?: Date;
  historiqueQualiopiOfLigne: HistoriqueQualiopiOfLigne[];
};

export type CertificateurListeReply = RSListReply & {
  CertificateurListe: Certificateur[];
};
export type Certificateur = {
  idCertificateur: string;
  dateDebutValidite?: Date;
  dateFinValidite?: Date;
  actif?: boolean;
  actionsDeformation?: boolean;
  apprentissage?: boolean;
  bilansDeCompetences?: boolean;
  vae?: boolean;
  certificateurSiren: string;
  typeCertificateur: string;
  raisonSociale: string;
  siren: string;
  nda: string;
  dateCreation?: Date;
  dateModification?: Date;
};

export type HistoriqueQualiopiOfLigneReply = RSListReply & {
  HistoriqueQualiopiOfLigneListe: HistoriqueQualiopiOfLigne[];
};
export type HistoriqueQualiopiOfLigne = {
  historiqueLigneId: string;
  date?: Date;
  etatAF?: boolean;
  etatBC?: boolean;
  etatVAE?: boolean;
  etatAPP?: boolean;
};

export type HistoriqueOfReply = RSListReply & {
  HistoriqueOfListe: HistoriqueOf[];
};

export type HistoriqueOf = {
  idTrace: string;
  action: string;
  dateEvenement: Date;
  nda: string;
  siren: string;
  structureId: string;
  utilisateurIdStructure: string;
  utilisateurIdentifiant: string;
  utilisateurId: string;
  utilisateurNom: string;
  utilisateurNomStructure: string;
  utilisateurPrenom: string;
  utilisateurTypeStructure: string;
  status: string;
  extra?: any;
};

export type CertificationTypeReply = RSListReply & {
  TypeCertificationListe: CertificationType[];
  TypeCertificationDetails: CertificationType;
};

export type CertificationType = {
  idCertificationType: string;
  dateCreation: Date;
  dateModification: Date;
  code: string;
  nom: string;
  description: string;
  active: boolean;
  dateInscriptionMinimale: Date;
  dureeMois: string;
  qualification: EnumQualificationCertificationType;
  effectIndicateurs: EffectIndicateurs[];
};

export type EffectIndicateurs = {
  codeIndicateur: string;
  descriptionFacultative: boolean;
  idEffetIndicateur: string;
  preuvesFacultative: boolean;
  validationFacultative: boolean;
};

export enum EnumQualificationCertificationType {
  "GENERALISTE" = "GENERALISTE",
  "SPECIALISE" = "SPECIALISE",
}

export const EnumQualificationCertificationTypeToString = [
  {
    key: "GENERALISTE",
    valeur: "Généraliste",
  },
  {
    key: "SPECIALISE",
    valeur: "Spécialisé",
  },
];

export const EnumToStringArray = [
  {
    key: "NON_RENSEIGNE",
    valeur: "Non renseigné",
  },
  {
    key: "PRESENTIEL",
    valeur: "Présentiel",
  },
  {
    key: "FOAD",
    valeur: "Foad",
  },
  {
    key: "MIXTE",
    valeur: "Mixte",
  },
  {
    key: "PAR_TELEPHONE",
    valeur: "Par téléphone",
  },
  {
    key: "INTER",
    valeur: "Inter",
  },
  {
    key: "INTRA",
    valeur: "Intra",
  },
  {
    key: "INDIVIDUEL",
    valeur: "Individuel",
  },
  {
    key: "INDEPENDANT",
    valeur: "Indépendant",
  },
  {
    key: "MOINS_DE_10_SALARIES",
    valeur: "Moins de 10 salariés",
  },
  {
    key: "DE_10_A_20_SALARIES",
    valeur: "Entre 10 et 20 salariés",
  },
  {
    key: "PLUS_DE_20_SALARIES",
    valeur: "Plus de 20 salariés",
  },
];

export type Controle = {
  id: string;
  dateCreation: Date;
  dateModification: Date;
  idOf: string;
  rapportIdGrid: string;
  rapportNomFichier: string;
  annexetIdGrid: string;
  annexeNomFichier: string;
  notificationIdGrid: string;
  notificationNomFichier: string;
};

export type ControleReply = RSListReply & {
  ControleDetails: Controle;
};

export type RegionReply = RSListReply & {
  RegionListe: Region[];
};

export type Region = {
  id: string;
  code: string;
  nom: string;
};