/**
* ExamenDeclaration Routes
*/

export const ExamenDeclarationRoutes = {
	

toExamenResumePage: (idOf?: string, type_declaration?: string)  : string => idOf ? `/interface/fiche-of/${idOf}/${type_declaration}/index` :  '/interface/fiche-of/:idOf/:type_declaration/index',

toFicheOfMieuxConnaitrePage: (idOf?: string, type_declaration?: string)  : string => idOf ? `/interface/fiche-of/${idOf}/${type_declaration}/contexte` :  '/interface/fiche-of/:idOf/:type_declaration/contexte',

toExamenCertificationsPage: (idOf?: string, type_declaration?: string)  : string => idOf ? `/interface/fiche-of/${idOf}/${type_declaration}/certifications` :  '/interface/fiche-of/:idOf/:type_declaration/certifications',

toExamenFinalisationPage: (idOf?: string, type_declaration?: string)  : string => idOf ? `/interface/fiche-of/${idOf}/${type_declaration}/finalisation` :  '/interface/fiche-of/:idOf/:type_declaration/finalisation',

toExamenIndicateurPage: (idOf?: string, type_declaration?: string, id_indicateur?: string)  : string => idOf ? `/interface/fiche-of/${idOf}/${type_declaration}/indicateur/${id_indicateur}` :  '/interface/fiche-of/:idOf/:type_declaration/indicateur/:id_indicateur',

toExamenAnalyseIndicateurPage: (idOf?: string, type_declaration?: string, id_indicateur?: string)  : string => idOf ? `/interface/fiche-of/${idOf}/${type_declaration}/analyse/${id_indicateur}` :  '/interface/fiche-of/:idOf/:type_declaration/analyse/:id_indicateur',

};