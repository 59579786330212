import React from "react";

        import { VotreCompteRoutes } from "./VotreCompteRoutes";
        import { RequireAuth, NotRequireAuth } from "@d4b/fluent-ui";
        import { Routes, Route } from "react-router-dom";
        

const FeatureVotreCompte = {
    
InformationsDeComptePage: React.lazy(() => import("@DataDock/InterfaceGlobale/VotreCompte/views/InformationsDeComptePage")),


ChangerEmailPage: React.lazy(() => import("@DataDock/InterfaceGlobale/VotreCompte/views/ChangerEmailPage")),


ModifierInformationsComptePage: React.lazy(() => import("@DataDock/InterfaceGlobale/VotreCompte/views/ModifierInformationsComptePage")),


ModifierMotDePassePage: React.lazy(() => import("@DataDock/InterfaceGlobale/VotreCompte/views/ModifierMotDePassePage")),


};

export const VotreCompteAppRoutes = () => (
    <Routes>
        
                        <Route key={"routeInformationsDeComptePage"} element={<RequireAuth />}>
                          <Route path={VotreCompteRoutes.toInformationsDeComptePage()} element={<FeatureVotreCompte.InformationsDeComptePage />} />
                        </Route>
                      
                        <Route key={"routeChangerEmailPage"} element={<NotRequireAuth />}>
                          <Route path={VotreCompteRoutes.toChangerEmailPage()} element={<FeatureVotreCompte.ChangerEmailPage />} />
                        </Route>
                        
                        <Route key={"routeModifierInformationsComptePage"} element={<RequireAuth />}>
                          <Route path={VotreCompteRoutes.toModifierInformationsComptePage()} element={<FeatureVotreCompte.ModifierInformationsComptePage />} />
                        </Route>
                      
                        <Route key={"routeModifierMotDePassePage"} element={<RequireAuth />}>
                          <Route path={VotreCompteRoutes.toModifierMotDePassePage()} element={<FeatureVotreCompte.ModifierMotDePassePage />} />
                        </Route>
                      
    </Routes>
);