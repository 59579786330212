/**
* AdminStructuresFinanceur Routes
*/

export const AdminStructuresFinanceurRoutes = {
	

toAdminVisualisationStructurePage: ()  : string =>  '/interface/financeurs-administration',

toAdminFicheStructureFinanceurPage: (idFinanceur?: string)  : string => idFinanceur ? `/interface/financeurs/${idFinanceur}/structure` :  '/interface/financeurs/:idFinanceur/structure',

toAdminModifierStructureFinanceurPage: (idFinanceur?: string)  : string => idFinanceur ? `/interface/financeurs/${idFinanceur}/modifier` :  '/interface/financeurs/:idFinanceur/modifier',

toAdminAjouterStructureFinanceurPage: (idFinanceur?: string)  : string => idFinanceur ? `/interface/financeurs/${idFinanceur}/ajouter` :  '/interface/financeurs/:idFinanceur/ajouter',

toCompteDelegePage: (idFinanceur?: string)  : string => idFinanceur ? `/interface/financeurs/${idFinanceur}/utilisateurs` :  '/interface/financeurs/:idFinanceur/utilisateurs',

toAjouterCompteDelegePage: (idFinanceur?: string)  : string => idFinanceur ? `/interface/financeurs/${idFinanceur}/utilisateurs-form` :  '/interface/financeurs/:idFinanceur/utilisateurs-form',

};