import React from "react";

        import { TableauDeBordRoutes } from "./TableauDeBordRoutes";
        import { RequireAuth } from "@d4b/fluent-ui";
        import { Routes, Route } from "react-router-dom";
        

const FeatureTableauDeBord = {
    
TableauDeBordOperationnelPage: React.lazy(() => import("@DataDock/Financeur/TableauDeBord/views/TableauDeBordOperationnelPage")),


};

export const TableauDeBordAppRoutes = () => (
    <Routes>
        
                        <Route key={"routeTableauDeBordOperationnelPage"} element={<RequireAuth />}>
                          <Route path={TableauDeBordRoutes.toTableauDeBordOperationnelPage()} element={<FeatureTableauDeBord.TableauDeBordOperationnelPage />} />
                        </Route>
                      
    </Routes>
);