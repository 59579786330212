import { useAuth } from "lib/utils/auth";
import {
  Navigate,
  Outlet,
  Route,
  useLocation
} from "react-router-dom";

export const PrivateRoute = ({ children, ...rest }: any) => {
  let auth = useAuth();
  if (auth.isLoading) return <></>;

  return (
    <Route
      {...rest}
      render={({ location }: any) =>
        auth.isAuthenticated ? (
          children
        ) : (
          <Navigate
            to={{
              pathname: "/interface",
            }}
            state={{ from: location }}
          />
        )
      }
    />
  );
};

export const PublicRoute = ({ children, ...rest }: any) => {
  let auth = useAuth();
  if (auth.isLoading) return <></>;
  return <Route {...rest} render={({ location }: any) => children} />;
};

export const RequireAuth = () => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};

export const NotRequireAuth = () => {
  return <Outlet />;
};
