import { Spinner, Stack } from "@fluentui/react";

import { DataDockApplicationRoutes } from "@DataDock/DataDockApplicationRoutes";
import { AppHeader, useAppContext } from "lib";
import React, { Suspense, useEffect } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router } from "react-router-dom";
import { AppFooter } from "./AppFooter";
import { axiosInstance } from "@d4b/api";
import { useAuth } from "lib/utils/auth";

export const AppRoutes: React.FC = () => {
  const {signout} = useAuth();
  
  useEffect(() => {
    axiosInstance.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error.response.status === 401) {
          console.log("UNAUTHORIZED - login out", error.response.config.url);
          if (error.response.config.url.indexOf('compte-actuel') === -1) {
            console.log('Votre cookie a expiré. Veuillez vous reconnecter.')
            signout(()=>{});
          }
        }
        return Promise.reject(error);
      }
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isLoading, loadingText, ws } = useAppContext();



  return isLoading ? (
    <Spinner label={loadingText} styles={{ root: { marginTop: 25 } }} />
  ) : (
    <Router>
      <div className="wrapper">
        <Helmet
          title={`DataDock ${ws?.version}`}
          titleTemplate={`%s | DataDock ${ws?.version}`}
        />
        <div
          className="header"
          style={{ position: "fixed", width: "100%", zIndex: 2 }}
        >
          <AppHeader />
        </div>
        <Stack className="main">
          {/* {isAuthenticated && (
            <AppNavs toggleMenu={toggleMenu} setToggleMenu={setToggleMenu} />
          )} */}

          <Stack
            styles={{
              root: {
                margin: 0,
                padding: 0,
                width: "100%",
                //marginLeft: toggleMenu ? 48 : 240,
                marginTop: 48,
                //overflowX: "auto",
                minHeight: "calc(100vh - 93px)",
              },
            }}
          >
            <Suspense
              fallback={
                <Spinner
                  label="Loading page"
                  styles={{ root: { marginTop: 25 } }}
                />
              }
            >
                <DataDockApplicationRoutes />
              
            </Suspense>
          </Stack>
          <AppFooter />
        </Stack>
      </div>
    </Router>
  );
};
