import { PrimaryButton } from "@fluentui/react";

/**
 * This component is used for displaying primary button
 *
 * @version 1.0.0
 * @param {any} props
 * @returns
 */
export const FormButton = (props: any) => {
  return (
    <PrimaryButton
      {...props}
      text={props.text}
      styles={{
        root: {
          backgroundColor: "#c94420",
          borderColor: "#c34d2d",
          ...props.routStyles,
        },
        ...props.styles,
      }}
    />
  );
};
