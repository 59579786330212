import React from "react";

        import { AdminGestionOfRoutes } from "./AdminGestionOfRoutes";
        import { RequireAuth } from "@d4b/fluent-ui";
        import { Routes, Route } from "react-router-dom";
        

const FeatureAdminGestionOf = {
    
AdminRechercheOfPage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminGestionOf/views/AdminRechercheOfPage")),


AdminAjouterOFPage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminGestionOf/views/AdminAjouterOFPage")),


AdminExporterOfPage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminGestionOf/views/AdminExporterOfPage")),


AdminImporterOfPage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminGestionOf/views/AdminImporterOfPage")),


};

export const AdminGestionOfAppRoutes = () => (
    <Routes>
        
                        <Route key={"routeAdminRechercheOfPage"} element={<RequireAuth />}>
                          <Route path={AdminGestionOfRoutes.toAdminRechercheOfPage()} element={<FeatureAdminGestionOf.AdminRechercheOfPage />} />
                        </Route>
                      
                        <Route key={"routeAdminAjouterOFPage"} element={<RequireAuth />}>
                          <Route path={AdminGestionOfRoutes.toAdminAjouterOFPage()} element={<FeatureAdminGestionOf.AdminAjouterOFPage />} />
                        </Route>
                      
                        <Route key={"routeAdminExporterOfPage"} element={<RequireAuth />}>
                          <Route path={AdminGestionOfRoutes.toAdminExporterOfPage()} element={<FeatureAdminGestionOf.AdminExporterOfPage />} />
                        </Route>
                      
                        <Route key={"routeAdminImporterOfPage"} element={<RequireAuth />}>
                          <Route path={AdminGestionOfRoutes.toAdminImporterOfPage()} element={<FeatureAdminGestionOf.AdminImporterOfPage />} />
                        </Route>
                      
    </Routes>
);