import React from "react";

        import { CreerUnCompteRoutes } from "./CreerUnCompteRoutes";
        import { NotRequireAuth } from "@d4b/fluent-ui";
        import { Routes, Route } from "react-router-dom";
        

const FeatureCreerUnCompte = {
    
CreationComptePage: React.lazy(() => import("@DataDock/OrganismeDeFormation/CreerUnCompte/views/CreationComptePage")),


RecapitulatifCreationComptePage: React.lazy(() => import("@DataDock/OrganismeDeFormation/CreerUnCompte/views/RecapitulatifCreationComptePage")),


FinaliserCreationComptePage: React.lazy(() => import("@DataDock/OrganismeDeFormation/CreerUnCompte/views/FinaliserCreationComptePage")),


FinaliserCreationCompteDeleguePage: React.lazy(() => import("@DataDock/OrganismeDeFormation/CreerUnCompte/views/FinaliserCreationCompteDeleguePage")),


};

export const CreerUnCompteAppRoutes = () => (
    <Routes>
        
                        <Route key={"routeCreationComptePage"} element={<NotRequireAuth />}>
                          <Route path={CreerUnCompteRoutes.toCreationComptePage()} element={<FeatureCreerUnCompte.CreationComptePage />} />
                        </Route>
                        
                        <Route key={"routeRecapitulatifCreationComptePage"} element={<NotRequireAuth />}>
                          <Route path={CreerUnCompteRoutes.toRecapitulatifCreationComptePage()} element={<FeatureCreerUnCompte.RecapitulatifCreationComptePage />} />
                        </Route>
                        
                        <Route key={"routeFinaliserCreationComptePage"} element={<NotRequireAuth />}>
                          <Route path={CreerUnCompteRoutes.toFinaliserCreationComptePage()} element={<FeatureCreerUnCompte.FinaliserCreationComptePage />} />
                        </Route>
                        
                        <Route key={"routeFinaliserCreationCompteDeleguePage"} element={<NotRequireAuth />}>
                          <Route path={CreerUnCompteRoutes.toFinaliserCreationCompteDeleguePage()} element={<FeatureCreerUnCompte.FinaliserCreationCompteDeleguePage />} />
                        </Route>
                        
    </Routes>
);