import React from "react";

        import { AdminStructuresFinanceurRoutes } from "./AdminStructuresFinanceurRoutes";
        import { RequireAuth } from "@d4b/fluent-ui";
        import { Routes, Route } from "react-router-dom";
        

const FeatureAdminStructuresFinanceur = {
    
AdminVisualisationStructurePage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminStructuresFinanceur/views/AdminVisualisationStructurePage")),


AdminFicheStructureFinanceurPage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminStructuresFinanceur/views/AdminFicheStructureFinanceurPage")),


AdminModifierStructureFinanceurPage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminStructuresFinanceur/views/AdminModifierStructureFinanceurPage")),


AdminAjouterStructureFinanceurPage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminStructuresFinanceur/views/AdminAjouterStructureFinanceurPage")),


CompteDelegePage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminStructuresFinanceur/views/CompteDelegePage")),


AjouterCompteDelegePage: React.lazy(() => import("@DataDock/AdministrateurDatadock/AdminStructuresFinanceur/views/AjouterCompteDelegePage")),


};

export const AdminStructuresFinanceurAppRoutes = () => (
    <Routes>
        
                        <Route key={"routeAdminVisualisationStructurePage"} element={<RequireAuth />}>
                          <Route path={AdminStructuresFinanceurRoutes.toAdminVisualisationStructurePage()} element={<FeatureAdminStructuresFinanceur.AdminVisualisationStructurePage />} />
                        </Route>
                      
                        <Route key={"routeAdminFicheStructureFinanceurPage"} element={<RequireAuth />}>
                          <Route path={AdminStructuresFinanceurRoutes.toAdminFicheStructureFinanceurPage()} element={<FeatureAdminStructuresFinanceur.AdminFicheStructureFinanceurPage />} />
                        </Route>
                      
                        <Route key={"routeAdminModifierStructureFinanceurPage"} element={<RequireAuth />}>
                          <Route path={AdminStructuresFinanceurRoutes.toAdminModifierStructureFinanceurPage()} element={<FeatureAdminStructuresFinanceur.AdminModifierStructureFinanceurPage />} />
                        </Route>
                      
                        <Route key={"routeAdminAjouterStructureFinanceurPage"} element={<RequireAuth />}>
                          <Route path={AdminStructuresFinanceurRoutes.toAdminAjouterStructureFinanceurPage()} element={<FeatureAdminStructuresFinanceur.AdminAjouterStructureFinanceurPage />} />
                        </Route>
                      
                        <Route key={"routeCompteDelegePage"} element={<RequireAuth />}>
                          <Route path={AdminStructuresFinanceurRoutes.toCompteDelegePage()} element={<FeatureAdminStructuresFinanceur.CompteDelegePage />} />
                        </Route>
                      
                        <Route key={"routeAjouterCompteDelegePage"} element={<RequireAuth />}>
                          <Route path={AdminStructuresFinanceurRoutes.toAjouterCompteDelegePage()} element={<FeatureAdminStructuresFinanceur.AjouterCompteDelegePage />} />
                        </Route>
                      
    </Routes>
);