import { IColumn } from "@fluentui/react";
import { ISortableColumn, makeSortableColumns, useUrlParams } from "@d4b/fluent-ui";

// export type IColumnEntry = Omit<IColumn, "key">  & {
//   isNotSortable?: boolean
// };
export const useSortedColumns = (
  columns: ISortableColumn<any>[],
  urlParams: any
) => {
  const filterParams = useUrlParams(urlParams);
  // console.log(filterParams)
  const columnsSorted: IColumn[] = makeSortableColumns(
    columns,
    filterParams
  );
  return { columnsSorted, filterParams };
};
export default useSortedColumns;