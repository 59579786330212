import { IColumn, Icon } from "@fluentui/react";
import { fromNowDate, UseUrlParams, TableColumnSortBtn, humanizeDateUseMemo } from "@d4b/fluent-ui";

type PageContext = {
  name: string;
};

type IColumnSortContext = {
  page: PageContext;
  detailsListName: string;
};

export type ISortableColumn<T> = {
  key?: string;
  name?: string;
  fieldName: keyof T & string;
  customSort?: any;
  minWidth?: number;
  maxWidth?: number;
  isDate?: boolean;
  isYN?: boolean;
  isTimeOnly?: boolean;
  isNumber?: boolean;
  numberSuffix?: string;
  isFromNowDate?: boolean;
  isDateHumanized?: boolean;
  isNotSortable?: boolean;
  isResizable?: boolean;
  onRender?: (item: T) => React.ReactNode;
  onRenderHeader?: (item: T) => React.ReactNode;
};

export const getLocalStorageName = (ctx: IColumnSortContext) =>
  `${ctx.page.name}:ColumnSort:${ctx.detailsListName}`;

export function makeSortableColumns<T>(
  columns: ISortableColumn<T>[],
  filterParams: UseUrlParams,
  displayedCols?: string[]
): IColumn[] {
  let resultCols: IColumn[] = [];
  columns.forEach((column) => {
    column.key = column.key|| column.fieldName || column.name;
    column.name = column.name || column.fieldName;
    if (!column.isNotSortable)
      column.onRenderHeader = (item: any) => (
        <TableColumnSortBtn
          item={item}
          filterParams={filterParams}
          customSort={column.customSort}
        />
      );
    if (column.isDate)
      column.onRender = (item: any) => (
        <span>
          {item[column.fieldName]
            ? column.isTimeOnly
              ? new Date(item[column.fieldName] + "Z").toLocaleTimeString()
              : new Date(item[column.fieldName] + "Z").toLocaleString()
            : ""}
        </span>
      );
    if (column.isDateHumanized)
      column.onRender = (item: any) =>
        humanizeDateUseMemo(item[column.fieldName] + "Z");

    if (column.isFromNowDate)
      column.onRender = (item: any) =>
        fromNowDate(item[column.fieldName] + "Z", { suffix: true });

    if (column.isYN)
      column.onRender = (item: any) => (
        <span>
          {item[column.fieldName] === "Y" ? <Icon iconName="accept" /> : ""}
        </span>
      );

    if (column.isNumber)
      column.onRender = (item: any) => (
        <span>
          {(item[column.fieldName] as number) === 0
            ? "-"
            : item[column.fieldName]}
          {column.numberSuffix && column.numberSuffix}
        </span>
      );

    if (!displayedCols) {
      resultCols.push(column as IColumn);
    } else if (displayedCols.includes(column.key || column.fieldName)) {
      resultCols.push(column as IColumn);
    }
  });
  return resultCols;
}
