import { IStackItemStyles, Stack } from "@fluentui/react";

export type PrimaryCommandeBarProps = {
    stackItemStyles: IStackItemStyles; 
    buttons: React.ReactNode;
}
export const PrimaryCommandeBar = ({stackItemStyles,buttons}: PrimaryCommandeBarProps) => {
    return <Stack horizontal styles={stackItemStyles}>
        {buttons && buttons}
    </Stack>;
}