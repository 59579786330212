import { ThemeProvider } from "@fluentui/react";
import { createTheme } from "@fluentui/style-utilities";
import React from "react";
import { ReactQueryDevtools } from "react-query/devtools";
import { AppRoutes, BreakpointProvider } from "lib/components";
import { AxiosProvider } from "@d4b/api";
import { AppContextProvider } from "lib";
import { ProvideAuth } from "lib/utils/auth";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "./App.css";

// export const theme = getTheme();
export const theme = createTheme({
  palette: {
    themePrimary: "#0074e8",
    themeLighterAlt: "#f4f9fe",
    themeLighter: "#d3e7fb",
    themeLight: "#aed3f8",
    themeTertiary: "#60a9f1",
    themeSecondary: "#1c83eb",
    themeDarkAlt: "#0068d1",
    themeDark: "#0058b0",
    themeDarker: "#004182",
    neutralLighterAlt: "#faf9f8",
    neutralLighter: "#f3f2f1",
    neutralLight: "#edebe9",
    neutralQuaternaryAlt: "#e1dfdd",
    neutralQuaternary: "#d0d0d0",
    neutralTertiaryAlt: "#c8c6c4",
    neutralTertiary: "#a19f9d",
    neutralSecondary: "#605e5c",
    neutralSecondaryAlt: "#8a8886",
    neutralPrimaryAlt: "#3b3a39",
    neutralPrimary: "#323130",
    neutralDark: "#201f1e",
    black: "#000000",
    white: "#ffffff",
  },
});

export const themePalette = theme.palette;

const sentryDSN =
window && (window as any)._env_
  ? (window as any)._env_.SENTRY_REACT_DSN
  : "";

const isDevelopmentEnvironment = false;// ;(process?.env?.NODE_ENV === "development");
(sentryDSN &&  sentryDSN !== "" ) && Sentry.init({
  dsn: sentryDSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: isDevelopmentEnvironment ? 1.0 : 0.25,
});

export const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme} style={{ height: "inherit" }}>
      <BreakpointProvider>
        <AxiosProvider>
          <AppContextProvider>
            <ProvideAuth>
              <AppRoutes />
            </ProvideAuth>
          </AppContextProvider>
        </AxiosProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </BreakpointProvider>
    </ThemeProvider>
  );
};
