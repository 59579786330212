import React from "react";

        import { OFRoutes } from "./OFRoutes";
        import { RequireAuth } from "@d4b/fluent-ui";
        import { Routes, Route } from "react-router-dom";
        

const FeatureOF = {
    
RechercheSimpleOFPage: React.lazy(() => import("@DataDock/Financeur/OF/views/RechercheSimpleOFPage")),


FicheOfResumePage: React.lazy(() => import("@DataDock/Financeur/OF/views/FicheOfResumePage")),


FicheOfInformationGeneralePage: React.lazy(() => import("@DataDock/Financeur/OF/views/FicheOfInformationGeneralePage")),


FicheOfMieuxConnaitrePage: React.lazy(() => import("@DataDock/Financeur/OF/views/FicheOfMieuxConnaitrePage")),


FicheOfEtablissementPrincipalePage: React.lazy(() => import("@DataDock/Financeur/OF/views/FicheOfEtablissementPrincipalePage")),


FicheOfListeEtablissementPage: React.lazy(() => import("@DataDock/Financeur/OF/views/FicheOfListeEtablissementPage")),


FicheOfHistoriqueQualiopiPage: React.lazy(() => import("@DataDock/Financeur/OF/views/FicheOfHistoriqueQualiopiPage")),


FicheOfHistoriquePage: React.lazy(() => import("@DataDock/Financeur/OF/views/FicheOfHistoriquePage")),


FicheOfReferencementPage: React.lazy(() => import("@DataDock/Financeur/OF/views/FicheOfReferencementPage")),


CreerOFSansNDAPage: React.lazy(() => import("@DataDock/Financeur/OF/views/CreerOFSansNDAPage")),


ConfirmationCreationComptePage: React.lazy(() => import("@DataDock/Financeur/OF/views/ConfirmationCreationComptePage")),


FicheOfCertificateursPage: React.lazy(() => import("@DataDock/Financeur/OF/views/FicheOfCertificateursPage")),


};

export const OFAppRoutes = () => (
    <Routes>
        
                        <Route key={"routeRechercheSimpleOFPage"} element={<RequireAuth />}>
                          <Route path={OFRoutes.toRechercheSimpleOFPage()} element={<FeatureOF.RechercheSimpleOFPage />} />
                        </Route>
                      
                        <Route key={"routeFicheOfResumePage"} element={<RequireAuth />}>
                          <Route path={OFRoutes.toFicheOfResumePage()} element={<FeatureOF.FicheOfResumePage />} />
                        </Route>
                      
                        <Route key={"routeFicheOfInformationGeneralePage"} element={<RequireAuth />}>
                          <Route path={OFRoutes.toFicheOfInformationGeneralePage()} element={<FeatureOF.FicheOfInformationGeneralePage />} />
                        </Route>
                      
                        <Route key={"routeFicheOfMieuxConnaitrePage"} element={<RequireAuth />}>
                          <Route path={OFRoutes.toFicheOfMieuxConnaitrePage()} element={<FeatureOF.FicheOfMieuxConnaitrePage />} />
                        </Route>
                      
                        <Route key={"routeFicheOfEtablissementPrincipalePage"} element={<RequireAuth />}>
                          <Route path={OFRoutes.toFicheOfEtablissementPrincipalePage()} element={<FeatureOF.FicheOfEtablissementPrincipalePage />} />
                        </Route>
                      
                        <Route key={"routeFicheOfListeEtablissementPage"} element={<RequireAuth />}>
                          <Route path={OFRoutes.toFicheOfListeEtablissementPage()} element={<FeatureOF.FicheOfListeEtablissementPage />} />
                        </Route>
                      
                        <Route key={"routeFicheOfHistoriqueQualiopiPage"} element={<RequireAuth />}>
                          <Route path={OFRoutes.toFicheOfHistoriqueQualiopiPage()} element={<FeatureOF.FicheOfHistoriqueQualiopiPage />} />
                        </Route>
                      
                        <Route key={"routeFicheOfHistoriquePage"} element={<RequireAuth />}>
                          <Route path={OFRoutes.toFicheOfHistoriquePage()} element={<FeatureOF.FicheOfHistoriquePage />} />
                        </Route>
                      
                        <Route key={"routeFicheOfReferencementPage"} element={<RequireAuth />}>
                          <Route path={OFRoutes.toFicheOfReferencementPage()} element={<FeatureOF.FicheOfReferencementPage />} />
                        </Route>
                      
                        <Route key={"routeCreerOFSansNDAPage"} element={<RequireAuth />}>
                          <Route path={OFRoutes.toCreerOFSansNDAPage()} element={<FeatureOF.CreerOFSansNDAPage />} />
                        </Route>
                      
                        <Route key={"routeConfirmationCreationComptePage"} element={<RequireAuth />}>
                          <Route path={OFRoutes.toConfirmationCreationComptePage()} element={<FeatureOF.ConfirmationCreationComptePage />} />
                        </Route>
                      
                        <Route key={"routeFicheOfCertificateursPage"} element={<RequireAuth />}>
                          <Route path={OFRoutes.toFicheOfCertificateursPage()} element={<FeatureOF.FicheOfCertificateursPage />} />
                        </Route>
                      
    </Routes>
);