import {
  Callout,
  DefaultButton,
  DefaultEffects,
  FontWeights,
  IStackStyles,
  IStyle,
  mergeStyleSets,
  Stack,
  Text,
  DefaultPalette,
  Link,
  CommandButton,
  ContextualMenuItemType,
} from "@fluentui/react";
import pkg from "../../../package.json";
import { useBoolean, useId } from "@fluentui/react-hooks";
import { themePalette } from "App";
import React, { ReactElement } from "react";
import { useAppContext, UserPreferencePanel } from "lib";
import { ReactComponent as Logo } from "../../lib/components/logo/logo_datadock.svg";
import { useNavigate } from "react-router-dom";
import { useAuth } from "lib/utils/auth";

const styles = mergeStyleSets({
  button: {
    width: 130,
  },
  callout: {
    width: 320,
    maxWidth: "90%",
    padding: "12px 24px",
  },
  title: {
    marginTop: 12,
    fontWeight: FontWeights.bold,
  },
});
type VersionTableProps = {
  children?: React.ReactNode;
};
const VersionTable = ({ children }: VersionTableProps) => (
  <table>
    <tbody>{children}</tbody>
  </table>
);

type VersionLineProps = {
  text: string;
  version?: string;
};
export const VersionLine = ({
  text,
  version = "undefined",
}: VersionLineProps) => (
  <tr style={{ marginBottom: 5 }}>
    <td style={{ marginRight: 5 }}>
      <Text variant="medium">
        <span className={styles.title}>{text}</span>
      </Text>
    </td>
    <td>
      <Text>{version}</Text>
    </td>
  </tr>
);

export const AppHeader = (): ReactElement => {
  const navigate = useNavigate();
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] =
    useBoolean(false);
  const buttonId = useId("callout-button");

  const { ws, isAuthenticated, compteUtilisateur } = useAppContext();

  const stylesRoot: IStackStyles = {
    root: {
      margin: "0 auto",
      width: "100%",
      height: 48,
      lineHeight: 48,
      boxShadow: DefaultEffects.elevation8,
      backgroundColor: DefaultPalette.themePrimary,
      color: themePalette.themeLighterAlt,
    },
  };

  const TopLeftGroupeStack: IStackStyles = {
    root: { display: "flex", alignItems: "center", flexFlow: "inherit" },
  };
  const MiddleStack: IStackStyles = {
    root: { width: "100%", flexFlow: "inherit" },
  };

  const badgeTextStyle: IStyle = {
    height: 25,
    backgroundColor: DefaultPalette.themePrimary,
    borderColor: DefaultPalette.white,
    color: "#FFF",
    lineHeight: "18px",
    padding: "2px 10px",
    borderRadius: 4,
    marginRight: 5,
    fontWeight: 400,
    whiteSpace: "nowrap",
  };

  return (
    <>
      <Stack horizontal styles={stylesRoot}>
        <Stack horizontalAlign="center" verticalAlign="center" horizontal>
          <Link onClick={() => navigate("/")}>
            <Logo style={{ marginLeft: -25, marginTop: 5 }} height={35} />
          </Link>
        </Stack>
        <Stack styles={MiddleStack}></Stack>
        <Stack styles={TopLeftGroupeStack} verticalAlign="center">
          <OFSelector />
          <DefaultButton
            id={buttonId}
            onClick={toggleIsCalloutVisible}
            text={ws?.RS_APP_LABEL || ws?.name}
            styles={{ root: badgeTextStyle, label: { fontWeight: 400 } }}
          />

          {isCalloutVisible && (
            <Callout
              className={styles.callout}
              gapSpace={0}
              target={`#${buttonId}`}
              onDismiss={toggleIsCalloutVisible}
              setInitialFocus
            >
              <VersionTable>
                <VersionLine text="Frontend" version={`v${pkg.version}`} />
                <VersionLine text="Backend" version={`v${ws?.version}`} />
              </VersionTable>
            </Callout>
          )}
          {isAuthenticated &&
            compteUtilisateur?.utilisateurId !== "Anonymous" && (
              <UserPreferencePanel />
            )}
        </Stack>
      </Stack>
    </>
  );
};

export default AppHeader;

const OFSelector = () => {
  const {
    currentAppartenance,
    appartenances,
    setCurrentAppartenance,
    hasProfil,
    isLoading,
  } = useAuth();

  const displayMenu =
    !isLoading &&
    appartenances &&
    appartenances?.length !== 0 &&
    (hasProfil("ADMIN_OF") || hasProfil("DECLARANT_OF"));

  const ofs =
    appartenances
      ?.filter((x) => !x.desactive && !x.enVoieDesactivation)
      .map((item) => ({
        key: item.structureId,
        text: item.raisonSociale || `${item?.siren || "-"}/${item?.nda || "-"}` || "Non renseigné",
        onClick: () => setCurrentAppartenance(item),
      })) || [];

  const ofsDesactive =
    appartenances
      ?.filter((x) => x.desactive)
      .map((item) => ({
        key: item.structureId,
        text: item.raisonSociale || `${item?.siren || "-"}/${item?.nda || "-"}` || "Non renseigné",
        onClick: () => setCurrentAppartenance(item),
      })) || [];

  const ofsEnVoieDesactivation =
    appartenances
      ?.filter((x) => x.enVoieDesactivation)
      .map((item) => ({
        key: item.structureId,
        text: item.raisonSociale || `${item?.siren || "-"}/${item?.nda || "-"}` || "Non renseigné",
        onClick: () => setCurrentAppartenance(item),
      })) || [];
      
  const items = [
    {
      key: "ofsEnVoieDesactivation",
      itemType: ContextualMenuItemType.Section,
      sectionProps: {
        title: "Actif",
        items: ofs,
      },
    },
    {
      key: "ofsEnVoieDesactivation",
      itemType: ContextualMenuItemType.Section,
      sectionProps: {
        title: "En voie de désactivation",
        items: ofsEnVoieDesactivation,
      },
    },
    {
      key: "section3",
      itemType: ContextualMenuItemType.Section,
      sectionProps: {
        title: "Désactivé",
        items: ofsDesactive,
      },
    },
  ];

  return displayMenu ? (
    <CommandButton
      styles={{
        label: { color: DefaultPalette.white },
        menuIcon: { color: DefaultPalette.white },
        root: { width: 250 },
      }}
      text={
        currentAppartenance?.raisonSociale ||
        `${currentAppartenance?.siren || "-"}/${currentAppartenance?.nda || "-"}` ||
        "Non renseigné"
      }
      menuProps={{ items: items }}
    />
  ) : (
    <></>
  );
};
