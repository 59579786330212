import React from "react";

export type useFormState<State> = {
  values: State;
  errors: any;
  onChange?: (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => void;
  setErrors?: (errors: any) => void;
  checkRequired: (name: string, errors: any, message?: string) => boolean;
  addField: (name: string) => void;
  hasField: (name: string) => boolean;
  setValues: (values: State) => void;
  cleanValues: () => object;
  setValue: (name:string, value: any) => void;
  setFieldError: (name:string, value: any, errors: any) => void;
};


export function useForm<State>(initialState: State): useFormState<State> {
  const [values, setValues] = React.useState<State>(initialState);
  const [errors, setErrors] = React.useState<any>({});
  const [fields, setFields] = React.useState<string[]>([]);


  const setValue = (name:string, value: any) => {
    setValues({ ...values, [name]: value });
  }
  const checkRequired = (
    name: string,
    errors: any,
    message?: string
  ): boolean => {
    if(!hasField(name)) {
      errors["rs:Form"] = `Required field ${name} not in form`;
      return false;
    }
    const file : File =  (values as any)["file:"+name];

    if(file) {
      if(file.size < 0) {
        errors[name] = "No empty files allowed";
        return false;
      }

      if(!file.name ) {
        errors[name] = message || "Please provide a value";
        return false;
      }
      return true;
    }

    if (!(values as any)[name]) {
      errors[name] = message || "Merci de renseigner";
      return false;
    }
    const newValue = (values as any)[name] || "";
    if (typeof newValue === "string")
      setValues({ ...values, [name]: newValue.trim() });
    else
      setValues({ ...values, [name]: newValue });

    return true;
  };

  const cleanValues = () => {
    let result: any = {};
    for (let key in values) {
      const objValue = values[key];
      // console.log({key, value: values[key], type: typeof objValue});
      if (typeof objValue === "string") {
        result[key] = objValue.trim();
      } else {
        result[key] = objValue;
      }
    }
    // console.log({values, result});
    return result;
  };

  const setFieldError = (name: string, error: string) => {
    // console.log("errors", errors);
    if (errors[name]) return;
    const newErrors = { ...errors, [name]: error || "" };
    // console.log("newErrors", newErrors);
    setErrors(newErrors);
  };

  const hasField = (name: string) => (fields.indexOf(name) > -1);

  const addField = (name: string) => {
    if(fields.indexOf(name) === -1) {
      fields.push(name);
      setFields(fields);
    }
  }

  const onChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    const { name }: any = event.target;
    if (name) {
      // let cleaned = newValue.trim();
      const newValues = { ...values, [name]: newValue || "" };
      // Clear errors
      if (errors[name])
        setErrors({ ...errors, [name]: undefined });
      setValues(newValues);
    }
  };

  return {
    addField,
    hasField,
    onChange,
    values,
    setValues,
    errors,
    setErrors,
    checkRequired,
    cleanValues,
    setValue,
    setFieldError
  };
}
