import { DefaultPalette, Stack, Text } from "@fluentui/react";

export const AppFooter = () => {
  return (
    <Stack
      styles={{
        root: {
          height: 45,
          backgroundColor: "#230040",
        },
      }}
      horizontal
      horizontalAlign="center"
      verticalAlign="center"
    >
      <Text styles={{ root: { color: DefaultPalette.white } }}>
        © DATADOCK 2023
      </Text>
    </Stack>
  );
};
