/**
* AdminDeclaration Routes
*/

export const AdminDeclarationRoutes = {
	

toAdminDeclarationContextePage: (idOf?: string, type?: string)  : string => idOf ? `/interface/administration/${idOf}/declaration/${type}/contexte` :  '/interface/administration/:idOf/declaration/:type/contexte',

toAdminDeclarationCertificationsPage: (idOf?: string, type?: string)  : string => idOf ? `/interface/administration/${idOf}/declaration/${type}/certifications` :  '/interface/administration/:idOf/declaration/:type/certifications',

};