import React, { CSSProperties, useState } from "react";

import {
  DefaultButton,
  DefaultSpacing,
  Link,
  MessageBar,
  MessageBarType,
  Stack,
  Text,
} from "@fluentui/react";
import { RSErrorType } from "@d4b/rs-core";
import { isError } from "@d4b/api";


type ErrorMessageBarProps = {
  error?: RSErrorType | Error | false | null;
  hideTime?: boolean;
  style?: CSSProperties;
  actions?: JSX.Element;
  extraAction?: any;
  onDismiss?: any;
};

type ErrorEntryProps = {
  error: any;
};
export const ErrorEntry = ({ error }: ErrorEntryProps) => {
  const {
    errorMessage = "Unkown",
    errorType = "Unkown",
    errorMessageDetails = false,
    dataElement,
    ...rest
  } = error;

  const extraStr = JSON.stringify(rest || "");
  const [showDataElemt, setShowDataElement] = useState(false);

  return (
    <Stack style={{ paddingBottom: 8, width: "100%" }}>
      {errorMessage && (
        <Text variant="small">
          {typeof errorMessage == "string"
            ? `${errorMessage} (${errorType})`
            : JSON.stringify(errorMessage)}
        </Text>
      )}
      {errorMessageDetails && (
        <Text style={{ marginLeft: 8 }} variant="smallPlus">
          {typeof errorMessageDetails == "string"
            ? errorMessageDetails
            : JSON.stringify(errorMessageDetails)}
        </Text>
      )}
      {dataElement && (
        <Stack style={{ width: "100%" }}>
          <DefaultButton
            text="Show data"
            onClick={() => setShowDataElement(!showDataElemt)}
          />
          {showDataElemt && (
            <Stack>
              <Text>Data element:</Text>
              <pre>{JSON.stringify(dataElement, null, 2)}</pre>
            </Stack>
          )}
        </Stack>
      )}
      {extraStr && extraStr !== "" && extraStr !== "{}" && (
        <Text style={{ marginLeft: 8 }} variant="xSmall">
          <pre style={{ padding: 0, margin: 0, whiteSpace: "pre-wrap" }}>
            {extraStr}
          </pre>
        </Text>
      )}
    </Stack>
  );
};

type ErrorListProps = {
  errors: any[];
};
export const ErrorList = ({ errors = [] }: ErrorListProps) => {
  if (!errors || errors.length === 0) return null;
  return (
    <Stack
      style={{ marginBottom: DefaultSpacing.l1, width: "100%" }}
      tokens={{ childrenGap: DefaultSpacing.l1 }}
    >
      {errors.map((error: any, i: number) => (
        <ErrorEntry key={i} error={error} />
      ))}
    </Stack>
  );
};

export const ErrorMessageBar = ({
  error = false,
  hideTime = false,
  style,
  actions,
  extraAction,
  onDismiss,
}: ErrorMessageBarProps) => {
  // const { myDetails } = useAppContext();
  const [errorDate] = useState(new Date());
  const [closed, setClosed] = useState(false);
  // Reformat error
  if (typeof error === "string" && error !== "" && !closed) {
    error = { errorMessage: error };
  } else if (!isError(error) || closed) return null;
  const {
    errorFields = false,
    Error,
    GenericErrorList = [],
  } = (error as any) || {};

  if (errorFields) error = { ...error, errorFields: undefined };

  // return <pre>{JSON.stringify(error)}</pre>

  return (
    <MessageBar
      messageBarType={MessageBarType.error}
      isMultiline
      onDismiss={onDismiss || (() => setClosed(true))}
      actions={actions}
      styles={{ root: { ...(style as any) } }}
    >
      <Stack style={{ width: "100%"}}>
        {GenericErrorList.length > 0 && <ErrorList errors={GenericErrorList} />}
        {typeof error === "object" && <ErrorList errors={[error]} />}
        {!error && typeof Error === "object" && <ErrorList errors={Error} />}
        {errorFields && errorFields.length > 0 && (
          <ul>
            {errorFields.map((v: any) => (
              <li key={v}>{v}</li>
            ))}
          </ul>
        )}
        {extraAction && <Link onClick={extraAction}>Reset filters!</Link>}
        {!hideTime && errorDate && (
          <Text variant="tiny">{errorDate.toISOString()}</Text>
        )}
        {/* <Text variant="tiny">{myDetails.Name}</Text> */}
      </Stack>
    </MessageBar>
  );
};
