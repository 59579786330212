import {
  axiosInstance,
  fetchAxios,
  resolveApiUrl,
  useOperation2,
} from "@d4b/api";

import {
  Appartenance,
  AppartenanceProfilType,
  AppartenancesListReply,
  CompteUtilisateur,
} from "lib";
import React, { useContext, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

type AuthContextType = {
  isAuthenticated: boolean;
  isLoading: boolean;
  compteUtilisateur: CompteUtilisateur;
  nomUtilisateur: string;
  error: Error | null;
  setError: React.Dispatch<any>;
  signin: (username: string, password: string, callback: any) => void;
  signout: (_: any) => void;
  refreshCompteUtilisateur: () => void;
  hasProfil: (profil: AppartenanceProfilType) => boolean;
  currentAppartenance?: Appartenance;
  setCurrentAppartenance: React.Dispatch<
    React.SetStateAction<Appartenance | undefined>
  >;
  appartenances?: Appartenance[];
  refreshAppartenancesLink: () => void;
};

export const ACCOUNT_ANONYMOUS: CompteUtilisateur = {
  utilisateurId: "Anonymous",
  civilite: "Anonymous",
  email: "Anonymous",
  prenom: "Anonymous",
  nomDeFamille: "Anonymous",
};

const AuthContext = React.createContext<AuthContextType>({
  error: null,
  setError: (_: any) => {},
  isAuthenticated: false,
  isLoading: false,
  compteUtilisateur: ACCOUNT_ANONYMOUS,
  nomUtilisateur: "",
  signin: (_user: string, _pass: string, _: any) => {},
  signout: (_: any) => {},
  refreshCompteUtilisateur: () => {},
  hasProfil: (_: AppartenanceProfilType) => false,
  currentAppartenance: undefined,
  setCurrentAppartenance: (_: any) => {},
  appartenances: undefined,
  refreshAppartenancesLink: () => {},
});

export const ProvideAuth = ({ children }: any) => {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext<AuthContextType>(AuthContext);

export const getCSRFCookieRequest = async () => {
  await axiosInstance.get(resolveApiUrl() + "/csrf-cookie");
};

export const useProvideAuth = () => {
  const queryClient = useQueryClient();

  const {
    data: currentAccount = ACCOUNT_ANONYMOUS,
    isLoading: isLoadingDetails,
  } = useQuery<CompteUtilisateur, Error>(
    "CompteUtilisateur:Actuel",
    () =>
      fetchAxios<any>(
        axiosInstance,
        resolveApiUrl() + "/compte-actuel",
        "CompteUtilisateurDetails"
      ),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      retry: 1,
      retryDelay: 0,
    }
  );

  const [currentAppartenance, setCurrentAppartenance] =
    useState<Appartenance | undefined>();

  const {
    data: appartenancesListReply,
    isLoading: isLoadingAppartenances,
    refreshLink: refreshAppartenancesLink,
  } = useOperation2<AppartenancesListReply>({
    de: currentAccount,
    operation: { link: "CompteUtilisateur:Appartenances" },
    key: currentAccount?.utilisateurId,
    disabled: isLoadingDetails || !currentAccount?.utilisateurId,
  });

  // const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>();

  const hasProfil = (profil: AppartenanceProfilType) => {
    if (
      profil === "ADMIN_DDOCK" &&
      currentAccount?.utilisateurType === "DataDock"
    )
      return true;
    const profilFound = appartenancesListReply?.Appartenances?.find(
      (x) => x.profil === profil
    );

    return !!profilFound?.utilisateurAppartenanceId;
  };

  useEffect(() => {
    if (currentAppartenance || !appartenancesListReply?.Appartenances) {
      // NOOP
      return;
    }
    setCurrentAppartenance(appartenancesListReply.Appartenances[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appartenancesListReply?.Appartenances]);

  const nomUtilisateur = currentAccount
    ? (currentAccount?.civilite === "TITLE_MALE" ? "M." : "Mme.") +
      " " +
      currentAccount.prenom +
      " " +
      currentAccount.nomDeFamille
    : "";

  const mutations = {
    Login: useMutation(loginRequest, {
      onSuccess: () => {
        queryClient.invalidateQueries("CompteUtilisateur:Actuel");
      },
      onError: (error: any) => {
        setError({ message: error.response?.data?.errorMessage });
        return;
      },
    }),
    Logout: useMutation(logoutRequest, {
      onSuccess: () => {
        setCurrentAppartenance(undefined);
        queryClient.removeQueries("CompteUtilisateur:Actuel");
      },
      onError: (error: any) => {
        setError({ message: error.response?.data?.errorMessage });
        return;
      },
    }),
  };

  return {
    error,
    setError,
    hasProfil,
    refreshCompteUtilisateur: () =>
      queryClient.invalidateQueries(["CompteUtilisateur:Actuel"]),
    compteUtilisateur: currentAccount || ACCOUNT_ANONYMOUS,
    nomUtilisateur,
    isLoading:
      mutations.Login.isLoading ||
      mutations.Logout.isLoading ||
      isLoadingDetails ||
      isLoadingAppartenances,
    isAuthenticated:
      currentAccount !== undefined &&
      currentAccount?.utilisateurId !== ACCOUNT_ANONYMOUS.utilisateurId,
    appartenances: appartenancesListReply?.Appartenances,
    currentAppartenance: currentAppartenance,
    setCurrentAppartenance: setCurrentAppartenance,
    refreshAppartenancesLink: refreshAppartenancesLink,
    signin: async (username: string, password: string, cb: any) => {
      await mutations.Login.mutate({ email: username, password });
    },
    signout: async (cb: any) => {
      await mutations.Logout.mutate();
    },
  };
};
export const getCSRFCookieRequestHeader = async () => {
  await getCSRFCookieRequest();

  const value = `; ${document.cookie}`;
  const parts = value.split(`; XSRF-TOKEN=`) || [];

  let token;
  if (parts.length === 2) {
    token = parts.pop()?.split(";")?.shift();
  }
  if (!token) token = "";
  return {
    headers: {
      "X-XSRF-TOKEN": decodeURIComponent(token),
    },
  };
};

export const loginRequest = async (user: any) => {
  await getCSRFCookieRequest();
  const response = await axiosInstance.post(
    resolveApiUrl() + "/login",
    user
  );
  return response.data;
};

export const logoutRequest = async () => {
  const response = await axiosInstance.post(resolveApiUrl() + "/logout", null);
  return response.data;
};
