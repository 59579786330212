/**
* AdminTraitements Routes
*/

export const AdminTraitementsRoutes = {
	

toAdminTraitementsPage: ()  : string =>  '/interface/traitements',

toAdminGestionBaseSIRETPage: ()  : string =>  '/interface/traitements/base-siret',

toAdminGestionIntegrationDGEFPPage: ()  : string =>  '/interface/traitements/integration-dgefp',

toAdminGestionIntegrationOrganismesFinancesPage: ()  : string =>  '/interface/traitements/organismes-finances',

toAdminGestionNombreMaxResultatExportPage: ()  : string =>  '/interface/traitements/export-recherche',

toAdminGestionOFStagnantPage: ()  : string =>  '/interface/traitements/of-stagnant',

toAdminTraitementDateReferencabilitePage: ()  : string =>  '/interface/traitements/date-referencabilite',

toAdminSuppressionUtilisateurExpireePage: ()  : string =>  '/interface/traitements/expiration-compte',

toAdminExportOfsPage: ()  : string =>  '/interface/traitements/export-ofs',

toAdminExportReferencementsPage: ()  : string =>  '/interface/traitements/export-referencements',

toAdminExportSiretsPage: ()  : string =>  '/interface/traitements/export-sirets',

toAdminCertificationsExpirationsPage: ()  : string =>  '/interface/traitements/expiration-certification',

toAdminExpirationsMotsDePassePage: ()  : string =>  '/interface/traitements/expiration-mdp',

toAdminGestionCompteFinanceurActifPage: ()  : string =>  '/interface/traitements/compte-financeur',

toAdminTraitementsEmailsPage: ()  : string =>  '/interface/traitements/emails',

toAdminTraitementsAutoCreationOFsPage: ()  : string =>  '/interface/traitements/auto-creation-ofs',

toAdminTraitementsSuppressionAutoInertiePage: ()  : string =>  '/interface/traitements/suppression-auto-inertie',

toAdminTraitementsSuppressionStatutSuspenduPage: ()  : string =>  '/interface/traitements/suppression-statut-suspendu',

};