import {
  CommandButton,
  ILabelProps,
  ITextProps,
  Label,
  Link,
  NeutralColors,
  Shimmer,
  Stack,
  Text,
} from '@fluentui/react';
import { fromNowDate } from '@d4b/fluent-ui';
//import { humanizeDateToString } from "lib/utils/humanizeDate";
import { ReactNode } from 'react';
import { DateDisplay } from '../Text';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { useId } from '@fluentui/react-hooks';
import { humanizeDateToString } from '@d4b/fluent-ui/utils/humanizeDate';
import { useNavigate } from 'react-router-dom';
import ReactJson from 'react-json-view';

export type LabeledTextProps = {
  style?: any;
  beforeText?: ReactNode;
  text?: string | undefined;
  afterText?: ReactNode;
  label: string;
  labelProps?: ILabelProps;
  textProps?: ITextProps;
  isUrl?: boolean;
  horizontal?: boolean;
  nowrap?: boolean;
  block?: boolean;
  extra?: React.ReactNode;
  isDate?: boolean;
  showSeconds?: boolean;
  isDateHumanized?: boolean;
  isFromNowDate?: boolean;
  usePreTag?: boolean;
  isLoading?: boolean;
  customAction?: any;
  link?: string;
  noPaddingLeft?: boolean;
};

export const LabeledText = ({
  beforeText,
  afterText,
  block,
  nowrap,
  isDate = false,
  isDateHumanized = false,
  isFromNowDate = false,
  showSeconds = false,
  text = '-',
  label,
  labelProps,
  textProps,
  style = {},
  isUrl,
  horizontal,
  extra,
  usePreTag = false,
  isLoading = false,
  customAction,
  noPaddingLeft = false,
  link,
}: LabeledTextProps) => {
  const navigate = useNavigate();
  return (
    <Stack
      horizontal={horizontal}
      verticalAlign={horizontal ? 'center' : undefined}
      style={{ paddingBottom: 5, minHeight: 40, ...style }}
    >
      <Label style={{ marginRight: 10 }} {...labelProps}>
        {label}

        {customAction && (
          <CustomActionButton
            customAction={customAction}
            tooltipContent={`Browse objects where ${label} = ${text}`}
          />
        )}
      </Label>
      {isLoading && <Shimmer style={{ minHeight: 24 }} width="90%" />}
      {!isLoading && (
        <Text
          nowrap={nowrap}
          block={block}
          style={{
            marginRight: 10,
            paddingBottom: 5,
            paddingLeft: noPaddingLeft ? 0 : 10,
            color: NeutralColors.gray130,
          }}
          {...textProps}
        >
          {beforeText && (
            <span style={{ marginRight: beforeText ? 5 : 0 }}>
              {beforeText}
            </span>
          )}
          {isUrl && (
            <Link href={text} target="_blank">
              {text}
            </Link>
          )}
          {link && <Link onClick={() => navigate(link)}>{text}</Link>}
          {usePreTag && (
            <pre
              style={{
                overflow: 'scroll',
                // whiteSpace: "pre-wrap"
              }}
            >
              {typeof text === 'object' ? (
                <ReactJson name={null} iconStyle="circle" src={text} />
              ) : (
                text
              )}
            </pre>
          )}
          {!isUrl &&
            !isDate &&
            !usePreTag &&
            !isDateHumanized &&
            !isFromNowDate &&
            !link &&
            (text || "-")}
          {isDate &&
            (text ? (
              <DateDisplay showSeconds={showSeconds} dateStr={text} />
            ) : (
              '-'
            ))}
          {isDateHumanized &&
            (text === '' || text === 'Z'
              ? '-'
              : humanizeDateToString(
                  text + 'Z',
                  isDate ? ' (' : '',
                  isDate ? ' ago)' : ' ago'
                ))}

          {isFromNowDate &&
            (text === '' || text === 'Z'
              ? '-'
              : (isDate ? ' (' : '') +
                fromNowDate(text + 'Z', { suffix: true })) +
              (isDate ? ')' : '')}

          {afterText && (
            <span style={{ marginLeft: text === '' ? 0 : 5 }}>{afterText}</span>
          )}
        </Text>
      )}
      {!isLoading && <>{extra}</>}
    </Stack>
  );
};

type CustomActionButtonProps = {
  customAction: any;
  tooltipContent: string;
};

const CustomActionButton = ({
  customAction,
  tooltipContent,
}: CustomActionButtonProps) => {
  const tooltipId = useId('tooltip');
  return (
    <TooltipHost
      content={tooltipContent}
      id={tooltipId}
      calloutProps={{ gapSpace: 0 }}
      styles={{ root: { display: 'inline-block' } }}
    >
      <CommandButton
        styles={{ icon: { fontSize: 14 } }}
        onClick={customAction}
        text="Filter"
        iconProps={{ iconName: 'Filter' }}
      />
    </TooltipHost>
  );
};
