import { useQuery, useQueryClient } from "react-query";
import { getLinks, useAxios } from ".";
import { LinkEntry } from "../../rs-core";
import { fetchAxios } from "./fetchAxios";
import { OperationEntry } from "./Operation";
import { UrlParams } from "./RSTypes";

async function sendError(message: string, messageDetails: string, de: any, links: string[]): Promise<any> {
  return new Promise((resolve) =>
    resolve(({
      errorMessage: message,
      errorMessageDetails: messageDetails,
      errorFields: links,
      dataElement: de
    }) as any)
  )
}


export type useOperationOptions = {
  handleInvalidOperation?: (de: any, operation: OperationEntry) => boolean;
};

export function useOperation<T>(
  de: any,
  operation: OperationEntry,
  key?: string | undefined,
  searchParams?: UrlParams,
  options?: useOperationOptions,
  disabled?: boolean
) {
  const axios = useAxios();
  const appQueryClient = useQueryClient();
  const { link: linkName, dataElement: dataElementName = "" } = operation;
  if (!linkName && !operation.isDisabled) {
    throw new Error("An operation must have a link");
  }

  const links = getLinks(de);
  const link: LinkEntry = links[linkName];
  let invalidLink = false;

  if (de && links && !link && !operation.isDisabled) {
    if (!options?.handleInvalidOperation) {

      // throw new Error(
      //   `Link '${linkName}' not found on data element:\n ${JSON.stringify(de,null,2)}`
      // );
      invalidLink = true;
    }
    if (!invalidLink && options?.handleInvalidOperation && !options?.handleInvalidOperation(de, operation)) {
      throw new Error("Link " + linkName + " not found on operation ");
    }
  }

  let url = link?.url;
  let searchParam = new URLSearchParams(searchParams);
  if (url && searchParam?.toString()) {
    const linkUrl = new URL(link.url);
    searchParam.sort();
    if (linkUrl.search !== "") {
      throw Error("TODO: Merge seach params");
    }
    url = `${link.url}?${searchParam.toString()}`;
  }

  return {
    ...useQuery<T>(
      [linkName, key, searchParam?.toString()],
      () => invalidLink ? sendError(`Link ${linkName} not found on data element`, "Available links", de, Object.keys(links)) : fetchAxios<any>(axios, url, dataElementName, operation.isList || false),
      { enabled: invalidLink || (!disabled && !!link?.url && !operation.isDisabled), staleTime: 100 }
    ),
    refreshLink: () => appQueryClient.invalidateQueries([operation.link])
  };
}

type useOperation2Options = {
  de: any,
  operation: OperationEntry,
  key?: string | undefined,
  searchParams?: UrlParams,
  options?: useOperationOptions,
  disabled?: boolean
}
export function useOperation2<T>({ de, operation, key, searchParams, options, disabled }: useOperation2Options) {
  return useOperation<T>(de, operation, key, searchParams, options, disabled);
}
/*

export type useOperationOptions = {
  handleInvalidOperation?: (de: any, operation: OperationEntry) => boolean;
};

export function useOperation<T>(
  de: any,
  operation: OperationEntry,
  key?: string | undefined,
  searchParams?: UrlParams,
  options?: useOperationOptions,
  disabled?: boolean
) {
  const axios = useAxios();
  const appQueryClient = useQueryClient();
  const { link: linkName, dataElement: dataElementName = "" } = operation;
  if (!linkName && !operation.isDisabled) {
    throw new Error("An operation must have a link");
  }

  const links = getLinks(de);
  const link: LinkEntry = links[linkName];

  if (de && links && !link && !operation.isDisabled) {
    if (!options?.handleInvalidOperation) {
      throw new Error(
        "Link " +
          linkName +
          " not found on de with keys (" +
          JSON.stringify(Object.keys(de)) +
          "). \n" +
          "Valid links are:\n" +
          JSON.stringify(links, null, 2)
      );
    }
    if (!options.handleInvalidOperation(de, operation)) {
      throw new Error("Link " + linkName + " not found on operation ");
    }
  }

  let url = link?.url;
  let searchParam = new URLSearchParams(searchParams);
  if (url && searchParam?.toString()) {
    const linkUrl = new URL(link.url);
    searchParam.sort();
    if (linkUrl.search !== "") {
      throw Error("TODO: Merge seach params");
    }
    url = `${link.url}?${searchParam.toString()}`;
  }

  return {
    ...useQuery<T>(
      [linkName, key, searchParam?.toString()],
      () =>
        fetchAxios<any>(axios, url, dataElementName, operation.isList || false),
      {
        enabled: !disabled && !!link?.url && !operation.isDisabled,
        staleTime: 100,
        retry: 1,
        retryDelay: 3000,
      }
    ),
    refreshLink: () => appQueryClient.invalidateQueries([operation.link]),
  };
}

*/