import { UseUrlParams } from "@d4b/fluent-ui";
import { RSPagination } from "@d4b/rs-core";
import {
  DefaultButton,
  DefaultPalette,
  DefaultSpacing,
  NeutralColors,
  Stack,
  Text,
} from "@fluentui/react";
import { useState } from "react";

const styleOnHovered = {
  backgroundColor: DefaultPalette.blue,
  color: DefaultPalette.white,
  borderColor: DefaultPalette.blue,
};

const prevNextStyle = {
  color: DefaultPalette.blue,
  minWidth: DefaultSpacing.s1,
  borderColor: NeutralColors.gray60,
};

type PagerProps = {
  isLoading?: boolean;
  pagination?: RSPagination;
  filterParams?: UseUrlParams;
  invalidate?: (reload?: boolean) => void;
  emptyMessage?: string;
};

export const Pagination = ({
  isLoading,
  pagination,
  filterParams,
  invalidate,
  emptyMessage,
}: PagerProps) => {
  const {
    page = 1,
    pageCount = 10,
    pageSize,
    hasNextPage,
    hasPrevPage,
    total = 0,
  } = pagination || {};

  const pageNumberLimit = 3;
  const [maxPageLimit, setMaxPageLimit] = useState(3);
  const [minPageLimit, setMinPageLimit] = useState(0);

  let totalPagesArray = new Array(total);
  for (let i = 1; i <= pageCount; i++) {
    totalPagesArray.push(i);
  }

  const pageNumbers = totalPagesArray.map((value) => {
    if (value <= maxPageLimit && value > minPageLimit && total > 0)
      return (
        <DefaultButton
          disabled={isLoading}
          key={value}
          text={value}
          styles={{
            root: {
              minWidth: DefaultSpacing.s1,
              borderRight: 0,
              padding: DefaultSpacing.s1,
              borderColor:
                page === value ? DefaultPalette.blue : NeutralColors.gray60,
              backgroundColor:
                page === value ? DefaultPalette.blue : DefaultPalette.white,
              color:
                page === value ? DefaultPalette.white : DefaultPalette.blue,
            },
            rootHovered: styleOnHovered,
          }}
          onClick={() => filterParams?.setCurrentPage(value)}
        />
      );
    else return null;
  });

  let pageIncrementEllipses = null;
  if (pageCount > maxPageLimit) {
    pageIncrementEllipses = (
      <DefaultButton
        disabled={isLoading}
        style={{
          minWidth: DefaultSpacing.s1,
          borderColor: NeutralColors.gray60,
        }}
      >
        &hellip;
      </DefaultButton>
    );
  }

  let pageDecremenEllipses = null;
  if (minPageLimit >= 1) {
    pageDecremenEllipses = (
      <DefaultButton
        disabled={isLoading}
        style={{
          minWidth: DefaultSpacing.s1,
          borderColor: NeutralColors.gray60,
        }}
      >
        &hellip;
      </DefaultButton>
    );
  }

  // First button
  const firstBtn = (
    <DefaultButton
      disabled={page===1 || isLoading}
      onClick={() => {
        filterParams?.setCurrentPage(1);
      }}
      styles={{
        root: prevNextStyle,
        rootHovered: styleOnHovered,
      }}
      text="<<"
    />
  );

  // previous button
  const prevBtn = (
    <DefaultButton
      disabled={!hasPrevPage || isLoading}
      onClick={() => {
        filterParams?.setCurrentPage(page - 1);
        if ((page - 1) % pageNumberLimit === 0) {
          setMaxPageLimit(maxPageLimit - 3);
          setMinPageLimit(minPageLimit - 3);
        }
      }}
      styles={{
        root: prevNextStyle,
        rootHovered: styleOnHovered,
      }}
      text="<"
    />
  );

  // Next button
  const nextBtn = (
    <DefaultButton
      disabled={!hasNextPage || isLoading}
      onClick={() => {
        if (page + 1 > maxPageLimit) {
          setMaxPageLimit(maxPageLimit + pageNumberLimit);
          setMinPageLimit(minPageLimit + pageNumberLimit);
        }
        filterParams?.setCurrentPage(page + 1);
      }}
      styles={{
        root: prevNextStyle,
        rootHovered: styleOnHovered,
      }}
      text=">"
    />
  );

  // Last button
  const lastBtn = (
    <DefaultButton
      disabled={page===pageCount || isLoading}
      onClick={() => {
        filterParams?.setCurrentPage(pageCount);
      }}
      styles={{
        root: prevNextStyle,
        rootHovered: styleOnHovered,
      }}
      text=">>"
    />
  );

  const totalMessage =  (total === 0 && emptyMessage) ? emptyMessage : `${total} Résultat(s) trouvé(s)`;

  return (
    <Stack
      styles={{ root: { marginTop: DefaultSpacing.l1 } }}
      tokens={{ childrenGap: DefaultSpacing.l1 }}
    >
      <Stack horizontal horizontalAlign="space-between">
        <Text>
          {isLoading ? "Chargement en cours..." : totalMessage}
        </Text>
        <Stack
          horizontal
          verticalAlign="center"
          tokens={{ childrenGap: DefaultSpacing.s1 }}
        >
          <Text>Nombre de résultats par page:</Text>
          <select
            value={pageSize}
            onChange={(e) => {
              filterParams?.setCurrentPageSize(parseInt(e.target.value));
              filterParams?.setCurrentPage(1);
              setMaxPageLimit(10);
              setMinPageLimit(0);
            }}
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="30">30</option>
            <option value="100">100</option>
          </select>
        </Stack>
      </Stack>

      <Stack horizontal>
        {firstBtn}
        {prevBtn}
        {pageDecremenEllipses}
        {pageNumbers}
        {pageIncrementEllipses}
        {nextBtn}
        {lastBtn}
        {invalidate && <RefreshButton invalidate={invalidate} />}
      </Stack>
    </Stack>
  );
};

type RefreshButtonProps = {
  invalidate: () => void;
};
const RefreshButton = ({ invalidate }: RefreshButtonProps) => {
  return (
    <DefaultButton
      onClick={() => invalidate()}
      styles={{
        root: {
          backgroundColor: DefaultPalette.blue,
          color: DefaultPalette.white,
          minWidth: DefaultSpacing.s1,
          padding: DefaultSpacing.s1,
          borderColor: DefaultPalette.blue,
        },
        rootHovered: {
          color: DefaultPalette.blue,
          borderColor: NeutralColors.gray60,
        },
      }}
      iconProps={{ iconName: "Refresh" }}
    />
  );
};
