import { DefaultPalette, DefaultSpacing, Stack, Text } from "@fluentui/react";
import React, { Children, ReactNode } from "react";

type ContextStackProps = {
  title: string;
  children?: ReactNode | ReactNode[];
  rootStyles?: object;
  status?: string;
};
export const ContextStack = ({
  title,
  children,
  rootStyles,
  status,
}: ContextStackProps) => {
  return (
    <Stack
      styles={{
        root: {
          backgroundColor: DefaultPalette.neutralLight,
          padding: DefaultSpacing.m,
          borderLeft: "4px solid",
          borderLeftColor: DefaultPalette.themePrimary,
          marginBottom: DefaultSpacing.l1,
          ...rootStyles,
        },
      }}
      tokens={{ childrenGap: DefaultSpacing.m }}
    >
      <Stack horizontal horizontalAlign="space-between">
        <Text styles={{ root: { fontWeight: "bold" } }} variant="large">
          {title}
        </Text>
        {status && (
          <Text
            styles={{ root: { fontWeight: "bold", marginTop: 3 } }}
            variant="medium"
          >
            {status}
          </Text>
        )}
      </Stack>

      {Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child);
        }
      })}
    </Stack>
  );
};
