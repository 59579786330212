import {
  Breadcrumb,
  IBreadcrumbItem,
  IShimmerElement,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Shimmer,
  ShimmerElementType,
  Stack,
  Text,
  useTheme,
} from "@fluentui/react";
import PageMetaDataViewer from "@d4b/fluent-ui/components/Headers/PageMetaDataViewer";
import { PageMetaData } from "lib/meta-utils";
import React, { MouseEventHandler, useState } from "react";
import { Helmet } from "react-helmet";
import { FlashMessageBar } from "..";
import {
  PrimaryCommandeBar,
  PrimaryCommandeBarProps,
} from "./PrimaryCommandeBar";
import {
  SecondaryCommandeBar,
  SecondaryCommandeBarProps,
} from "./SecondaryCommandeBar";

const stackItemStyles: IStackItemStyles = {
  root: {
    height: 50,
    overflow: "hidden",
  },
};

const outerStackTokens: IStackTokens = { childrenGap: 5 };

const innerStackTokens: IStackTokens = {
  childrenGap: 10,
  padding: "10px 0px 0px 0px",
};
const stackStyles: IStackStyles = {
  root: {
    overflow: "hidden",
    width: `100%`,
  },
};

export type ActionButtonItem = {
  text?: string;
  disabled?: boolean;
  iconName: string;
  onClick: MouseEventHandler<any> | undefined;
  key: string;
};

type SubHeaderProps = {
  breadCrumbs?: IBreadcrumbItem[];
  pageTitle?: React.ReactNode;
  browserTitle?: string;
  extraButton?: React.ReactNode;
  titlePostfixIcon?: React.ReactNode;
  titleIcon?: React.ReactNode;
  instructions?: React.ReactNode;
  primaryCommandeBarProps?: PrimaryCommandeBarProps;
  secondaryCommandeBarProps?: SecondaryCommandeBarProps;
  isShimmerActive?: boolean;
  pageUid?: string;
  relativePath?: string;
  pageMetaData?: PageMetaData;
};

export const PageHeader = ({
  breadCrumbs: breadCrumbsDefault = [],
  titleIcon,
  titlePostfixIcon,
  pageTitle: pageTitleDefault = "Untitled Page",
  browserTitle,
  extraButton,
  instructions,
  secondaryCommandeBarProps,
  isShimmerActive = false,
  pageUid,
  relativePath,
  pageMetaData,
}: SubHeaderProps) => {
  const { semanticColors } = useTheme();
   const [flashMessage, setFlashMessage] = useState<string>("");

  const shimmerWithElementtop: IShimmerElement[] = [
    { type: ShimmerElementType.line, height: 37, width: "60%" },
    { type: ShimmerElementType.gap, width: "40%" },
  ];
  const shimmerWithElementTitle: IShimmerElement[] = [
    { type: ShimmerElementType.line, height: 32, width: "20%" },
    { type: ShimmerElementType.gap, width: "80%" },
  ];
  const shimmerWithElementInstruction: IShimmerElement[] = [
    { type: ShimmerElementType.line, height: 19, width: "30%" },
    { type: ShimmerElementType.gap, width: "70%" },
  ];

  const pageTitle = pageMetaData?.pageTitle || pageTitleDefault;
  const breadCrumbs = pageMetaData?.breadCrumbs || breadCrumbsDefault;
  const showToolBar = Object.keys(secondaryCommandeBarProps || {}).length || extraButton;

  const title =
    browserTitle && browserTitle !== ""
      ? browserTitle
      : typeof pageTitle === "string"
      ? (pageTitle as string)
      : undefined;

  return (
    <>
      <Helmet title={title} />
      {breadCrumbs &&
        (isShimmerActive ? (
          <Shimmer
            styles={{
              root: {
                marginTop: 0,
                position: "fixed",
                zIndex: 1,
                width: "100%",
              },
            }}
            shimmerElements={shimmerWithElementtop}
          />
        ) : (
          <Breadcrumb
            styles={{
              root: {
                marginTop: 0,
                position: "fixed",
                backgroundColor: semanticColors.menuBackground,
                color: semanticColors.menuItemText,
                zIndex: 1,
                width: "100%",
              },
              itemLink: { fontSize: 14 },
            }}
            items={breadCrumbs}
          />
        ))}

      <Stack style={{ margin: 10, marginTop: 48 }}>
        <Stack horizontal verticalAlign="center">
          {titleIcon && titleIcon}

          {isShimmerActive ? (
            <Shimmer
              style={{ display: "contents" }}
              shimmerElements={shimmerWithElementTitle}
            />
          ) : (
            <>
              <Text
                style={{ marginLeft: titleIcon ? 10 : 0, fontWeight: 600 }}
                variant="xLargePlus"
              >
                {pageTitle}
              </Text>
            </>
          )}

          {titlePostfixIcon && (
            <Text style={{ marginLeft: 10 }}>{titlePostfixIcon}</Text>
          )}
        </Stack>
        {(instructions || pageMetaData?.instructions) && (
          <Stack style={{ marginTop: 20 }}>
            {isShimmerActive ? (
              <Shimmer
                style={{ display: "contents" }}
                shimmerElements={shimmerWithElementInstruction}
              />
            ) : (
              instructions || pageMetaData?.instructions
            )}
          </Stack>
        )}

        <Stack
          style={{ borderBottom: "1px solid #ddd", paddingBottom: 25 }}
        ></Stack>
        {showToolBar && (
          <Stack tokens={outerStackTokens}>
            <Stack horizontal styles={stackStyles} tokens={innerStackTokens}>
              {extraButton && (
                <PrimaryCommandeBar
                  stackItemStyles={stackItemStyles}
                  buttons={extraButton}
                />
              )}
              <SecondaryCommandeBar
                {...secondaryCommandeBarProps}
                invalidateProps={
                  secondaryCommandeBarProps?.invalidateProps ||
                  (pageMetaData && pageMetaData.operationsToInvalidate)
                }
              />
            </Stack>
          </Stack>
        )}
        <FlashMessageBar flashMessage={flashMessage} setFlashMessage={setFlashMessage} />
        <PageMetaDataViewer
          pageMetaData={pageMetaData}
          pageUid={pageUid}
          componentPath={relativePath}
        />
      </Stack>
    </>
  );
};
