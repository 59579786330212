import { PageTitle } from "@d4b/fluent-ui";
import { DefaultButton, DefaultSpacing, Stack, Text } from "@fluentui/react";
import React, { ErrorInfo, ReactNode } from "react";

interface Props {
  children: ReactNode;
}
interface State {
  errorMessage?: string;
}

export class ErrorBoundary extends React.Component<Props, State> {
  public state: State = {
    errorMessage: "",
  };

  static getDerivedStateFromError(error: Error) {
    return { errorMessage: error.toString() };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
     this.logErrorToServices(error.toString(), errorInfo.componentStack);
  }

  // A fake logging service.
  logErrorToServices = console.log;

  public onClearErrorClick() {
    this.setState({ errorMessage: undefined});
  }

  render() {
    
    if (this.state.errorMessage) {
      return (
        <Stack tokens={{ childrenGap: DefaultSpacing.l1 }}>
          <PageTitle pageTitle="Whoops.... that's an error" />
          <DefaultButton text="Clear error" style={{ maxWidth: 150}} onClick={() => this.onClearErrorClick()} />
          <Text>Have chat with your developers and show them this:</Text>
          <Stack styles={{ root: { padding: DefaultSpacing.m } }}>
            <pre
              style={{
                display: "block",
                fontSize: "smaller",
                fontFamily: "monospace",
                whiteSpace: "pre",
                margin: "1em 0",
              }}
            >
              {this.state.errorMessage}
            </pre>
          </Stack>
        </Stack>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
