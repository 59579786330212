const MIN = 60e3;
const HOUR = MIN * 60;
const DAY = HOUR * 24;
const YEAR = DAY * 365;
const MONTH = DAY * 30;


export const fromNowDate = ( date: string, opts?: any ) => {

  if(date === "" || date === "Z") return "";

  opts = opts || {};

  var del = new Date(date).getTime() - Date.now();
  var abs = Math.abs(del);

  if (abs < MIN) return "just now";

  var periods: any = {
    year: abs / YEAR,
    month: (abs % YEAR) / MONTH,
    day: (abs % MONTH) / DAY,
    hour: (abs % DAY) / HOUR,
    minute: (abs % HOUR) / MIN,
  };

  var k,
    val,
    keep = [],
    max = opts.max || MIN; // large number

  for (k in periods) {
    if (keep.length < max) {
      val = Math.floor(periods[k]);
      if (val || opts.zero) {
        keep.push(val + " " + (val === 1 ? k : k + "s"));
      }
    }
  }

  k = keep.length; // reuse
  max = ", "; // reuse

  if (k > 1 && opts.and) {
    if (k === 2) max = " ";
    keep[--k] = "and " + keep[k];
  }

  val = keep.join(max); // reuse

  if (opts.suffix) {
    val += del < 0 ? " ago" : " from now";
  }

  return val;
};
