import { RSDetailsReply, RSListReply } from "@d4b/api";
import { ErrorMessageBar } from "@d4b/fluent-ui";
import { DefaultSpacing, Stack } from "@fluentui/react";
import { CSSProperties } from "react";
import ErrorBoundary from "./ErrorBoundary";

type ShowListErrorProps = {
  component?: any;
  margin?: boolean;
  small?: boolean;
  rsReply?: RSDetailsReply | RSListReply;
  dumpReply?: boolean;
  children?: React.ReactNode | React.ReactNode[];
  fallback?: React.ReactNode | React.ReactNode[];
};

export const ShowListError = ({
  component,
  margin,
  small,
  rsReply,
  children,
  dumpReply,
  fallback,
}: ShowListErrorProps) => {
  let style: CSSProperties = { margin: margin ? DefaultSpacing.s1 : 0 };

  return (
    <ErrorBoundary>
      <Stack style={style}>
        {!small && <ErrorMessageBar error={rsReply} />}
        {!rsReply && fallback}
        {dumpReply && <pre>{JSON.stringify(rsReply, null, 2)}</pre>}
        {children}
      </Stack>
    </ErrorBoundary>
  );
};
