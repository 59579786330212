/**
* Commentaires Routes
*/

export const CommentairesRoutes = {
	

toCommentaireOfListePage: (idOf?: string)  : string => idOf ? `/interface/fiche-of/${idOf}/commentaires` :  '/interface/fiche-of/:idOf/commentaires',

toCommentaireOfAjouterPage: (idOf?: string)  : string => idOf ? `/interface/fiche-of/${idOf}/commentaires/ajouter` :  '/interface/fiche-of/:idOf/commentaires/ajouter',

toCommentaireOfDetailsPage: (idOf?: string, id_commentaire?: string)  : string => idOf ? `/interface/fiche-of/${idOf}/commentaires/${id_commentaire}/detail` :  '/interface/fiche-of/:idOf/commentaires/:id_commentaire/detail',

};