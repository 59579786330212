import { Stack, Text } from "@fluentui/react";
import React, { ReactNode } from "react";
import { Children } from "react";

type SectionProps = {
  sectionTitle?: string;
  /** The contents to be displayed */
  sectionBody?: React.ReactNode | string;
  showTopBorder?: boolean;
  children?: ReactNode | ReactNode[];
};
export const Section = ({
  sectionTitle,
  sectionBody,
  showTopBorder,
  children
}: SectionProps) => {
  return (
    <Stack
      styles={{
        root: {
          borderTop: showTopBorder ? "1px solid #000" : "",
          marginTop: 10,
          paddingTop: 10,
          width: "100%",
          paddingLeft: 10,
        },
      }}
    >
      <Text
        style={{
          fontSize: 14,
          fontWeight: 600,
          color: "black",
          marginBottom: 15,
        }}
      >
        {sectionTitle && sectionTitle}
      </Text>
      <Stack>
        {sectionBody && sectionBody}
        {Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child);
          }
        })}
      </Stack>
    </Stack>
  );
};
