import { DefaultSpacing } from "@fluentui/react";
import { CSSProperties } from "react";

type AlertMessageProps = {
  message: string;
  style?: CSSProperties;
};
export const AlertMessage = ({ message, style }: AlertMessageProps) => {
  const taostStyle = {
    backgroundColor: "#fff",
    backgroundImage:
      "repeating-linear-gradient(-45deg,transparent,transparent 5px,rgba(243,214,214,.4) 0,rgba(243,214,214,.4) 20px)",
    borderRadius: 10,
    width: "100%",
    paddingLeft: DefaultSpacing.l1,
    paddingRight: DefaultSpacing.l1,
    marginTop: DefaultSpacing.m,
    marginBottom: DefaultSpacing.m,
    maxWidth: 640,
    ...style,
  };
  return (
    <div style={taostStyle}>
      <p style={{ textAlign: "center", fontWeight: "bold" }}>
        {message.includes("<br/>")
          ? message.split("<br/>").map((messageItem, index) => (
              <>
                {messageItem}
                {index + 1 !== message.split("<br/>").length && <br />}
              </>
            ))
          : message}
      </p>
    </div>
  );
};
