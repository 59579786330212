import { PageMetaData } from "lib";
import { useEffect, useState } from "react";

export const useFoundMetaData = (pageId?: string) => {
  const application = require("application.json");
  const [foundMetaData, setFoundMetaDat] = useState<PageMetaData | undefined>();

  useEffect(() => {
    if (!pageId || !application || foundMetaData) return;
    application?.keyFeatures?.forEach((keyFeature: any) => {
      keyFeature?.features?.forEach((feature: any) => {
        feature?.pages?.forEach((page: any) => {
          if (page?.id === pageId) setFoundMetaDat(page);
        });
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId, application]);

  return { application, foundMetaData };
};
