/**
* OF Routes
*/

export const OFRoutes = {
	

toRechercheSimpleOFPage: ()  : string =>  '/interface/of-recherche',

toFicheOfResumePage: (idOf?: string)  : string => idOf ? `/interface/fiche-of/${idOf}/index` :  '/interface/fiche-of/:idOf/index',

toFicheOfInformationGeneralePage: (idOf?: string)  : string => idOf ? `/interface/fiche-of/${idOf}/informations-generales` :  '/interface/fiche-of/:idOf/informations-generales',

toFicheOfMieuxConnaitrePage: (idOf?: string)  : string => idOf ? `/interface/fiche-of/${idOf}/contexte` :  '/interface/fiche-of/:idOf/contexte',

toFicheOfEtablissementPrincipalePage: (idOf?: string)  : string => idOf ? `/interface/fiche-of/${idOf}/etablissement` :  '/interface/fiche-of/:idOf/etablissement',

toFicheOfListeEtablissementPage: (idOf?: string)  : string => idOf ? `/interface/fiche-of/${idOf}/liste-etablissements` :  '/interface/fiche-of/:idOf/liste-etablissements',

toFicheOfHistoriqueQualiopiPage: (idOf?: string)  : string => idOf ? `/interface/fiche-of/${idOf}/historique-qualiopi` :  '/interface/fiche-of/:idOf/historique-qualiopi',

toFicheOfHistoriquePage: (idOf?: string)  : string => idOf ? `/interface/fiche-of/${idOf}/historique` :  '/interface/fiche-of/:idOf/historique',

toFicheOfReferencementPage: (idOf?: string)  : string => idOf ? `/interface/fiche-of/${idOf}/referencement` :  '/interface/fiche-of/:idOf/referencement',

toCreerOFSansNDAPage: ()  : string =>  '/interface/of-ajout/creation-compte',

toConfirmationCreationComptePage: ()  : string =>  '/interface/of-ajout/confirmation-creation-compte',

toFicheOfCertificateursPage: (idOf?: string)  : string => idOf ? `/interface/fiche-of/${idOf}/certificateurs` :  '/interface/fiche-of/:idOf/certificateurs',

};