
export function formatJsonDateString(dateStr: string, showSeconds: boolean = false): string {

  const lang = window?.navigator.language || "en";
  const date = new Date(dateStr + "Z");

  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    weekday: "short",
    hourCycle: "h24",
    hour: "2-digit",
    minute: "numeric",
    second: showSeconds ? "numeric" : undefined
  };

  return date.toLocaleString(lang, options);

}
