import { AxiosInstance } from "axios";

export async function fetchAxios<T>(
  axios: AxiosInstance | undefined,
  url: string | undefined,
  dataElementName: string,
  isList?: boolean
) {
  if (!axios) throw new Error("Axios not defined");
  if (!url) throw new Error("Url not defined");

  const { data: apiReplyData } = await axios.get<any>(url);

  if (!dataElementName || dataElementName === "") {
    return apiReplyData as any;
  }

  if (typeof apiReplyData !== "object")
    throw Error(
      `Expected an objet reply from the server but got ${typeof apiReplyData}`
    );

  if (apiReplyData.errorMessage) {
    throw Error(apiReplyData.errorMessage);
  }

  if (apiReplyData.Error?.errorMessage) return apiReplyData;

  let dataElement: T = apiReplyData[dataElementName];

  if (dataElement === undefined)
    throw Error(
      `Data element '${dataElementName}' entry not found in api reply`
    );

  (dataElement as any)._metaData = apiReplyData?._metaData;
  return dataElement as T;
}
