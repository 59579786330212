import { useCommand } from "@d4b/api";
import { useFormState } from "@d4b/fluent-ui";
import {
  DefaultButton,
  DefaultSpacing,
  IStyle,
  Stack,
  Text,
} from "@fluentui/react";
import { FormButton } from "components";
import React, { useEffect } from "react";

type FormProps<T, C> = {
  dataElement: T;
  showInvalidLink?: boolean;
  initFromState?: () => any;
  formState: useFormState<C>;
  children?: React.ReactNode;
  linkName: string;
  actionButtonText: string;
  actionButtonStyles?: IStyle;
  actionButtonDisabled?: boolean;
  onCollectErrors?: (errors: any, values?: C) => void;
  onTransformValues?: (current: C) => any;
  onServerReply?: (onServerReply: any) => void;
  onDismiss?: (val: any) => void;
  debug?: boolean;
  instructions?: string;
  setError?: (val: any) => void;
  callFunction?: () => void;
};

export const Form = <T, C>({
  dataElement,
  showInvalidLink = true,
  initFromState,
  formState,
  children,
  linkName,
  actionButtonText,
  actionButtonStyles,
  actionButtonDisabled,
  onCollectErrors,
  onTransformValues,
  onServerReply,
  onDismiss,
  debug = false,
  instructions,
  setError,
  callFunction, //to call another function onsubmit
}: FormProps<T, C>) => {
  const {
    isSuccess,
    data: serverReply,
    isLoading,
    onSubmit,
    reset,
  } = useCommand(
    dataElement,
    linkName,
    (e: any) => {
      setError && setError(e);
      onServerReply && onServerReply({});
      reset();
    },
    undefined,
    true
  );

  // const isValidLink = true;

  const { setValues, setErrors, cleanValues, values } = formState;
  useEffect(() => {
    if (!dataElement || !initFromState) return;
    const loadValues = initFromState();
    setValues(loadValues);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataElement]);
  useEffect(() => {
    if (!isSuccess || !serverReply) return;
    onServerReply && onServerReply(serverReply);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, serverReply, isLoading]);

  const onSubmitForm = (): void => {
    let errors: any = {};
    onCollectErrors && onCollectErrors(errors);
    setErrors && setErrors(errors);
    if (errors["rs:Form"]) {
      setError &&
        setError({
          errorMessage: "Form error",
          errorMessageDetails: errors["rs:Form"],
        });
      return;
    }
    if (JSON.stringify(errors) !== "{}") {
      console.log("onSubmitForm", errors);
      return;
    }

    const valuesTransformed = onTransformValues
      ? onTransformValues(cleanValues() as any)
      : cleanValues();

    onSubmit(valuesTransformed);
  };

  return (
    <>
      {dataElement &&
        !(dataElement as any)._links[linkName] &&
        linkName !== "" && (
          <span style={{ color: "red" }}>Invalid link '{linkName}'</span>
        )}

      {/* {error && (
        <MessageBar delayedRender={false} messageBarType={MessageBarType.error}>
          {error.message || "Something went wrong!"}
        </MessageBar>
      )} */}
      <form style={{ width: "100%" }} onSubmit={onSubmitForm}>
        {React.Children.toArray(children).map((child) => {
          if (!child) return null;
          const type = typeof (child as any).type;
          return type === "function"
            ? React.cloneElement(child as React.ReactElement, {
                formState,
              })
            : React.cloneElement(child as React.ReactElement);
        })}

        <Stack tokens={{ childrenGap: DefaultSpacing.l1 }}>
          {instructions && (
            <Text style={{ marginTop: DefaultSpacing.l1 }}>{instructions}</Text>
          )}
          <Stack
            horizontal
            style={{ marginTop: DefaultSpacing.l1 }}
            tokens={{ childrenGap: DefaultSpacing.s2 }}
          >
            <FormButton
              disabled={isLoading || actionButtonDisabled}
              text={actionButtonText}
              onClick={callFunction ? callFunction : onSubmitForm}
              routStyles={actionButtonStyles}
            />
            {onDismiss && <DefaultButton text="ANNULER" onClick={onDismiss} />}
          </Stack>
        </Stack>
      </form>
      {debug && (
        <Stack>
          <Text>Debug information</Text>
          <pre>{JSON.stringify({ values }, null, 2)}</pre>
        </Stack>
      )}
    </>
  );
};
