import React from "react";

        import { InterfaceGlobaleRoutes } from "./InterfaceGlobaleRoutes";
        import { NotRequireAuth } from "@d4b/fluent-ui";
        import { Routes, Route } from "react-router-dom";
        

const FeatureInterfaceGlobale = {
    
ConditionsGeneralesPage: React.lazy(() => import("@DataDock/InterfaceGlobale/InterfaceGlobale/views/ConditionsGeneralesPage")),


HomePage: React.lazy(() => import("@DataDock/InterfaceGlobale/InterfaceGlobale/views/HomePage")),


LoginPage: React.lazy(() => import("@DataDock/InterfaceGlobale/InterfaceGlobale/views/LoginPage")),


ReinitialisationMotDePassePage: React.lazy(() => import("@DataDock/InterfaceGlobale/InterfaceGlobale/views/ReinitialisationMotDePassePage")),


ResetMotDePassePage: React.lazy(() => import("@DataDock/InterfaceGlobale/InterfaceGlobale/views/ResetMotDePassePage")),


DroitInsuffisantPage: React.lazy(() => import("@DataDock/InterfaceGlobale/InterfaceGlobale/views/DroitInsuffisantPage")),


MaintenancePage: React.lazy(() => import("@DataDock/InterfaceGlobale/InterfaceGlobale/views/MaintenancePage")),


};

export const InterfaceGlobaleAppRoutes = () => (
    <Routes>
        
                        <Route key={"routeConditionsGeneralesPage"} element={<NotRequireAuth />}>
                          <Route path={InterfaceGlobaleRoutes.toConditionsGeneralesPage()} element={<FeatureInterfaceGlobale.ConditionsGeneralesPage />} />
                        </Route>
                        
                        <Route key={"routeHomePage"} element={<NotRequireAuth />}>
                          <Route path={InterfaceGlobaleRoutes.toHomePage()} element={<FeatureInterfaceGlobale.HomePage />} />
                        </Route>
                        
                        <Route key={"routeLoginPage"} element={<NotRequireAuth />}>
                          <Route path={InterfaceGlobaleRoutes.toLoginPage()} element={<FeatureInterfaceGlobale.LoginPage />} />
                        </Route>
                        
                        <Route key={"routeReinitialisationMotDePassePage"} element={<NotRequireAuth />}>
                          <Route path={InterfaceGlobaleRoutes.toReinitialisationMotDePassePage()} element={<FeatureInterfaceGlobale.ReinitialisationMotDePassePage />} />
                        </Route>
                        
                        <Route key={"routeResetMotDePassePage"} element={<NotRequireAuth />}>
                          <Route path={InterfaceGlobaleRoutes.toResetMotDePassePage()} element={<FeatureInterfaceGlobale.ResetMotDePassePage />} />
                        </Route>
                        
                        <Route key={"routeDroitInsuffisantPage"} element={<NotRequireAuth />}>
                          <Route path={InterfaceGlobaleRoutes.toDroitInsuffisantPage()} element={<FeatureInterfaceGlobale.DroitInsuffisantPage />} />
                        </Route>
                        
                        <Route key={"routeMaintenancePage"} element={<NotRequireAuth />}>
                          <Route path={InterfaceGlobaleRoutes.toMaintenancePage()} element={<FeatureInterfaceGlobale.MaintenancePage />} />
                        </Route>
                        
    </Routes>
);